// import UserList from 'components/admin/iuser/list';
import { Header } from 'components/admin/common/header';
import { Loading } from 'components/common';
import React, { Component, Fragment } from 'react';

export default class AdminDashboard extends Component {
    state = {
        isPageLoading: true
    };

    async componentDidMount() {
        this.setState({ isPageLoading: false });
    }
    render() {
        const { isPageLoading, isLoading, data } = this.state;
        const { session } = this.props;

        return (
            <div className='page page-users box'>
                <Header session={session} container />
                <div className='container pt-4'>
                    {isPageLoading && <Loading />}
                    {!isPageLoading && (
                        <Fragment>
                            <p>Welcome back {session.displayName} 💐</p>
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}
