import React, { Component, Container, Fragment } from 'react';

export default class Header extends Component {
    render() {
        return (
            <div className='container'>
                <footer className='footer-block'>
                    <span className='d-flex align-items-center'>
                        <img
                            className='img-fluid brand-logo-img mr-4'
                            src='/assets/home/logo.svg'
                            alt='Dreambig Edu Ventures Private Limited'
                            title='Dreambig Edu Ventures Private Limited'
                        />
                        <address
                            className='mb-0'
                            title='No.150/79, Montieth Road Cisons Complex, Egmore, Chennai, Tamilnadu-600008'>
                            {' '}
                            Egmore, Chennai-600008
                        </address>
                    </span>
                    <ul className='navbar-nav ml-md-auto footer-sub-block'>
                        {/* <li className="nav-item active">
                        <a className="nav-link" href="#">Privacy</a>
                        </li> */}
                        <li className='nav-item'>
                            <a
                                className='nav-link'
                                href='https://www.instagram.com/dreambignetworks/'
                                target='_blank'>
                                <img
                                    className='img-fluid block-img'
                                    src='/assets/home/in.svg'
                                    alt='Dream Big on Instagram'
                                />
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a
                                className='nav-link'
                                href='https://www.facebook.com/Dream-Big-2396640877231365'
                                target='_blank'>
                                <img
                                    className='img-fluid block-img'
                                    src='/assets/home/fb.svg'
                                    alt='Dream Big on Facebook'
                                />
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a
                                className='nav-link'
                                href='https://twitter.com/dbevdotin'
                                target='_blank'>
                                <img
                                    className='img-fluid block-img'
                                    src='/assets/home/tw.svg'
                                    alt='Dream Big on Twitter'
                                />
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a
                                className='nav-link'
                                href='https://www.linkedin.com/company/dbev-in'
                                target='_blank'>
                                <img
                                    className='img-fluid block-img'
                                    src='/assets/home/ln.svg'
                                    alt='Dream Big on Linked-in'
                                />
                            </a>
                        </li>
                    </ul>
                </footer>
            </div>
        );
    }
}
