import React, { Component, Fragment } from 'react';
import { Navbar, Nav, NavItem, Collapse } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import classnames from 'classnames';

class SideNav extends Component {
    list = [{ url: '/link1', icon: '', text: 'Link 1' }];

    render() {
        const items = this.props.items ? this.props.items : this.list;

        return (
            <Navbar className='sidemenu'>
                <Nav navbar>
                    {items.map((item, i) => (
                        <Fragment key={i}>
                            {!item.children && <Item item={item} key={i} />}
                            {item.children && <NavCollapse item={item} />}
                        </Fragment>
                    ))}
                </Nav>
            </Navbar>
        );
    }
}

const Item = ({ item }) => (
    <NavItem>
        <NavLink to={item.url} exact className='nav-link'>
            {item.icon} {item.text}
        </NavLink>
    </NavItem>
);

class NavCollapse extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { collapse: false };
    }

    toggle(e) {
        e.preventDefault();
        this.setState({ collapse: !this.state.collapse });
    }

    render() {
        const { item } = this.props;
        return (
            <Fragment>
                <NavItem
                    className={classnames('nav-header', {
                        active: this.state.collapse
                    })}
                    item={item}
                    onClick={e => this.toggle(e)}>
                    {item.icon} {item.text}
                </NavItem>
                <Collapse className='submenu' isOpen={this.state.collapse}>
                    {item.children.map((sub, i) => (
                        <Item item={sub} key={i} />
                    ))}
                </Collapse>
            </Fragment>
        );
    }
}
export default SideNav;
