import jwtDecode from 'jwt-decode';
import http from './httpService';
import { apiUrl } from 'config.json';

const apiEndpoint = apiUrl + '/auth';
const tokenKey = 'token';

http.setJwt(getJwt());

export async function login(username, password, ref = '', next = '') {
    const result = await http.post(apiEndpoint, {
        username,
        password,
        ref,
        next
    });
    localStorage.setItem(tokenKey, result.data.access_token);

    return result;
}

export function loginWithJwt(jwt) {
    localStorage.setItem(tokenKey, jwt);
}

export function logout() {
    localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
    try {
        const {
            identity,
            user_claims: { displayName, pictureUrl, roles }
        } = decodedToken();

        return { uid: identity, displayName, pictureUrl, roles };
    } catch (ex) {
        return null;
    }
}

export function decodedToken() {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
}

export function isExpired() {
    const decoded = decodedToken();

    const now = Date.now().valueOf() / 1000;
    if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
        // console.log(`token expired: ${JSON.stringify(decoded)}`)
        return true;
    }
    if (typeof decoded.nbf !== 'undefined' && decoded.nbf > now) {
        // console.log(`token expired: ${JSON.stringify(decoded)}`)
        return true;
    }

    return false;
}

export function isUserType(user = null, type = 'student') {
    if (!user) user = getCurrentUser();
    return user && user.roles && user.roles.indexOf(type) > -1;
}

export function getJwt() {
    return localStorage.getItem(tokenKey);
}

export default {
    login,
    loginWithJwt,
    logout,
    getCurrentUser,
    isUserType,
    decodedToken,
    isExpired,
    getJwt
};
