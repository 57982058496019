import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import classnames from 'classnames';
import { asField } from 'informed';
import Joi from 'joi-browser';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import DatePicker from 'react-datepicker';


const dateRangeSchema = {
    startDate: Joi.any().required().label('Start Date'),
    endDate: Joi.any().required().label('End Date')
}



const dateToMoment = (date, format = "DD/MM/YYYY") => {
    if (!date) return null

    return moment(date, format)
}


const FormControlDateRangePicker = asField(({ fieldState, fieldApi, ...props }) => {
    const { value } = fieldState;
    const { setValue, setTouched } = fieldApi;
    const { field, onChange, onBlur, initialValue, forwardedRef, label, id, children, helper, ...rest } = props;

    return <div
        className={classnames({ 'dp-is-invalid': fieldState.error })}
    ><DRP
            value={value}
            {...rest}
            startDate={value && value['startDate'] ? dateToMoment(value['startDate']) : null}
            endDate={value && value['endDate'] ? dateToMoment(value['endDate']) : null}
            onChange={({ startDate, endDate }) => {
                setValue({ startDate, endDate });
                setTouched();
                if (onChange) {
                    onChange({ startDate, endDate });
                }
            }}
            onBlur={e => {
                setTouched();
                if (onBlur) {
                    onBlur(e);
                }
            }}
        /></div>
});

const FormControlDatePicker = asField(({ fieldState, fieldApi, ...props }) => {
    const { value } = fieldState;
    const { setValue, setTouched } = fieldApi;
    const { field, onChange, onBlur, initialValue, forwardedRef, label, id, children, helper, ...rest } = props;

    return <div className={classnames({ 'dp-is-invalid': fieldState.error })}    >
        <DatePicker
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isClearable={true}
            
            {...rest}
            id={id ? id : field}
            field={field}
            ref={forwardedRef}
            selected={value ? value : null}
            
            className={classnames("form-control", { "is-invalid": fieldState.error })}
            onChange={e => {
                setValue(e);
                setTouched();
                if (onChange) {
                    onChange(e);
                }
            }}
            onBlur={e => {
                setTouched();
                if (onBlur) {
                    onBlur(e);
                }
            }}
        />

    </div>
});

class DRP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            focusedInput: null,
        };
    }

    render() {
        const { value, onChange, onBlur, ...rest } = this.props;
        return (
            <div className="App">
                <DateRangePicker
                    {...rest}
                    showClearDates
                    small
                    displayFormat="MMM D"
                    startDateId="startDate"
                    endDateId="endDate"

                    onDatesChange={({ startDate, endDate }) => {
                        onChange({ startDate, endDate })
                    }}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={(focusedInput) => {
                        this.setState({ focusedInput });
                        onBlur(focusedInput);
                    }}
                />
            </div>
        );
    }
}

class SDP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            focused: null,
        };
    }

    render() {
        const { id, value, onChange, onBlur, numberOfMonths, ...rest } = this.props;
        return (
            <div className="App">
                <SingleDatePicker
                    {...rest}
                    showClearDate
                    small
                    displayFormat="MMM D"
                    id={id}
                    numberOfMonths={numberOfMonths ? numberOfMonths : 1}
                    onDateChange={date => {
                        onChange(date)
                    }}
                    focused={this.state.focused}
                    onFocusChange={({ focused }) => {
                        this.setState({ focused });
                        onBlur(focused);
                    }}
                />
            </div>
        );
    }
}

export {
    FormControlDatePicker,
    FormControlDateRangePicker,
    DRP,
    SDP,
    dateRangeSchema
}