import React from "react";
import { Loading } from 'components/common';

const SubmitButton = ({ isLoading, ...props }) => {
    return (
        <button className={`btn btn-primary ${props.extraclass}`}
            {...props} >{isLoading && (isLoading === true || isLoading === 'true') ? <Loading color='white' /> : props.value}
        </button>)
}

export {
    SubmitButton
}