import {
    FormGroupInput,
    FormGroupAsyncAutoSuggest,
    FormGroupTextarea,
    FormGroupCustomSelect,
    SubmitButton
} from 'components/common/forms';
import { getQueryParams, Loading, Logo } from 'components/common';
import { Header } from 'components/admin/common/header';
import { BoxWrapper } from 'components/core/box';
import { Form, Scope } from 'informed';
import Joi from 'joi-browser';
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Alert } from 'reactstrap';
import auth from 'services/authService';
import { saveGroup } from 'services/clientService';
import { addressSchema, AddressComponent } from 'components/common/address';

// prettier-ignore
const userTagSchema = {
    uid: Joi.string().required().label('User Id required'),
    displayName: Joi.empty('').optional(),
    pictureUrl: Joi.empty('').optional(),
    userType:Joi.empty('').optional(),
    password: Joi.empty('').optional()
}
export default class ClientForm extends Component {
    state = {
        data: {},
        type: 'client',
        id: 'new',
        errors: {},
        isLoading: false,
        success: {}
    };

    // prettier-ignore
    schema = {
        // type: Joi.string().required().label('Type'),
        code: Joi.string().required().error(errors => { return { message: "Client Code should not be empty" }}),
        name: Joi.string().required().error(errors => { return { message: "Client Name should not be empty" }}),
        logo: Joi.empty('').optional(),
        icon: Joi.empty('').optional(),

        entityName: Joi.string().required().label('Entity Name'),
        branchName: Joi.string().required().label('Branch Name'),

        periodLabel: Joi.empty('').optional().default('Semester'),
        periodStartDate: Joi.empty('').optional(),
        periodEndDate: Joi.empty('').optional(),

        currentYear: Joi.empty('').optional(),
        academicYear: Joi.empty('').optional(),
        
        category: Joi.empty('').optional(),
        committee: Joi.empty('').optional(),
        affiliation: Joi.empty('').optional(),
        rulesAndRegulations: Joi.empty('').optional(),
        
        // owners: Joi.array().items(Joi.string()),
        // managers: Joi.array().items(Joi.string()),
        // members: Joi.array().items(Joi.string()),


        owners: Joi.array().items(userTagSchema),
        // managers: Joi.array().items(userTagSchema),
        // members: Joi.array().items(userTagSchema),

        // members: Joi.array().items(Joi.string()),

        // groupEmailIds: Joi.array().items(Joi.string()),
        // ccEmailIds: Joi.array().items(Joi.string()),
        // bccEmailIds: Joi.array().items(Joi.string()),
        // groupContactNos: Joi.array().items(Joi.string()),
        
        address: Joi.object(addressSchema),
        // sameAddressAsGroupId: Joi.boolean()        
    };

    componentDidMount() {
        const sampleData = this.getSampleData();
        this.formApi.setValues(sampleData);
    }

    getSampleData = () => {
        return {
            // type: 'client',
            code: 'sdev',
            name: 'Name 1',
            logo: 'Logo 1',
            icon: 'Icon 1',
            entityName: 'Entity Name 1',
            branchName: 'Branch Name 1',
            periodLabel: 'Semester',
            periodStartDate: '',
            periodEndDate: '',
            currentYear: '',
            academicYear: '',
            category: 'College',
            committee: '',
            affiliation: '',
            rulesAndRegulations: '',
            owners: [
                {
                    uid: 'sdev',
                    displayName: 'User 1',
                    pictureUrl: '',
                    userType: 'superAdmin'
                }
            ],
            managers: [],
            members: [],
            roles: [],
            groupEmailIds: [],
            ccEmailIds: [],
            bccEmailIds: [],
            groupContactNos: [],

            address: {
                attn: 'Attn 1',
                line1: 'Address Line1',
                line2: 'Address Line2',
                city: 'City 1',
                state: 'State 1',
                country: 'Country 1',
                pincode: '638462',
                landmark: 'Landmark 1'
            },
            sameAddressAsGroupId: true
        };
    };

    validateProperty = (name, value) => {
        const schema = Joi.reach(Joi.object(this.schema), name);
        const { error } = Joi.validate(value, schema);
        if (error) console.log(error);
        return error &&
            error.details &&
            error.details.length > 0 &&
            error.details[0].message
            ? error.details[0].message
            : null;
    };

    validate = () => {
        const options = { abortEarly: false };
        const data = this.formApi.getState().values;

        const { error } = Joi.validate(data, this.schema, options);
        if (!error) return null;
        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message;
        return errors;
    };

    setFormApi = formApi => {
        this.formApi = formApi;
    };

    onSubmit = async () => {
        console.log('onSubmit');

        this.setState({ isLoading: true, errors: {}, success: {} });

        const data = this.formApi.getState().values;
        console.log(data);

        try {
            const { id, type } = this.state;
            const result = await saveGroup(type, id, data);
            console.log(result);

            if (
                result &&
                result.data &&
                result.data.status &&
                result.data.status == 1
            ) {
                this.setState({ isLoading: false, success: result.data });
            } else {
                this.setState({
                    isLoading: false,
                    errors: { message: result.data.message }
                });
            }
        } catch (ex) {
            this.setState({ isLoading: false });
            if (ex.response && ex.response.data) {
                const errors = {
                    ...this.state.errors,
                    ...ex.response.data
                };
                this.setState({ errors });
            } else {
                const errors = {
                    ...this.state.errors,
                    message: 'Sorry some error occurred. Please try again',
                    class: 'danger'
                };
                this.setState({ errors });
            }
        }
    };

    render() {
        const { isLoading, errors, success } = this.state;

        return (
            <div className='page page-client-preview p-4'>
                <h5 className='text-gradient-eco'>
                    <i className='icon icon-university' /> New Institute
                </h5>
                <small className='text text-muted'>
                    All the fields required
                </small>
                <hr />
                <Form
                    className=''
                    getApi={this.setFormApi}
                    onSubmit={this.onSubmit}>
                    {({ formApi, formState }) => (
                        <Fragment>
                            <Fragment>
                                <h6>Client Details</h6>
                                <div className='row'>
                                    <div className='col-12 col-md-4'>
                                        <FormGroupInput
                                            field='code'
                                            label='Client Code'
                                            validateOnBlur
                                            validate={e =>
                                                this.validateProperty('code', e)
                                            }
                                        />
                                    </div>
                                    <div className='col-12 col-md-8'>
                                        <FormGroupInput
                                            field='name'
                                            label='Name'
                                            validateOnBlur
                                            validate={e =>
                                                this.validateProperty('name', e)
                                            }
                                        />
                                    </div>
                                </div>
                            </Fragment>

                            {/* <Fragment>
                                <h6>Owner Details</h6>
                                <Scope scope='owners[0]'>
                                    <div className='row'>
                                        <div className='col-12 col-md-6'>
                                            <FormGroupInput
                                                field='uid'
                                                type='text'
                                                label='User Id'
                                                validateOnBlur
                                                validate={e =>
                                                    this.validateProperty(
                                                        'uid',
                                                        e
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <FormGroupInput
                                                field='password'
                                                type='password'
                                                label='Password'
                                                validateOnBlur
                                                validate={e =>
                                                    this.validateProperty(
                                                        'password',
                                                        e
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className='col-12'>
                                            <FormGroupInput
                                                field='email'
                                                type='email'
                                                label='Email Id'
                                                validateOnBlur
                                                validate={e =>
                                                    this.validateProperty(
                                                        'email',
                                                        e
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </Scope>
                            </Fragment> */}

                            <Fragment>
                                <h6>Entity/Branch Details</h6>
                                <div className='row'>
                                    <div className='col-12 col-md-6'>
                                        <FormGroupInput
                                            field='entityName'
                                            label='Entity Name'
                                            validateOnBlur
                                            validate={e =>
                                                this.validateProperty(
                                                    'entityName',
                                                    e
                                                )
                                            }
                                        />
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <FormGroupInput
                                            field='branchName'
                                            label='Branch Name'
                                            validateOnBlur
                                            validate={e =>
                                                this.validateProperty(
                                                    'branchName',
                                                    e
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </Fragment>

                            <Fragment>
                                <h6>Customization</h6>
                                <div className='row'>
                                    <div className='col-12 col-md-6'>
                                        <FormGroupInput
                                            field='logo'
                                            label='Logo'
                                            validateOnBlur
                                            validate={e =>
                                                this.validateProperty('logo', e)
                                            }
                                        />
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <FormGroupInput
                                            field='icon'
                                            label='Icon'
                                            validateOnBlur
                                            validate={e =>
                                                this.validateProperty('icon', e)
                                            }
                                        />
                                    </div>
                                </div>
                            </Fragment>

                            <Fragment>
                                <h6>Address Details</h6>
                                <AddressComponent
                                    scope='address'
                                    validateProperty={this.validateProperty}
                                />
                            </Fragment>

                            <Fragment>
                                <h6>Additional Details</h6>
                                {/* FormGroupTextarea */}
                                <FormGroupTextarea
                                    field='committee'
                                    label='Communication Details'
                                    validateOnBlur
                                    validate={e =>
                                        this.validateProperty('committee', e)
                                    }
                                />
                                <FormGroupInput
                                    field='affiliation'
                                    label='Affiliation'
                                    validateOnBlur
                                    validate={e =>
                                        this.validateProperty('affiliation', e)
                                    }
                                />
                                <FormGroupTextarea
                                    field='rulesAndRegulations'
                                    label='Rules and Regulations'
                                    validateOnBlur
                                    validate={e =>
                                        this.validateProperty(
                                            'rulesAndRegulations',
                                            e
                                        )
                                    }
                                />
                            </Fragment>

                            {/* <FormGroupInput
                                field='password'
                                type='password'
                                label='Password'
                                validateOnBlur
                                validate={e =>
                                    this.validateProperty('password', e)
                                }
                            /> */}

                            <div className='mt-3 text-center'>
                                {
                                    <SubmitButton
                                        extraclass='w-100'
                                        isLoading={isLoading}
                                        value='Submit'
                                    />
                                }
                            </div>
                            {errors && JSON.stringify(errors)
                            // <Alert color={errors.class} className='mt-3'>
                            //     {errors.message}
                            // </Alert>
                            }
                            {success && success.status && success.status == 1 && (
                                <Alert color={success.class} className='mt-3'>
                                    {success.message}
                                </Alert>
                            )}
                        </Fragment>
                    )}
                </Form>
            </div>
        );
    }
}
