import React, { Component } from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem, FormGroup, Label, Input, Form } from 'reactstrap';


export default class Questions extends Component {
    state = {
        id: "",
        course: {},
        isPageLoading: true,
        isLoading: false
    }
    async componentDidMount() {
        const { courseId } = this.props.match.params;
        // const { data:course } = await getCourse(courseId);
        const course = this.getSampleCourse();
        await this.setState({ course, isPageLoading: false });
    }
    getSampleCourse = () => {
        return {
            "_id": "5c9ce63968a3ff15b86b2677",
            "code": "tamil-01",
            "name": "",
            "shortDesc": "",
            "desc": "",
            "prerequisites": "",
            "duration": 180,
            "noModules": 1,
            "noLessons": 3,
            "canDownload": true,
            "toc": [
                {
                    "name": "Chapter",
                    "type": "module",
                    "children": [
                        {
                            "name": "Video Topic 1",
                            "type": "topic",
                            "contentType": "video",
                            "params": {
                                "url": "",
                                "quality": [
                                    450,
                                    250
                                ],
                                "thumbnail": "",
                                "subtitles": {
                                    "en": ""
                                }
                            }
                        },
                        {
                            "name": "Video Topic 2",
                            "type": "topic",
                            "contentType": "video",
                            "params": {
                                "url": "",
                                "quality": [
                                    144,
                                    250
                                ],
                                "thumbnail": "",
                                "pictureUrl": "",
                                "subtitles": {
                                    "en": ""
                                },
                                "visibility": true,
                                "downloads": [
                                    {
                                        "url": "",
                                        "name": "",
                                        "fileType": ""
                                    }
                                ]
                            }
                        },
                        {
                            "name": "Article Topic 3",
                            "type": "topic",
                            "contentType": "article",
                            "params": {
                                "content": "",
                                "contentMarkdown": "",
                                "thumbnail": "",
                                "pictureUrl": "",
                                "subtitles": {
                                    "en": ""
                                }
                            }
                        }
                    ],
                    "noLessons": 3
                }
            ],
            "status": "live",
            "createdAt": 1553786424,
            "createdBy": "sdev",
            "level": "Easy",
            "category": { "label": "10 Std", "slug": "x-std" },
            "tags": [{ "label": "Chemistry", "slug": "x-chemistry" }],
            "promo": {
                "url": "http://clips.vorwaerts-gmbh.de/VfE_html5.mp4",
                "type": "video/mp4",
                "quality": [
                    450,
                    250
                ],
                "thumbnail": "",
                "subtitles": {
                    "en": ""
                }
            }
        }
    }
    render() {
        const { isPageLoading, isLoading, course } = this.state;
        console.log(course);
        return (
            <Container className="questions">
                {course && course._id && <div>
                    <Row>
                        <h6>Ask a Question</h6>
                    </Row>
                    <Row>
                        <FormGroup className="w-100">
                            <Input type="textarea" name="text" id="exampleText" placeholder="ask an academic question" />
                        </FormGroup>
                    </Row>
                    <Row>
                        <p className="w-100">
                            <span className="mr-3 q-active">ALL QUESTIONS</span>
                            <span>MY QUESTIONS</span>
                            <Col xs="12" md="3" lg="2" className="float-right pr-0">
                                <Input type="select" name="select" id="exampleSelect" bsSize="sm">
                                    <option>Latest First</option>
                                </Input>
                            </Col>
                        </p>
                    </Row>
                    <Row>
                        <QuestionBlock />
                    </Row>
                </div>
                }

            </Container>
        )
    }
}

class QuestionBlock extends Component {
    render() {
        return (
            <Container className="gray-border">
                <Row>
                    <Col xs="4" md="2" className="float-left">
                        <div className="text-center">
                            <i className="fa fa-caret-up d-block fa-3x"></i>
                            <label className="d-block font-weight-bolder">10</label>
                            <i className="fa fa-caret-down d-block fa-3x"></i>
                        </div>
                    </Col>
                    <Col xs="8" md="10" className="float-left">
                    <Row className="text-muted">
                        <span className="mr-2">.10 STD</span>
                        <span className="mr-2">.Easy</span>
                        <span>Asked by Sivaraj.s</span>
                        </Row>
                        <Row>
                            <div>Concurrency of three Lines()</div>
                        </Row>
                        <Row className="text-muted">
                            Answer for concorrency of three lines
                        </Row>
                        <Row>
                            <span className="q-active">
                                ANSWERS
                            </span>
                            <div className="float-right">
                                <span>5 Answers</span>
                                <span className="dot">.</span>
                                <span>25 Views</span>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}