import ClientList from 'components/admin/iclient/list';
import { Loading } from 'components/common';
import { Header } from 'components/admin/common/header';
import React, { Component, Fragment } from 'react';
import { getAdminClients } from 'services/clientService';
import Drawer from 'rc-drawer';

import ClientPreview from 'components/admin/iclient/preview';
import ClientForm from 'components/admin/iclient/form';
import PerfectScrollbar from 'react-perfect-scrollbar';

export default class ClientsPage extends Component {
    state = {
        isPageLoading: true,
        open: false,
        openClientForm: false,
        openChild: false,
        openChildren: false,
        cid: null,
        cname: '',
        ctype: 'client',
        cicon: '',
        colorCode: ''
    };
    clientPreview = (
        cid = null,
        ctype = 'client',
        cname = '',
        cicon = '',
        colorCode = ''
    ) => {
        this.setState({
            open: !this.state.open,
            cid,
            ctype,
            cname,
            cicon,
            colorCode
        });
    };
    openClientForm = () => {
        this.setState({
            openClientForm: !this.state.openClientForm
        });
    };
    onMaskClick = () => {
        this.setState({
            open: !this.state.open
        });
    };

    onMaskClickClientForm = () => {
        this.setState({
            openClientForm: !this.state.openClientForm
        });
    };

    async componentDidMount() {
        const {
            data: { clients }
        } = await getAdminClients();

        this.setState({ data: clients, isPageLoading: false });
    }
    render() {
        const { isPageLoading, isLoading, data } = this.state;
        const { session } = this.props;
        return (
            <div className='page page-clients box'>
                <Header session={session} container />
                <div className='container pt-4'>
                    {isPageLoading && <Loading />}
                    {!isPageLoading && (
                        <Fragment>
                            {/* {data && data.length <= 0 && 'No Clients found'}
                            {data && data.length > 0 && (
                                <ClientList data={data} />
                            )} */}
                            {data && (
                                <ClientList
                                    data={data}
                                    clientPreview={this.clientPreview}
                                    openClientForm={this.openClientForm}
                                />
                            )}
                            <Drawer
                                width='50vw'
                                handler={false}
                                level={['body > h1', '#__react-content']}
                                open={this.state.open}
                                onMaskClick={this.onMaskClick}
                                className='drawer1'
                                placement='right'>
                                {this.state.cid && (
                                    <ClientPreview
                                        session={session}
                                        cid={this.state.cid}
                                        ctype={this.state.ctype}
                                        cname={this.state.cname}
                                        cicon={this.state.cicon}
                                        colorCode={this.state.colorCode}
                                    />
                                )}
                            </Drawer>

                            <Drawer
                                width='50vw'
                                handler={false}
                                level={['body > h1', '#__react-content']}
                                open={this.state.openClientForm}
                                onMaskClick={this.onMaskClickClientForm}
                                className='drawer2'
                                placement='right'>
                                <ClientForm session={session} />
                            </Drawer>
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}
