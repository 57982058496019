import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import update from 'react-addons-update';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { NavLink } from 'react-router-dom';

const { SearchBar } = Search;
export default class TestList extends Component {
    state = {
        isPageLoading: true,
        isLoading: false,
        errors: {},
        data: [],
        columns: [],
        columnHeaders: { keys: [], def: {} },
        hideColumns: [],
        sort: [],
        success: [],
        selected: [],
        toggleColumns: false,
        labels: {}
    };

    async componentDidMount() {
        const { type, prefixUrl } = this.props;

        await this.initTableData();
        await this.setState({
            data: this.props.data,
            type,
            isPageLoading: false
        });
    }

    initTableData = async () => {
        const columnHeaders = getColumnHeaders();
        const hideColumns = this.state.hideColumns;
        const columns = getColumns(columnHeaders, hideColumns);

        await this.setState({ columns, columnHeaders, hideColumns });
    };

    isColumnVisible = key => {
        return !_.includes(this.state.hideColumns, key);
    };

    toggleColumn = async i => {
        this.setState({ isLoading: true });
        await this.setState(prevState => {
            let hidden =
                prevState.columns[i] && prevState.columns[i]['hidden']
                    ? prevState.columns[i]['hidden']
                    : false;
            var index = this.state.hideColumns.indexOf(
                prevState.columns[i]['dataField']
            );
            let hideColumns = this.state.hideColumns;
            if (!hidden) {
                hideColumns.push(prevState.columns[i]['dataField']);
            } else {
                if (index !== -1) {
                    hideColumns.splice(index, 1);
                }
            }

            return {
                columns: update(this.state.columns, {
                    [i]: { hidden: { $set: !hidden } }
                }),
                hideColumns
            };
        });
        this.setState({ isLoading: false });
    };

    handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selected: [...this.state.selected, row.id]
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter(x => x !== row.id)
            }));
        }
    };

    handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.id);
        if (isSelect) {
            this.setState(() => ({
                selected: ids
            }));
        } else {
            this.setState(() => ({
                selected: []
            }));
        }
    };

    toggleColumns = () => {
        this.setState({ toggleColumns: !this.state.toggleColumns });
    };

    render() {
        const {
            isPageLoading,
            isLoading,
            errors,
            type,
            data,
            columnHeaders: { keys: colKeys, def: colDef },
            columns
        } = this.state;

        return (
            <React.Fragment>
                {!isPageLoading && (
                    <React.Fragment>
                        {/* <div className='d-md-flex align-items-md-center justify-content-md-between'>
                            <h1>Tests</h1>
                            <NavLink
                                className='btn btn-primary btn-sm'
                                to={`/admin/test/upload`}>
                                Upload Test
                            </NavLink>
                        </div> */}
                        {!isLoading && (
                            <Fragment>
                                <ToolkitProvider
                                    keyField='testId'
                                    data={data}
                                    columns={columns}
                                    search>
                                    {props => (
                                        <Fragment>
                                            {/* <SearchBar {...props.searchProps} /> */}

                                            <Fragment>
                                                <div className='d-flex align-items-center justify-content-md-between border-b'>
                                                    <div className='heading-wrap'>
                                                        <h1>
                                                            Tests
                                                        </h1>
                                                        <div className='search-block'>
                                                            <i className='fa fa-search' />
                                                            <SearchBar
                                                                {...props.searchProps}
                                                            />
                                                        </div>
                                                    </div>
                                                    <NavLink
                                                        className='btn btn-primary btn-sm'
                                                        to={`/admin/user/upload`}>
                                                        Upload User
                                                    </NavLink>
                                                </div>
                                            </Fragment>
                                            {/* <br /> */}
                                            <BootstrapTable
                                                {...props.baseProps}
                                                keyField='testId'
                                                bootstrap4
                                                striped
                                                hover
                                                condensed
                                                // classes='table table-bordered table-hover table-sm'
                                                wrapperClasses='table-responsive'
                                                // filter={filterFactory()}
                                                // pagination={paginationFactory(options)}
                                                // noDataIndication='No courses created yet.'
                                                noDataIndication={() => {
                                                    return (
                                                        <div className='empty-data'>
                                                            <img src='/assets/admin/empty.png' />
                                                            <p>
                                                                No users created yet.
                                                            </p>
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </Fragment>
                                    )}
                                </ToolkitProvider>
                            </Fragment>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

function getColumns(columnsHeaders, hideColumns = []) {
    let columns = [];
    const { keys, def } = columnsHeaders;

    _.forEach(keys, key => {
        columns.push({ ...def[key], hidden: _.includes(hideColumns, key) });
    });
    return columns;
}

function getColumnHeaders() {
    let allKeys = [
        'sno',
        'testName',
        'duration',
        'topic',
        'createdBy',
        'actions'
    ];
    let excludeKeys = [];

    let keys = _.filter(allKeys, v => !_.includes(excludeKeys, v));
    let def = {
        sno: {
            dataField: 'sno',
            isDummyField: true,
            text: 'S.No',
            style: {
                width: '50px',
                textAlign: 'center'
            },
            headerStyle: (colum, colIndex) => {
                return { width: '80px', textAlign: 'center' };
            },
            formatter: serialNumberFormatter
        },
        testName: {
            dataField: 'testName',
            text: `Test Name`,
            formatter: testNameFormatter,
            // filter: getTextFilter(),
            sort: true
        },
        duration: {
            dataField: 'duration',
            text: `Duration`,
            desc: `Total Duration`,
            formatter: testNameFormatter
        },
        topic: {
            dataField: 'topic.label',
            text: `Topic`,
            desc: `Topic`,
            formatter: testNameFormatter
        },
        createdBy: {
            dataField: 'createdBy',
            text: `Created By`,
            desc: `Created By`,
            formatter: testNameFormatter
        },
        actions: {
            dataField: 'actions',
            isDummyField: true,
            text: 'Actions',
            formatter: actionsFormatter
        }
    };
    return { keys: keys, def: def };
}

function serialNumberFormatter(cell, row, index, formatExtraData) {
    return index + 1;
}

function testNameFormatter(cell, row, index, formatExtraData) {
    return (
        <div className='testName'>
            <div
                className='icon'
                style={{ backgroundImage: `url(${row.icon})` }}
            />
            {cell}
        </div>
    );
}

function getTextFilter(type = 'default') {
    return textFilter({
        placeholder: '',
        delay: 1000
    });
}

function actionsFormatter(cell, row, index, formatExtraData) {
    return (
        <div className='actions'>
            <NavLink
                to={`/admin/test/preview/${row.testId}`}
                className='badge badge-success'>
                View
            </NavLink>{' '}
            &nbsp;
            {/* <NavLink
                to={`/admin/test/preview/${row.testId}/edit`}
                className='badge badge-warning'>
                Edit
            </NavLink>{' '}
            &nbsp; */}
            <NavLink
                to={`/admin/test/preview/${row.testId}/delete`}
                className='badge badge-danger'>
                Delete
            </NavLink>
        </div>
    );
}
