import { getQueryParams, Loading } from 'components/common';
import DropdownTreeContainer from 'components/common/ddtreecontainer';

import { SubmitButton } from 'components/common/forms';
import { Header } from 'components/admin/common/header';
import { Form } from 'informed';
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { userUpload } from 'services/userService';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import data from './data.json';
import {
    loadBranchList,
    loadDepartmentListByEntity
} from 'services/autoSuggest';
import Select from 'react-select';
const ReactMarkdown = require('react-markdown');

export default class UserUploadPage extends Component {
    state = { isPageLoading: false };
    render() {
        const { isPageLoading } = this.state;
        const { session } = this.props;
        const { utype } = getQueryParams(this.props);
        const params = {};
        return (
            <div className='page page-userUpload box'>
                <Header session={session} container />
                <div className='container pt-4'>
                    {isPageLoading && <Loading />}
                    {!isPageLoading && (
                        <div className='row'>
                            {(!utype || utype == 'student') && (
                                <div className='col'>
                                    <UserUploadForm
                                        {...this.props}
                                        utype='student'
                                        params={params}
                                    />
                                </div>
                            )}
                            {(!utype || utype == 'staff') && (
                                <div className='col'>
                                    <UserUploadForm
                                        {...this.props}
                                        utype='staff'
                                        params={params}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
const onChange = (currentNode, selectedNodes) => {
    console.log('currentNode::', currentNode);
    console.log('selectedNodes::', selectedNodes);
};
const assignObjectPaths = (obj, stack) => {
    Object.keys(obj).forEach(k => {
        const node = obj[k];
        if (typeof node === 'object') {
            node.path = stack ? `${stack}.${k}` : k;
            assignObjectPaths(node, node.path);
        }
    });
};
class UserUploadForm extends Component {
    state = {
        data: {},
        errors: {},
        isLoading: false,
        success: {},
        onUploadProgress: 0,
        branchDefaultValue: {},
        branches: [],
        groups: []
    };

    async componentDidMount() {
        let { data } = await loadBranchList();
        data = [data[0]];
        let branchDefaultValue = {};
        if (data && data.length == 1 && data[0]['id']) {
            // data[0]['checked'] = true;
            branchDefaultValue = data[0];
            console.log(branchDefaultValue);
            await this.loadDepartmentsByBranchId(data[0]['id']);
        }

        this.setState({ branches: data, branchDefaultValue });
    }
    readFile = e => {
        e.stopPropagation();
        e.preventDefault();

        const files = e.target.files;
        const f = files[0];
        this.file = f;
    };

    onChangeBranch = async e => {
        this.loadDepartmentsByBranchId(e.id);
        // console.log(selectedNodes);
        // if (selectedNodes.length > 0) {
        //     console.log(selectedNodes[0]['id']);

        //     const { data } = await loadDepartmentListByEntity(
        //         selectedNodes[0]['id']
        //     );
        //     console.log(data);
        //     this.setState({ groups: data });
        // }
    };

    loadDepartmentsByBranchId = async id => {
        if (id) {
            const { data } = await loadDepartmentListByEntity(id);
            this.setState({ groups: data });
        } else {
            this.setState({ groups: [] });
        }
    };
    onChangeGroup = (currentNode, selectedNodes) => {};

    onUploadProgress = data => {
        console.log(data);
    };

    onSubmit = async () => {
        // const data = this.formApi.getState().values;
        const { utype } = this.props;
        try {
            this.setState({ isLoading: true, errors: {}, success: {} });

            if (!this.file) {
                const errors = {
                    ...this.state.errors,
                    message: 'Please choose the file',
                    class: 'danger'
                };
                this.setState({ errors });
            }

            const formData = new FormData();
            formData.append('file', this.file);

            const config = {
                headers: { 'content-type': 'multipart/form-data' },
                onUploadProgress: progressEvent => {
                    console.log(progressEvent);

                    const totalLength = progressEvent.lengthComputable
                        ? progressEvent.total
                        : progressEvent.target.getResponseHeader(
                              'content-length'
                          ) ||
                          progressEvent.target.getResponseHeader(
                              'x-decompressed-content-length'
                          );
                    console.log('onUploadProgress', totalLength);
                    if (totalLength !== null) {
                        // console.log(
                        //     Math.round(
                        //         (progressEvent.loaded * 100) / totalLength
                        //     )
                        // );
                        const loaded = Math.round(
                            (progressEvent.loaded * 100) / totalLength
                        );
                        this.setState({ onUploadProgress: loaded });
                    }
                }
            };
            const { data } = await userUpload(formData, utype, config);
            console.log(data);

            if (data && data.status === 0) {
                const { message, errors } = data;
                this.setState({
                    isLoading: false,
                    errors: {
                        message: message,
                        list: errors,
                        class: 'danger'
                    }
                });
            } else if (data && data.status === 1) {
                const { message, sid } = data;

                this.setState({
                    isLoading: false,
                    success: {
                        sid: sid,
                        message: message,
                        class: 'success'
                    }
                });
            }
        } catch (ex) {
            this.setState({ isLoading: false });
            if (ex.response && ex.response.data) {
                const errors = { ...this.state.errors, ...ex.response.data };
                this.setState({ errors });
            } else {
                const errors = {
                    ...this.state.errors,
                    message: 'Sorry some error occurred. Please try again',
                    class: 'danger'
                };
                this.setState({ errors });
            }
        }
    };

    render() {
        const {
            isLoading,
            errors,
            success,
            branches,
            branchDefaultValue,
            groups
        } = this.state;
        // const queryParams = getQueryParams(this.props);
        const { utype } = this.props;
        // assignObjectPaths(data);

        return (
            <Form
                className='bg-white p-3'
                getApi={this.setFormApi}
                onSubmit={this.onSubmit}>
                {({ formApi, formState }) => (
                    <Fragment>
                        <div className={`bulk-upload bulk-upload-${utype}`}>
                            {utype == 'student' && (
                                <Fragment>
                                    <h1 className='bulk-upload__heading'>
                                        Bulk upload Students
                                    </h1>

                                    <p className='bulk-upload__desc'>
                                        Add or edit users by uploading a CSV
                                        file with their info such as passwords
                                        and organizational units.
                                        <a className='bulk-upload__link'>
                                            Learn more
                                        </a>
                                    </p>

                                    <p className='bulk-upload__desc'>
                                        <strong>Note:</strong> Required fields
                                        are First Name, Last Name, Email
                                        Address, Password and Org Unit Path.
                                    </p>
                                </Fragment>
                            )}

                            {utype == 'staff' && (
                                <Fragment>
                                    <h1 className='bulk-upload__heading'>
                                        Bulk upload Staff's
                                    </h1>

                                    <p className='bulk-upload__desc'>
                                        Add or edit users by uploading a CSV
                                        file with their info such as passwords
                                        and organizational units.
                                        <a className='bulk-upload__link'>
                                            Learn more
                                        </a>
                                    </p>

                                    <p className='bulk-upload__desc'>
                                        <strong>Note:</strong> Required fields
                                        are First Name, Last Name, Email
                                        Address, Password and Org Unit Path.
                                    </p>
                                </Fragment>
                            )}

                            {/* <DropdownTreeContainer
                                data={branches}
                                onChange={this.onChangeBranch}
                                className='bootstrap'
                                simpleSelect
                            /> */}
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <Select
                                        options={branches}
                                        defaultValue={branchDefaultValue}
                                        onChange={this.onChangeBranch}
                                    />
                                </div>
                                <div className='col-12 col-md-12'>
                                    <DropdownTreeContainer
                                        data={groups}
                                        onChange={this.onChangeGroup}
                                        className='bootstrap'
                                        hierarchical={true}
                                        // showPartiallySelected={true}
                                    />
                                </div>
                            </div>

                            <div className='fileBlock my-3'>
                                {/* <div className='custom-file'> */}
                                <input
                                    id={`upload-file-${utype}`}
                                    ref='upload'
                                    type='file'
                                    accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                    onChange={event => {
                                        this.readFile(event);
                                    }}
                                    onClick={event => {
                                        // event.target.value = null
                                    }}
                                />
                                {/* <label
                                        className='custom-file-label'
                                        htmlFor={`upload-file-${utype}`}>
                                        Choose file
                                    </label>
                                </div> */}
                            </div>

                            <strong>Not sure how to get started?</strong>

                            <p className='bulk-upload__desc'>
                                Download a XLSX file, then edit and upload the
                                file.
                            </p>
                            <ul className='bulk-upload__lists'>
                                <li>
                                    <p className='bulk-upload__desc'>
                                        Download blank
                                        <a className='bulk-upload__link'>
                                            XLSX template
                                        </a>
                                    </p>
                                </li>
                                <li>
                                    <p className='bulk-upload__desc'>
                                        Download user's info in a
                                        <a className='bulk-upload__link'>
                                            XLSX file
                                        </a>
                                    </p>
                                </li>
                            </ul>
                            {
                                <SubmitButton
                                    extraclass=''
                                    isLoading={isLoading}
                                    value='Submit'
                                    // value='User Upload'
                                />
                            }
                            <div>Uploading {this.state.onUploadProgress}</div>
                        </div>
                        {errors && errors.message && (
                            <Alert color={errors.class} className='mt-3'>
                                {errors.message}
                                {errors.list && errors.list.length > 0 && (
                                    <ul>
                                        {errors.list.map((e, idx) => {
                                            return (
                                                <li key={idx}>
                                                    <ReactMarkdown source={e} />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </Alert>
                        )}

                        {success && success.message && (
                            <Alert color={success.class} className='mt-3'>
                                {success.message}
                                {success.sid && (
                                    <NavLink
                                        to={`/user/${success.sid}/details`}
                                        className='badge badge-success'>
                                        Click here to view the user
                                    </NavLink>
                                )}
                            </Alert>
                        )}
                    </Fragment>
                )}
            </Form>
        );
    }
}
