import { FormGroupInput, SubmitButton } from 'components/common/forms';
import { getQueryParams, Loading, Logo } from 'components/common';
import { Header } from 'components/admin/common/header';
import { BoxWrapper } from 'components/core/box';
import { Form } from 'informed';
import Joi from 'joi-browser';
import React, { Component, Fragment } from 'react';
import { Alert } from 'reactstrap';
import { getTestPreview } from 'services/testService';
import Dropzone from 'react-dropzone'
import Previews from './images';
import classnames from 'classnames';
import _ from 'lodash';
import 'styles/ques.scss';
// import MathJax from 'mathjax';
// import Prism from "prismjs";

// import 'prismjs';
// import 'prismjs/plugins/toolbar/prism-toolbar';
// import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard';
// import 'prismjs/components/prism-css';
// import 'prismjs/components/prism-javascript';
// import 'prismjs/components/prism-java';
// import 'prismjs/components/prism-markup';
// import 'prismjs/components/prism-typescript';
// import 'prismjs/components/prism-sass';
// import 'prismjs/components/prism-scss';
// import 'prismjs/components/prism-sql';

import hljs from 'highlight.js';

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';

export default class TestPreviewPage extends Component {
  state = { isPageLoading: false }

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const { testId } = this.props.match.params;
    const { data: test } = await getTestPreview(testId)

    // if (window.MathJax) {
    //   console.log(`window.MathJax`)
    //   console.log(window.MathJax)

    //   window.MathJax.Hub.Config({
    //     config: ["MMLorHTML.js"],
    //     jax: ["input/TeX", "output/HTML-CSS", "output/NativeMML"],
    //     // jax: ["input/TeX", "output/CommonHTML"],
    //     extensions: ["MathMenu.js", "MathZoom.js"]
    //   });
    // }

    this.setState({ test })

    hljs.initHighlighting()


    // Prism.highlightAll();

  }

  componentDidUpdate = () => {
    // Prism.highlightAll();
    console.log(`Prism: componentDidUpdate`)
    // hljs.initHighlightingOnLoad();
    if (window.MathJax) {
      window.MathJax.Hub.Configured()
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
    }

    if (hljs) {
      hljs.initHighlighting.called = false;
      hljs.initHighlighting();
    }
    // if (MathJax) {
    //   console.log(MathJax)
    //   if (MathJax.Hub) {
    //     console.log(MathJax.Hub)
    //     MathJax.Hub.Config({
    //       config: ["MMLorHTML.js"],
    //       jax: ["input/TeX", "output/HTML-CSS", "output/NativeMML"],
    //       extensions: []
    //     });
    //   }
    // }
    // if (window.MathJax) {
    //   console.log(`window.MathJax`)
    //   console.log(window.MathJax)

    //   window.MathJax.Hub.Config({
    //     config: ["MMLorHTML.js"],
    //     jax: ["input/TeX", "output/HTML-CSS", "output/NativeMML"],
    //     // jax: ["input/TeX", "output/CommonHTML"],
    //     // extensions: ["MathMenu.js", "MathZoom.js"]
    //   });
    // }
  }
  render() {
    const { isPageLoading, test } = this.state;
    const { session } = this.props;
    const params = getQueryParams(this.props);

    return (
      <div className="page page-testUpload box bg-white">
        <Header session={session} container />
        <div className="container pt-5">
          {isPageLoading && <Loading />}
          {!isPageLoading && <div className="row">
            <div className="col-12">
              <TestPreview {...this.props} test={test} />
            </div>

          </div>}
        </div>
      </div>
    );
  }
}

export class TestPreview extends Component {
  state = {
    isPageLoading: false,
    activeTab: '1'
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    const { session, test } = this.props;
    console.log(test);

    return <Fragment>
      {test && test['_id'] && <div className="test testPreview">
        <h1 className="test-name">{test.testName}
          {/* <span className="test-code"> ({test.testCode}) </span>  */}
        </h1>
        {
          test.desc && <div className="test-desc">{viewContent(test.desc)}</div>
        }
        <div className="d-flex flex-row justify-content-between">
          {/* <div className="details">
            <span className="duration"> <span> Duration: </span> <span>{test.duration}</span></span>
            <span className="correct"><span> Correct: </span> <span>{test.marks[0]}</span> </span>
            <span className="wrong"> <span> Wrong: </span> <span>{test.marks[1]}</span> </span>
            <span className="unanswered"> <span> Unanswered: </span> <span>{test.marks[2]}</span></span>
          </div> */}

          <div className="details">
            <span className="duration"> <span className="fa fa-clock-o"></span> <span>{test.duration}</span></span>
            <span className="correct"><span className="fa fa-check"></span> <span>{test.marks[0]}</span> </span>
            <span className="wrong"> <span className="fa fa-close"></span> <span>{test.marks[1]}</span> </span>
            <span className="unanswered"> <span className="fa fa-circle-o"></span> <span>{test.marks[2]}</span></span>
          </div>

          <div>
            {test.topic && test.topic.label && <span className="badge badge-primary">Topic: {test.topic.label}</span>}

            {test.topic && test.topic.category && <span className="badge badge-primary">Category: {test.topic.category}</span>}

            {test.company && test.company.label && <span className="badge badge-secondary">Company: {test.company.label}</span>}

            {test['tags'] && test['tags'].length > 0 && test['tags'].map((tag, idx) => {
              return <span className="badge badge-secondary" key={idx}>{tag}</span>
            })}
          </div>
        </div>
        <Nav tabs>
          {test['sections'].map((section, idx) => {
            return <NavItem key={idx}>
              <NavLink
                className={classnames({ active: this.state.activeTab === `${idx + 1}` })}
                onClick={() => { this.toggle(`${idx + 1}`); }}>
                {section['name']}
              </NavLink>
            </NavItem>
          })}
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          {test['sections'].map((section, idx) => {
            return <TabPane tabId={`${idx + 1}`} key={idx}>
              <SectionPreview
                test={test}
                section={section}
              />
            </TabPane>
          })}
        </TabContent>
      </div>

      }
    </Fragment>
  }
}

const qt = {
  'mcq': { 'short': 'MCQ', 'long': 'Multiple Choice Questions' },
  'msq': { 'short': 'MCQ', 'long': 'Multiple Selection Questions' },
  'shortAnswer': { 'short': 'Short Anwer', 'long': 'Short Answer Questions' },
  'longAnswer': { 'short': 'Long Answer', 'long': 'Long Anwer Questions' },
  'code': { 'short': 'Code', 'long': 'Programming Questions' },

}
export class SectionPreview extends Component {
  state = { isPageLoading: false }
  getQno = (qid) => {
    const { section } = this.props;

    if (section && section['qids']) {
      return _.indexOf(section['qids'], qid) + 1
    }
    return ''
  }

  render() {
    const { session, section, test } = this.props;

    return <Fragment>
      {section && <div className="section col-sm-12 px-0">
        <div className="bg-light section-details">
          <div className="wrapper">
            <h4 className="section-name"> {section.name}</h4>
            {section.desc && <div>{viewContent(section.desc)}</div>}

            <table className="table borderless table-sm mt-3">
              <tbody>
                <tr>
                  <td>Total Questions </td>
                  <td>Passage questions</td>
                  <td>Strict question order</td>
                  <td>Typewise Count</td>
                </tr>
                <tr>
                  <th>{section['noQuestions']}</th>
                  <th>{section['noGroupQuestions']}</th>
                  <th>
                    {section['questionOrderStrict'] && 'Yes'}
                    {!section['questionOrderStrict'] && 'No'}
                  </th>
                  <th>
                    {
                      Object.keys(section['noByQTypes']).map((key, idx) => {
                        return <small key={idx}>{qt[key]['short']} - {section['noByQTypes'][key]} &nbsp;</small>
                      })
                    }
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <ul className="stats">
            {section['noQuestions'] && <li className=""> <span> Total number of questions: </span> {section['noQuestions']}</li>}
            {section['noGroupQuestions'] != 0 && <li className=""> <span> Total passage questions: </span> {section['noGroupQuestions']}</li>}
            {section['questionOrderStrict'] != false && <li className=""> <span> Follow same question order: </span> {section['questionOrderStrict']}</li>}
            {
              Object.keys(section['noByQTypes']).map((key, idx) => {
                return <li key={idx}>{qt[key]['long']} : {section['noByQTypes'][key]}</li>
              })
            }
          </ul> */}
        </div>
        {section['pqids'].map((value, idx) => {
          return <Fragment key={idx}>
            {!(_.isArray(value)) && <Question q={test['questions'][value]} key={idx} qno={this.getQno} />}
            {_.isArray(value) && <Passage qids={value} idx={idx} qs={test['questions']} qno={this.getQno} />}
          </Fragment>
        })}
      </div>}
    </Fragment>
  }
}

export class Passage extends Component {
  state = {}

  render() {
    const { qids, qs, idx } = this.props;
    const passage = qids[0] && qs[qids[0]] && qs[qids[0]]['group'] && qs[qids[0]]['group']['common'] ? qs[qids[0]]['group']['common'] : null;
    const startNo = idx + 1;
    const endNo = qids.length + idx;
    const onlyOneQuestion = startNo == endNo ? true : false

    return <div className="passage directions">
      {passage && <div className="test-passage">
        <p className="font-weight-bold">Directions {startNo} {!onlyOneQuestion && <span>to {endNo}</span>}</p>
        {viewContent(passage)}
      </div>}
      {qids && qids.map((value, idx) => {
        return <Question q={qs[value]} key={idx} qno={this.props.qno} />
      })}
    </div>
  }
}

export class Question extends Component {
  state = { isPageLoading: false }

  componentDidUpdate = () => {
    // Prism.highlightElement(this.refs.highlight)
  }

  render() {
    const { session, section, test, q, qid } = this.props;

    return <Fragment>
      {q && <div className="question no-border">

        <div className="d-flex justify-content-between">
          <p className="font-weight-bold">Question {this.props.qno(q['qid'])}</p>
          <span className={`level ${q['level'].toLowerCase()}`}>{q['level']}</span>
        </div>
        <div className="content" ref="highlight">{viewContent(q['question'])}</div>
        {
          q['options'] && q['options'].length > 0 && <div className="test-options">
            {q['options'].map((option, idx) => {
              return <Choice q={q} option={option} key={idx} />
            })}
          </div>
        }
        {
          q['type'] == "shortAnswer" && <ShortAnswer q={q} />
        }
        {
          q['type'] == "longAnswer" && <LongAnswer q={q} />
        }
        {
          q['type'] == "code" && <Code q={q} />
        }
        <div className="tags">
          {
            q['topic'] && q['topic']['slug'] &&
            <span className="tag">{q['topic']['label']}</span>
          }
          {
            q['company'] && q['company']['slug'] &&
            <span className="tag">{q['company']['label']}</span>
          }
          {
            q['tags'] && q['tags'].length > 0 &&
            q['tags'].map((tag, idx) => {
              return <span className="tag" key={idx}>{tag}</span>
            })
          }
        </div>
        {
          q['explanation'] && < div className="explanation">
            <p className="font-weight-bold">Explantion</p>
            {viewContent(q['explanation'])}
          </div>
        }
      </div>
      }
    </Fragment>
  }
}

export const ShortAnswer = ({ q }) => {

  return (
    <div>
      <input type="text" className="form-control" placeholder="" value={q['answer']} disabled></input>
    </div>
  );
};

export const LongAnswer = ({ q }) => {
  const params = ['minWords', 'maxWords', 'phrases', 'relatedPhrases'];

  return (
    <div>
      <div className="ques-params">

        {
          q['easyWriting'] && <Fragment>

            {
              q['easyWriting']['minWords'] && q['easyWriting']['minWords'] > 0 && <div>
                <span>Minimum number of words</span>
                <span>{q['easyWriting']['minWords']}</span>
              </div>
            }
            {
              q['easyWriting']['maxWords'] && q['easyWriting']['maxWords'] > 0 && <div>
                <span>Maximum number of words</span>
                <span>{q['easyWriting']['maxWords']}</span>
              </div>
            }

            {
              q['easyWriting']['phrases'] && q['easyWriting']['phrases'].length > 0 && <div>
                <span>Phrases</span>
                <span>{q['easyWriting']['phrases'].join(', ')} </span>
              </div>
            }

            {
              q['easyWriting']['relatedPhrases'] && q['easyWriting']['relatedPhrases'].length > 0 && <div>
                <span>Internal Phrases</span>
                <span>{q['easyWriting']['relatedPhrases'].join(', ')}</span>
                <small className="help-text text-info">(This will not be visibile to students)</small>
              </div>
            }

          </Fragment>
        }
      </div>
      <textarea className="form-control" rows="3" disabled defaultValue={q['answer']}></textarea>
    </div>
  );
};

export const Code = ({ q }) => {

  const code = q['programming'];
  return code && <div className="code-question">
    <div className="ques-params mb-3">
      {
        code['memoryLimit'] && <div> <span> Memory Limit</span> <span>{[code['memoryLimit']]}</span></div>
      }
      {
        code['sourceLimit'] && <div> <span> Source Limit</span> <span>{[code['sourceLimit']]}</span></div>
      }
      {
        code['timeLimit'] && <div> <span> Time Limit</span> <span>{[code['timeLimit']]}</span></div>
      }
      {
        code['compilationAttempts'] && <div> <span> Compilation Attempts</span> <span>{[code['compilationAttempts']]}</span></div>
      }
      {
        code['defaultLanguage'] && code['lsInfo'] && code['lsInfo'][code['defaultLanguage']] && <div> <span> Default Language: </span> <span>{code['lsInfo'][code['defaultLanguage']]['name']}</span></div>
      }
      {
        code['languageSupported'] && code['languageSupported'].length > 0 && code['lsInfo'] && <div>
          <span>Support Languages</span>
          <span>{code['languageSupported'].map((lang, idx) => <span className="badge badge-light" key={idx}>{code['lsInfo'][lang]['name']}</span>)}</span>
        </div>
      }
    </div>
    <div className="superfences-tabs">
      {
        code['allTestCases'] && code['allTestCases'].length > 0 && code['allTestCases'].map((tc, idx) => {
          return <Fragment key={idx}>
            <input name={`__tabs_${q['qno']}`} type="radio" id={`__tabs_${q['qno']}_${idx}`} defaultChecked={idx == 0} />
            <label htmlFor={`__tabs_${q['qno']}_${idx}`}>Test Case {idx + 1}
              {tc['isHidden'] && <span className="fa fa-eye-slash pl-1 text-muted" title='Hidden Test Case'></span>}
            </label>
            <div className="superfences-content">
              <pre className="highlight"><code className="plaintext">{tc['input']}</code></pre>
              <pre className="highlight"><code className="plaintext">{tc['output']}</code></pre>
            </div>
          </Fragment>
        })
      }
    </div>
    <p><strong>Code Template</strong></p>

    <div className="superfences-tabs">
      {code['languageSupported'] && code['languageSupported'].length > 0 && code['languageSupported'].map((lang, idx) => {
        const template = code['samples'][lang]['template'];
        const ls = code['lsInfo'][lang]['name']
        return (
          <Fragment key={idx}>
            <input name={`__tabs_${q['qno']}_template`} type="radio" id={`__tabs_${q['qno']}_${ls}_template_${idx}`} defaultChecked={idx == 0} />
            <label htmlFor={`__tabs_${q['qno']}_${ls}_template_${idx}`}>{ls}</label>
            <div className="superfences-content">
              <pre className="highlight"><code>{template}</code></pre>
            </div>
          </Fragment>
        )
      })}
    </div>
    <p><strong>Code Sample</strong></p>
    <div className="superfences-tabs">
      {code['languageSupported'] && code['languageSupported'].length > 0 && code['languageSupported'].map((lang, idx) => {
        const sample = code['samples'][lang]['sample'];
        const ls = code['lsInfo'][lang]['name']
        return (
          <Fragment key={idx}>
            <input name={`__tabs_${q['qno']}_sample`} type="radio" id={`__tabs_${q['qno']}_${ls}_sample_${idx}`} defaultChecked={idx == 0} />
            <label htmlFor={`__tabs_${q['qno']}_${ls}_sample_${idx}`}>{ls}</label>
            <div className="superfences-content">
              <pre className="highlight"><code>{sample}</code></pre>
            </div>
          </Fragment>
        )
      })}
    </div>
  </div>
};

export const Choice = ({ q, option }) => {
  const oid = `${q['qid']}${option['id']}`;
  const isCorrect = answerCheck(q['type'], option['id'], q['answer']);
  const type = q['type'] == 'msq' ? 'checkbox' : 'radio';

  return (
    <div className={classnames(`custom-control custom-${type}`, { 'checked': isCorrect })}>
      <input type={type} id={oid} name={oid} className="custom-control-input" disabled checked={isCorrect} />
      <label className="custom-control-label" htmlFor={oid}>
        {viewContent(option['text'])}
      </label>
    </div>
  );
};
export const viewContent = (content) => {

  return <div dangerouslySetInnerHTML={{ __html: content }} className="dynamic"></div>
}


function answerCheck(type, o, answer) {

  switch (type) {
    case 'mcq':
    case 'msq':
      return _.includes(answer, o);
    case 'shortAnswer':
      return answer == o
    default:
      return false
  }
}



