export const minsToHHMM = d => {
    // return `${Math.floor(duration / 60)} Hrs ${duration % 60} Mins`
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? 'h ' : 'h ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? 'm ' : 'm ') : '';
    var sDisplay = s > 0 ? s + (s == 1 ? 's' : 's') : '';
    return hDisplay + mDisplay + sDisplay;
};

export const getUserTypeLabels = type => {
    const u = {
        superAdmin: 'Super Admin',
        admin: 'Admin',
        staff: 'Staff',
        parent: 'Parent',
        student: 'Student'
    };
    return u[type] ? u[type] : 'Student';
};
