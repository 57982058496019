import UserList from 'components/admin/iuser/list';
import { Loading } from 'components/common';
import { Header } from 'components/admin/common/header';
import React, { Component, Fragment } from 'react';
import { getUserPreview } from 'services/userService';
import Drawer from 'rc-drawer';
import {
    UserName,
    EmailLinksAsCSV,
    ContactAsCSV,
    AgeNow
} from 'components/admin/common';
import ReactJson from 'react-json-view';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Description,
    AddressView,
    ParentDetailView
} from 'components/admin/common';
import { getLetters } from 'services/static';

export default class UserPreview extends Component {
    state = {
        isLoading: true,
        open: false,
        openChild: false,
        openChildren: false,
        uid: null,
        utype: 'user',
        data: {}
    };

    async componentDidMount() {
        const { uid, utype } = this.props;
        this.getDetails(uid, utype);
    }

    componentDidUpdate(prevProps) {
        if (this.props.uid !== prevProps.uid) {
            console.log('CID change!' + this.props.uid);
            this.getDetails(this.props.uid, this.props.userType);
        }
    }

    getDetails = async (uid, type) => {
        this.setState({ isLoading: true });
        const {
            data: { user }
        } = await getUserPreview(uid, type);

        this.setState({ data: user, isLoading: false });
    };
    render() {
        const { isPageLoading, isLoading, data } = this.state;
        const {
            session,
            uid,
            uname,
            uicon,
            utype,
            colorCode,
            _id,
            fname,
            mname,
            lname
        } = this.props;
        return (
            <div className='page page-user-preview p-4'>
                <UserName name={uname} icon={uicon} utype={utype} />
                <hr />
                {isLoading && <Loading />}
                {!isLoading && (
                    <Fragment>
                        {/* <p>content goes here</p> */}
                        <Fragment>
                            <h6>Basic Details</h6>

                            <table className='table table-borderless user-preview'>
                                <tbody>
                                    {/* <tr>
                                        <td>
                                            <Description title="Email ID's">
                                                <EmailLinksAsCSV
                                                    emailIds={
                                                        data.groupEmailIds
                                                    }
                                                />
                                            </Description>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Description title='Contact Number'>
                                                <ContactAsCSV
                                                    contactNos={
                                                        data.groupContactNos
                                                    }
                                                />
                                            </Description>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </Fragment>

                        <div className='details'>
                            <h6>User Details</h6>

                            {_id && (
                                <div className='label-details'>
                                    Id
                                    <span>{data._id}</span>
                                </div>
                            )}
                            {uid && (
                                <div className='label-details'>
                                    User Id
                                    <span>{data.uid}</span>
                                </div>
                            )}
                        </div>
                        <div className='details'>
                            <h6>Personal Details</h6>
                            <table className='table table-borderless'>
                                <tbody>
                                    <div className='row'>
                                        <div className='col'>
                                            <Description
                                                title='Full Name'
                                                empty=''>
                                                <span>{data.title}</span>.
                                                <span>{data.displayName}</span>
                                            </Description>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            <Description title='Email' empty=''>
                                                <EmailLinksAsCSV
                                                    emailIds={data.email}
                                                />
                                            </Description>
                                        </div>
                                        <div className='col'>
                                            <Description
                                                title='Contact No'
                                                empty=''>
                                                <ContactAsCSV
                                                    contactNos={data.mobile}
                                                />
                                            </Description>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 col-md-6'>
                                            <Description
                                                title='Date of Birth'
                                                empty=''>
                                                {data.dob}
                                                {data.dob && (
                                                    <small className='text-muted'>
                                                        {' '}
                                                        (
                                                        <AgeNow
                                                            dob={data.dob}
                                                        />
                                                        )
                                                    </small>
                                                )}
                                            </Description>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <Description
                                                title='Birth Place'
                                                empty=''>
                                                {data.birthPlace}
                                            </Description>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 col-md-6'>
                                            <Description
                                                title='Gender'
                                                empty=''>
                                                {data.gender}
                                            </Description>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <Description
                                                title='Blood Group'
                                                empty=''>
                                                {data.bloodGroup}
                                            </Description>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-12 col-md-6'>
                                            <Description
                                                title='Nationality'
                                                empty=''>
                                                {data.nationality}
                                            </Description>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <Description
                                                title='Mother Tongue'
                                                empty=''>
                                                {data.motherTongue}
                                            </Description>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 col-md-6'>
                                            <Description
                                                title='Religion'
                                                empty=''>
                                                {data.religion}
                                            </Description>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <Description title='Caste' empty=''>
                                                <span>
                                                    {data.casteCategory}
                                                </span>
                                                {data.subCaste && (
                                                    <span>
                                                        {' '}
                                                        - {data.subCaste}
                                                    </span>
                                                )}
                                            </Description>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            <Description
                                                title='Physically Challenged'
                                                empty=''>
                                                {data.physicallyChallenged
                                                    ? 'Yes'
                                                    : 'No'}
                                            </Description>
                                        </div>
                                    </div>
                                </tbody>
                            </table>
                        </div>
                        <div className='details'>
                            <h6>Parent Details</h6>
                            {data.parentDetails && (
                                <ParentDetailView
                                    parentDetails={data.parentDetails}
                                />
                            )}
                        </div>
                        <div className='details'>
                            <h6>Permanent Address</h6>
                            {!(data.address && data.address.line1) && (
                                <span className='text-muted'>
                                    Address not set
                                </span>
                            )}
                            {data.address && data.address.line1 && (
                                <AddressView address={data.address} />
                            )}
                            {!data.isPresentSameAsPermanentAddress && (
                                <div>
                                    <h6>Present Address</h6>
                                    {!(
                                        data.presentAddress &&
                                        data.presentAddress.line1
                                    ) && (
                                        <span className='text-muted'>
                                            Present address is not set
                                        </span>
                                    )}
                                    {data.presentAddress &&
                                        data.presentAddress.line1 && (
                                            <AddressView
                                                address={data.presentAddress}
                                            />
                                        )}
                                </div>
                            )}
                        </div>

                        {data.eduStatus && data.eduStatus.length > 0 && (
                            <div>
                                <h6>Education</h6>
                                {data.eduStatus.map((key, idx) => {
                                    return (
                                        <UserEduCourse
                                            data={data[key]}
                                            key={idx}
                                        />
                                    );
                                })}
                            </div>
                        )}
                        {data.experience && data.experience.length > 0 && (
                            <div>
                                <h6>Experience</h6>
                                {data.experience.map((e, idx) => {
                                    return (
                                        <UserEduExperience data={e} key={idx} />
                                    );
                                })}
                            </div>
                        )}

                        {data.knownLanguages && data.knownLanguages.length > 0 && (
                            <div className='education-details knownLanguages'>
                                <h6>Known Languages</h6>
                                <UserEduKnownLanguages
                                    data={data.knownLanguages}
                                />
                            </div>
                        )}

                        <ReactJson src={data} />
                    </Fragment>
                )}
            </div>
        );
    }
}

const UserEduCourse = ({ data }) => {
    return (
        <div className='education-details'>
            <div
                className={`placeholder letter-${getLetters(
                    data.instituteOrUniversity,
                    1
                )}`}
                data-letters={getLetters(data.instituteOrUniversity)}
            />
            <div>
                <h5>{data.instituteOrUniversity}</h5>
                {data.courseTitle && data.courseTitle != '' && (
                    <p>
                        {data.courseTitle}, {data.specialization}
                    </p>
                )}
                {data.duration &&
                    data.duration.start &&
                    data.duration.start != '' && (
                        <span class='duration text-muted'>
                            {data.duration.start} - {data.duration.end}
                        </span>
                    )}

                {data.activitiesAndSocieties && (
                    <div>{data.activitiesAndSocieties}</div>
                )}
            </div>
        </div>
    );
};

const UserEduExperience = ({ data }) => {
    return (
        <div className='education-details'>
            {data.company && (
                <div
                    className={`placeholder letter-${getLetters(
                        data.company,
                        1
                    )}`}
                    data-letters={getLetters(data.company)}
                />
            )}
            <div>
                {data.company && <h5>{data.company}</h5>}
                {data.title && data.title != '' && (
                    <p>
                        {data.title}, {data.companyLocation}
                    </p>
                )}
                {data.duration &&
                    data.duration.start &&
                    data.duration.start != '' && (
                        <span class='duration text-muted'>
                            {data.duration.start} - {data.duration.end}
                        </span>
                    )}

                {data.desc && <div>{data.desc}</div>}
            </div>
        </div>
    );
};

// UserEduknownLanguages
// "name":string"name1"
// "read":string"Yes"
// "write":string"No"
// "speak":string"Yes"
// "nativeLanguage":booltrue

const UserEduKnownLanguages = ({ data }) => {
    return (
        <table className='table table-bordered'>
            <thead>
                <tr>
                    <th>
                        <h5>Languages</h5>
                    </th>
                    <th>
                        <h5>Read</h5>
                    </th>
                    <th>
                        <h5>Write</h5>
                    </th>
                    <th>
                        <h5>Speak</h5>
                    </th>
                </tr>
            </thead>
            <tbody className='text-center'>
                {data.map((e, idx) => {
                    return (
                        <tr key={idx}>
                            <td>{e.name}</td>
                            <td>{e.read == 'yes' ? '✔' : '✘'}</td>
                            <td>{e.write == 'yes' ? '✔' : '✘'}</td>
                            <td>{e.speak == 'yes' ? '✔' : '✘'}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
