import { FormGroupInput, SubmitButton } from 'components/common/forms';
import { getQueryParams, Loading, Logo } from 'components/common';
import { Header, Footer } from 'components/core';
import { BoxWrapper } from 'components/core/box';
import { Form } from 'informed';
import Joi from 'joi-browser';
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Alert } from 'reactstrap';
import auth from 'services/authService';

export default class LoginPage extends Component {
    state = { isPageLoading: false };
    render() {
        const { isPageLoading } = this.state;
        const { session } = this.props;
        const params = getQueryParams(this.props);

        return (
            <div className='page page-login box bg-white login-bg'>
                {/* <Header session={session} container /> */}
                <div className='container pt-4'>
                    {isPageLoading && <Loading />}
                    {!isPageLoading && (
                        <BoxWrapper
                            // info={<LoginHelpBox {...this.props} params={params} />}
                            form={<LoginForm {...this.props} params={params} />}
                        />
                    )}
                </div>

                <Footer className='mt-5' />
            </div>
        );
    }
}

class LoginHelpBox extends Component {
    render() {
        return (
            <Fragment>
                <Logo center />
                <h4>Note</h4>
                <ul>
                    <li>
                        Enter the user name and password, then click{' '}
                        <strong>Login</strong>
                    </li>
                    <li>
                        Password is case sensitive. Please do not include any
                        space.
                    </li>
                </ul>
            </Fragment>
        );
    }
}

class LoginForm extends Component {
    state = {
        data: {
            username: '',
            password: '',
            rememberMe: false,
            next: ''
        },
        errors: {},
        isLoading: false
    };

    schema = {
        username: Joi.string()
            .required()
            .label('Username'),
        password: Joi.string()
            .required()
            .label('Password'),
        rememberMe: Joi.boolean()
    };

    componentDidMount() {
        const sampleData = this.getSampleData();
        this.formApi.setValues(sampleData);
    }

    getSampleData = () => {
        return {
            username: 'sdev',
            password: '123',
            rememberMe: true,
            next: '/random'
        };
    };

    validateProperty = (name, value) => {
        const schema = Joi.reach(Joi.object(this.schema), name);
        const { error } = Joi.validate(value, schema);
        if (error) console.log(error);
        return error ? error.details[0].message : null;
    };

    validate = () => {
        const options = { abortEarly: false };
        const data = this.formApi.getState().values;

        const { error } = Joi.validate(data, this.schema, options);
        if (!error) return null;
        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message;
        return errors;
    };

    setFormApi = formApi => {
        this.formApi = formApi;
    };

    onSubmit = async () => {
        const data = this.formApi.getState().values;

        try {
            this.setState({ isLoading: true, errors: {} });
            const result = await auth.login(
                data.username,
                data.password,
                data.rememberMe,
                data.next
            );
            // console.log(result)

            window.location = result.data.next;

            this.setState({ isLoading: false });
        } catch (ex) {
            this.setState({ isLoading: false });
            if (ex.response && ex.response.data) {
                const errors = { ...this.state.errors, ...ex.response.data };
                this.setState({ errors });
            } else {
                const errors = {
                    ...this.state.errors,
                    message: 'Sorry some error occurred. Please try again',
                    class: 'danger'
                };
                this.setState({ errors });
            }
        }
    };

    render() {
        const { isLoading, errors } = this.state;
        const queryParams = getQueryParams(this.props);

        return (
            <Form
                className='bg-white login-form mb-4'
                getApi={this.setFormApi}
                onSubmit={this.onSubmit}>
                {({ formApi, formState }) => (
                    <Fragment>
                        <div className='m-4'>
                            <NavLink to='/'>
                                <Logo center />
                            </NavLink>
                        </div>
                        <hr />
                        <div className='py-4 px-5'>
                            <div className='text-center'>
                                <h3 className='text-gradient-eco'>Login</h3>
                                <small className='text text-muted'>
                                    All the fields required
                                </small>
                            </div>
                            {queryParams && queryParams.isExpired && (
                                <Alert color='warning' className='mt-3'>
                                    <strong>⭐Session Expired</strong> Please
                                    login again...
                                </Alert>
                            )}

                            <FormGroupInput
                                field='username'
                                label='Username'
                                validateOnBlur
                                validate={e =>
                                    this.validateProperty('username', e)
                                }
                            />
                            <FormGroupInput
                                field='password'
                                type='password'
                                label='Password'
                                validateOnBlur
                                validate={e =>
                                    this.validateProperty('password', e)
                                }
                            />

                            {/* <div className='d-flex justify-content-end'>
                            <NavLink
                                to={{
                                    pathname: '/forgot-password',
                                    search: `${
                                        this.props.location.search
                                    }&username=${formState.values.username}`,
                                    hash: this.props.location.hash
                                }}
                                className='text text-muted'>
                                Forgot Password?
                            </NavLink>
                        </div> */}

                            <div className='mt-3 text-center'>
                                {
                                    <SubmitButton
                                        extraclass='w-100'
                                        isLoading={isLoading}
                                        value='Login'
                                    />
                                }
                            </div>
                            {errors && errors.message && (
                                <Alert color={errors.class} className='mt-3'>
                                    {errors.message}
                                </Alert>
                            )}

                            {/* <p className='pt-3'>
                            Click here to{' '}
                            <NavLink
                                to={{
                                    pathname: '/register',
                                    search: this.props.location.search,
                                    hash: this.props.location.hash
                                }}
                                className='text text-muted'>
                                Register
                            </NavLink>
                        </p> */}
                            <p className='pt-3'>
                                Back to{' '}
                                <NavLink
                                    to={{
                                        pathname: '/',
                                        search: this.props.location.search,
                                        hash: this.props.location.hash
                                    }}
                                    className='text text-muted'>
                                    Home
                                </NavLink>
                            </p>
                        </div>
                    </Fragment>
                )}
            </Form>
        );
    }
}
