import classnames from 'classnames';
import { getCourseLink, getQueryParams } from 'components/common';
import NotesBlock from 'components/icourse/notes';
import Quiz from 'components/icourse/quiz';
import { minsToHHMM } from 'components/libs';
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Nav, NavItem, NavLink as RNavLink, TabContent, TabPane } from 'reactstrap';
import { changeCourseTopic, getCourse } from 'services/courseService';

import VideoContainer from './video';

export default class CoursePlayer extends Component {

    state = {
        id: "",
        course: {},
        isPageLoading: true,
        isLoading: true,

        activeTab: '1',
        sidemenuOpen: true,

        notesText: "",
        addNoteTime: 0,

        isMouseInside: true,

        currentTopicId: null,
        currentModuleId: null,
        changedTopic: false,
        autoNextTopic: false,
    }


    async componentDidMount() {
        const { scheduleId } = this.props.match.params;
        const { tid, mid } = getQueryParams(this.props)

        this._loadCourse(scheduleId, tid, mid)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { tid: currentTopicId, mid: currentModuleId } = getQueryParams(nextProps)
        if ((prevState.currentTopicId && prevState.currentTopicId != '' && currentTopicId && currentTopicId !== prevState.currentTopicId) ||
            (prevState.currentModuleId && prevState.currentModuleId != '' && currentModuleId && currentModuleId !== prevState.currentModuleId)) {
            return { currentTopicId, currentModuleId, changedTopic: true };
        }
        else return null;
    }

    _loadCourse = async (scheduleId, tid, mid) => {
        const { data: course } = await getCourse(scheduleId, { tid, mid });
        const { progress, courseTrack } = course;
        return await this.setState({ course, progress, courseTrack, scheduleId, isPageLoading: false, isLoading: false, currentTopicId: tid, currentModuleId: mid });
    }

    _changeTopic = async (scheduleId, tid, mid, currentProgress) => {
        const { data: { progress, courseTrack } } = await changeCourseTopic(scheduleId, { tid, mid, progress: currentProgress });
        await this.setState({ isLoading: false, progress, courseTrack, autoNextTopic: false, currentModuleId: courseTrack['currentModuleId'], currentTopicId: courseTrack['currentTopicId'] })

        if (currentProgress['topicTrigger'] === 'AUTONEXT') {
            await this.changeTopic(this.state.currentTopicId, this.state.currentModuleId)
        }
    }



    onEnded = async () => {
        if (!this.state.autoNextTopic) {
            await this.setState({ autoNextTopic: true })
            const { scheduleId, course, progress, courseTrack } = this.state;
            const { currentTopicNo, currentModuleNo, currentModuleId, currentTopicId } = courseTrack;

            let nextModuleNo = currentModuleNo;
            let nextTopicNo = currentTopicNo + 1;
            let topicId = null;
            let moduleId = null;
            let isNextModule = false;
            let endOfCourse = false;

            if (course && course['modules'] && course['modules'][currentModuleNo]) {
                if (course['modules'][currentModuleNo]['tids'].length > nextTopicNo) {
                    topicId = course['modules'][currentModuleNo]['tids'][nextTopicNo]
                    moduleId = currentModuleId
                } else {
                    nextModuleNo = nextModuleNo + 1;
                    if (course && course['mids'] && course['mids'].length > nextModuleNo) {
                        isNextModule = true;
                        moduleId = course['mids'][nextModuleNo]
                        topicId = course['modules'][nextModuleNo]['tids'][0]
                    } else {
                        endOfCourse = true
                    }
                }
            }
            if (topicId && topicId != currentTopicId && moduleId) {
                const currentProgress = {
                    tid: currentTopicId,
                    mid: currentModuleId,
                    sidemenuOpen: this.state.sidemenuOpen,
                    volume: this.state.volume,
                    endTime: parseInt(this.state.playedSeconds),
                    topicTrigger: 'AUTONEXT',
                    isCompleted: true
                }

                const url = getCourseLink(scheduleId, topicId, moduleId)
                this.props.history.push(url)
                await this._changeTopic(scheduleId, topicId, moduleId, currentProgress);
            }
        }
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    mouseEnter = () => {
        this.setState({ isMouseInside: true });
    }
    mouseLeave = () => {
        this.setState({ isMouseInside: false });
    }

    addNote = () => {
        const { played, currentTopicId } = this.state
        this.setState({ 'activeTab': '2', addNoteTime: Math.floor(played * 100), currentTopicId: currentTopicId })
    }

    updateNoteTime = () => {
        const { played, currentTopicId } = this.state
        this.setState({ addNoteTime: Math.floor(played * 100) })
    }

    isTopicCompleted = (tid) => {
        const { progress } = this.state;
        if (progress && progress[tid]) {
            return progress[tid]['isCompleted']
        }
        return false
    }


    sidemenuToggle = () => {
        this.setState(prevState => {
            return { sidemenuOpen: !prevState.sidemenuOpen }
        })
    }

    changeTopic = async (tid, mid) => {
        const url = getCourseLink(this.state.scheduleId, tid, mid)
        this.props.history.push(url)

        const currentProgress = {
            tid: this.state.currentTopicId,
            mid: this.state.currentModuleId,
            sidemenuOpen: this.state.sidemenuOpen,
            volume: this.state.volume,
            endTime: parseInt(this.state.playedSeconds),
            topicTrigger: 'MANUAL',
            isCompleted: parseInt(this.state.duration) == parseInt(this.state.playedSeconds) ? true : false
        }

        await this._changeTopic(this.state.scheduleId, tid, mid, currentProgress);
    }

    setIsPlaying = (playing) => {
        this.setState({ playing })
    }

    setPlayedSeconds = (playedSeconds) => {
        this.setState({ playedSeconds })
    }

    setVideoDuration = (duration) => {
        this.setState({ duration })
    }

    setVolume = (volume) => {
        this.setState({ volume })
    }

    render() {

        const { isPageLoading, isLoading, course, scheduleId, playing,
            isMouseInside, sidemenuOpen,
            progress, courseTrack, currentTopicId } = this.state;
        const { addNoteTime } = this.state

        const contentType = courseTrack && courseTrack['contentType'] ? courseTrack['contentType'] : 'error';

        return (
            <Fragment>
                <div className={`page page-player page-player--${contentType}`}>
                    <section id="app" className={classnames('w-100 d-md-flex notes-enabled', { 'side-menu': sidemenuOpen })}>
                        <div id="player-container" className={`player-container container-${contentType} d-md-flex justify-content-md-center`} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                            <header className={classnames("video-header gradient-fade d-flex", { 'fade-out': !isMouseInside && playing })}>
                                {scheduleId && courseTrack && <div className="d-flex flex-grow-1 gradient-fade">
                                    <div className="col pt-md pl-lg">
                                        <div className="title-course ps-color-white" id="course-title">
                                            <NavLink className="ps-link-white" to={`/course/${scheduleId}/details`} id="course-title-link">React Fundamentals</NavLink>
                                        </div>
                                        <div className="title-clip ps-type-xs ps-color-gray-02" id="module-clip-title">{courseTrack['moduleName']} : {courseTrack['name']}</div>
                                    </div>
                                    <div className="d-flex align-items-start pt-tiny pr-sm">
                                        <div className="button-with-floating-content undefined d-none d-md-flex">
                                            <button id="side-menu-button" className={classnames("icobutton hidden-md-down pl-md fa", { 'fa-side-close': sidemenuOpen, 'fa-side-open': !sidemenuOpen })} onClick={(e) => this.sidemenuToggle()}></button>
                                            <span></span>
                                        </div>
                                        <button id="fullscreen-control" className="requires-fullscreen fa fa-full-open icobutton hidden-sm-up" title="Full screen (F)">
                                        </button>
                                    </div>
                                </div>}
                            </header>
                            {contentType == "quiz" &&
                                <Quiz
                                    courseTrack={courseTrack}
                                    progress={progress}
                                />
                            }
                            {contentType == "video" &&
                                <VideoContainer
                                    courseTrack={courseTrack}

                                    setIsPlaying={this.setIsPlaying}
                                    setPlayedSeconds={this.setPlayedSeconds}
                                    setVideoDuration={this.setVideoDuration}
                                    setVolume={this.setVolume}

                                />
                            }
                        </div>

                        <span>

                            <CSSTransition
                                in={sidemenuOpen}
                                timeout={300}
                                classNames="ps-sidemenu"
                                unmountOnExit
                            // onEnter={() => setShowButton(false)}
                            // onExited={() => setShowButton(true)}
                            >
                                <aside id="side-menu" className="d-flex flex-column ps-color-bg-gray-04 ">
                                    <Nav tabs>
                                        <NavItem>
                                            <RNavLink
                                                className={classnames({ active: this.state.activeTab === '1' })}
                                                onClick={() => { this.toggle('1'); }}>
                                                Table Of Contents
                                        </RNavLink>
                                        </NavItem>
                                        {/* <NavItem>
                                            <RNavLink
                                                className={classnames({ active: this.state.activeTab === '2' })}
                                                onClick={() => { this.toggle('2'); }} >
                                                Notes
                                        </RNavLink>
                                        </NavItem> */}
                                    </Nav>
                                    <TabContent activeTab={this.state.activeTab}>
                                        <TabPane tabId="1">
                                            <div id="tab-table-of-contents">

                                                {course.modules && course.modules.map((module, midx) => {
                                                    return <section key={midx}>
                                                        <header className="active-module d-flex" role="" tabIndex="0">
                                                            <div className="side-menu-module-progress d-flex justify-content-center align-items-center">
                                                                {/* 
                                                                    <div className="icon-complete ps-type-lg ps-color-bg-gray-05 ps-color-green rounded-circle">
                                                                        <span className="ps-type-tiny ps-color-white">2</span>
                                                                    </div> 
                                                                */}
                                                                <div className="viz-container rounded-circle ps-color-bg-gray-05 d-flex justify-content-center align-items-center">
                                                                    <span className="ps-type-tiny ps-color-white">{midx + 1}</span>
                                                                </div>
                                                            </div>
                                                            <div className="pr-lg py-md side-menu-module-title">
                                                                <h2 className="m-0 p-0 ps-color-white ps-type-sm ps-type-weight-medium">{module.name}</h2>
                                                                <div className="ps-color-gray-02 ps-type-tiny ps-type-weight-book side-menu-module-duration">
                                                                    <span className="fa fa-time pr-tiny ps-type-xs"></span>
                                                                    {minsToHHMM(module.duration)}
                                                                </div>
                                                            </div>
                                                            <div className="ps-color-gray-02 ps-type-lg align-self-center p-md">
                                                            </div>
                                                        </header>
                                                        <ul className="clips m-0 p-0">
                                                            {module.topics && module.topics.map((topic, tidx) => {
                                                                // ps-color-gray-02 ps-color-orange selected watched
                                                                return <li className="d-flex ps-color-bg-gray-05 py-sm pr-lg mx-0 clip-bg side-menu-clip  ps-color-gray-02 " tabIndex="0" key={tidx} onClick={(e) => { this.changeTopic(topic.topicId, module.moduleId) }}>
                                                                    <div className="d-flex side-menu-clip-progress justify-content-center align-items-center">
                                                                        {currentTopicId && currentTopicId == topic.topicId && <div className="ps-color-bg-gray-05 ps-color-orange rounded-circle fa fa-play-circle ps-type-xs"></div>}
                                                                        {this.isTopicCompleted(topic.topicId) && currentTopicId && currentTopicId != topic.topicId && <div className="fa fa-check-circle ps-color-green ps-type-xs"></div>}
                                                                        {!this.isTopicCompleted(topic.topicId) && currentTopicId && currentTopicId != topic.topicId && <div className="side-menu-clip-unwatched-icon ps-color-bg-gray-04 rounded-circle"></div>}
                                                                    </div>
                                                                    <h3 className="m-0 p-0 ps-type-xs ps-type-weight-book side-menu-clip-title ps-type-ellipsis">{topic.name}</h3>
                                                                    <div className="pl-tiny ps-type-xs ps-type-weight-book side-menu-clip-duration">
                                                                        {minsToHHMM(topic.duration)}
                                                                    </div>
                                                                </li>
                                                            })}
                                                        </ul>
                                                    </section>
                                                })}
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            {scheduleId && <NotesBlock
                                                scheduleId={scheduleId}
                                                addNoteTime={addNoteTime}
                                                currentTopicId={currentTopicId}
                                                updateNoteTime={this.updateNoteTime}

                                            />}
                                        </TabPane>
                                    </TabContent>
                                </aside>
                            </CSSTransition>
                        </span>
                    </section>
                </div>
            </Fragment >
        )
    }
}
