import 'styles/App.scss';

import App from 'components/App';
import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from 'serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister(); 
