import { FormGroupInput, SubmitButton } from 'components/common/forms';
import { getQueryParams, Loading, Logo } from 'components/common';
import { Header } from 'components/admin/common/header';
import TestList from 'components/admin/itest/list';
import React, { Component, Fragment } from 'react';
import { getAdminTests } from 'services/testService';

export default class TestsPage extends Component {
    state = { isPageLoading: true };

    async componentDidMount() {
        const { data } = await getAdminTests();

        this.setState({ data, isPageLoading: false });
    }
    render() {
        const { isPageLoading, isLoading, data } = this.state;
        const { session } = this.props;

        return (
            <div className='page page-tests box'>
                <Header session={session} container />
                <div className='container pt-4'>
                    {isPageLoading && <Loading />}
                    {!isPageLoading && (
                        <Fragment>
                            {/* {data && data.length <= 0 && 'No Tests found'}
                            {data && data.length > 0 && (
                                <TestList data={data} />
                            )} */}
                            {data && <TestList data={data} />}
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}
