import ClientList from 'components/admin/iclient/list';
import { Loading } from 'components/common';
import { Header } from 'components/admin/common/header';
import React, { Component, Fragment } from 'react';
import { getClientPreview } from 'services/clientService';
import Drawer from 'rc-drawer';
import {
    ClientName,
    EmailLinksAsCSV,
    ContactAsCSV
} from 'components/admin/common';
import ReactJson from 'react-json-view';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Description, AddressView } from 'components/admin/common';

export default class ClientPreview extends Component {
    state = {
        isLoading: true,
        open: false,
        openChild: false,
        openChildren: false,
        cid: null,
        ctype: 'client',
        data: {}
    };

    async componentDidMount() {
        const { cid, ctype } = this.props;
        this.getDetails(cid, ctype);
    }

    componentDidUpdate(prevProps) {
        if (this.props.cid !== prevProps.cid) {
            console.log('CID change!' + this.props.cid);
            this.getDetails(this.props.cid, this.props.ctype);
        }
    }

    getDetails = async (cid, type) => {
        this.setState({ isLoading: true });
        const {
            data: { group }
        } = await getClientPreview(cid, type);

        this.setState({ data: group, isLoading: false });
    };
    render() {
        const { isPageLoading, isLoading, data } = this.state;
        const { session, cid, cname, cicon, colorCode } = this.props;
        return (
            <div className='page page-client-preview p-4'>
                <ClientName name={cname} icon={cicon} colorCode={colorCode} />
                <hr />
                {isLoading && <Loading />}
                {!isLoading && (
                    <Fragment>
                        {/* <p>content goes here</p> */}
                        <Fragment>
                            <h6>Institute Details</h6>
                            <table className='table table-borderless'>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Description title="Email ID's">
                                                <EmailLinksAsCSV
                                                    emailIds={
                                                        data.groupEmailIds
                                                    }
                                                />
                                            </Description>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Description title='Contact Number'>
                                                <ContactAsCSV
                                                    contactNos={
                                                        data.groupContactNos
                                                    }
                                                />
                                            </Description>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Fragment>
                        {!_.includes(
                            ['client', 'entity', 'branch'],
                            data.type
                        ) && (
                            <Fragment>
                                <h6>Academic Details</h6>
                                <table className='table table-borderless'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Description
                                                    title='Current Year'
                                                    empty='Current year not set'>
                                                    {data.currentYear}
                                                </Description>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Description
                                                    title='Academic Year'
                                                    empty='Academic Year not set'>
                                                    {data.academicYear}
                                                </Description>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Description
                                                    title='Period Start/End Date'
                                                    empty='Period Start/End Date not set'>
                                                    {data.periodStartDate}
                                                    <span> - </span>
                                                    {data.periodEndDate}
                                                </Description>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr />
                            </Fragment>
                        )}

                        <h6>Details Summary</h6>
                        <table className='table table-borderless'>
                            <tbody>
                                <tr>
                                    <td>
                                        <Description
                                            title='Category'
                                            empty='Category not set'>
                                            {data.category}
                                        </Description>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Description
                                            title='Committee'
                                            empty='Committee not set'>
                                            {data.committee}
                                        </Description>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Description
                                            title='Affiliation'
                                            empty='Affiliation not set'>
                                            {data.affiliation}
                                        </Description>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Description
                                            title='Rules and Regulations'
                                            empty='Rules and Regulations not set'>
                                            {data.rulesAndRegulations}
                                        </Description>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className='address'>
                            <h6>Address</h6>
                            {!(data.address && data.address.line1) && (
                                <span className='text-muted'>
                                    Address not set
                                </span>
                            )}
                            {data.address && data.address.line1 && (
                                <AddressView address={data.address} />
                            )}
                        </div>

                        <h6>Groupwise Stats</h6>
                        <table className='table table-bordered stats'>
                            <thead>
                                <tr>
                                    <th>Entities</th>
                                    <th>Branches</th>
                                    <th>Departments</th>
                                    <th>Batches</th>
                                    <th>Groups</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{data.stats && data.stats.entities}</td>
                                    <td>{data.stats && data.stats.branches}</td>
                                    <td>
                                        {data.stats && data.stats.departments}
                                    </td>
                                    <td>{data.stats && data.stats.batches}</td>
                                    <td>{data.stats && data.stats.groups}</td>
                                </tr>
                            </tbody>
                        </table>
                        <h6>Userwise Stats</h6>
                        <table className='table table-bordered stats'>
                            <thead>
                                <tr>
                                    {/* <th>Total Active Users</th> */}
                                    <th>Students</th>
                                    <th>Staff's</th>
                                    <th>Admins</th>
                                    <th>Super Admins</th>
                                    <th>Blocked Users</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {/* <td>{data.stats && data.stats.entities}</td> */}
                                    <td>{data.stats && data.stats.students}</td>
                                    <td>{data.stats && data.stats.staffs}</td>
                                    <td>{data.stats && data.stats.admins}</td>
                                    <td>
                                        {data.stats && data.stats.superAdmins}
                                    </td>
                                    <td>
                                        {data.stats && data.stats.blockedUsers}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ReactJson src={data} />
                    </Fragment>
                )}
            </div>
        );
    }
}
