import { FormGroupInput, SubmitButton } from 'components/common/forms';
import { getQueryParams, Loading, Logo } from 'components/common';
import { Header } from 'components/core';
import { BoxWrapper } from 'components/core/box';
import { Form } from 'informed';
import Joi from 'joi-browser';
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Alert } from 'reactstrap';
import auth from 'services/authService';

export default class ProfilePage extends Component {
    state = { isPageLoading: false };
    render() {
        const { isPageLoading } = this.state;
        const { session } = this.props;
        const params = getQueryParams(this.props);
        return (
            <div className='page page-login box'>
                <Header session={session} container />
                <div className='container'>
                    {isPageLoading && <Loading />}
                    {!isPageLoading && <ProfileView session={session} />}
                </div>
            </div>
        );
    }
}

class ProfileView extends Component {
    state = {
        data: {},
        errors: {},
        isLoading: false
    };

    componentDidMount() {}

    render() {
        const { isLoading, errors } = this.state;
        const { session } = this.props;
        console.log(session);

        return (
            <div>
                Profile
                <pre>{JSON.stringify(session)}</pre>
            </div>
        );
    }
}
