// import 'styles/course.scss';

import { Loading } from 'components/common';
import { Header, SideNav } from 'components/core';
import React, { Component, Fragment, Container } from 'react';
import { getCourses } from 'services/courseService';
import { Link } from 'react-router-dom';

import CourseList from './list';
import Course from './view';
import CoursePLayer from './player'

export default class Courses extends Component {
    state = {
        data: [],
        parentData: [],
        prefixUrl: "",
        isPageLoading: true,
        isLoading: false
    }

    async componentDidMount() {
        this.getCourses(true, true)
    }

    getCourses = async (isPageLoading = false, isLoading = true) => {
        await this.setState({ isPageLoading, isLoading });

        const { data } = await getCourses();
        await this.setState({ data, isPageLoading: false, isLoading: false });
    }

    render() {
        const { isPageLoading, isLoading, errors, data: { schedules, courses, progress }, parentData, prefixUrl, type, id } = this.state;
        const { session } = this.props;


        return (
            <Fragment>
                <Header session={session} container />
                <div className="bg-white page page-course">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-10 content scroll">
                                {isPageLoading && <Loading />}
                                {!isPageLoading &&
                                    <CourseList
                                        schedules={schedules}
                                        courses={courses}
                                        progress={progress}
                                        refresh={this.getCourses}
                                        isLoading={isLoading}
                                    />
                                }
                            </div>
                            <div className="col-12 col-sm-4 col-lg-2 sidemenu-wrapper mb-4 mb-md-0">
                                <div className="promo">
                                    <img src="/assets/courses/test.svg" />
                                    <h2>Exams</h2>
                                    <ul>
                                        <li>
                                            <Link to="#">NEET Mock Tests</Link>
                                        </li>
                                        <li>
                                            <Link to="#">About NEET</Link>
                                        </li>
                                        <li>
                                            <Link to="#">Ask your doubts</Link>
                                        </li>

                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <Course /> 
                 <CoursePLayer /> */}
            </Fragment>
        );
    }
}

