import classnames from 'classnames';
import React, { Component, Container, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import ScrollableAnchor, { goToAnchor, goToTop } from 'react-scrollable-anchor';
import { Footer } from 'components/core';
import {
    Collapse,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem
} from 'reactstrap';

export default class Home extends Component {
    state = {
        data: [],
        parentData: [],
        prefixUrl: '',
        isPageLoading: true,
        isLoading: false
    };
    // constructor(props) {
    //   super(props);
    //   this.state = {
    //     isOpen: false
    //   };

    //   this.toggle = this.toggle.bind(this);
    //   this.togglesidebar = this.togglesidebar.bind(this);
    // }

    // toggle() {
    //   this.setState({
    //     isOpen: !this.state.isOpen
    //   });
    // }
    // async componentDidMount() {
    //     this.getCourses(true, true)
    // }
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };

        this.toggle = this.toggle.bind(this);
        this.togglesidebar = this.togglesidebar.bind(this);
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    togglesidebar() {}

    getLetters = str => {
        if (!str) return '';
        const matches = str.match(/\b(\w)/g);
        const acronym = matches.slice(0, 2).join('');
        return acronym;
    };

    goToTop = () => {
        goToTop();
    };

    goToAnchor = sectionName => {
        goToAnchor(sectionName);
    };
    //  data: { schedules, courses, progress }
    render() {
        const {
            isPageLoading,
            isLoading,
            errors,
            parentData,
            prefixUrl,
            type,
            id
        } = this.state;
        const { session, container } = this.props;

        return (
            <Fragment>
                {/* <Header session={session} container /> */}
                <Navbar
                    color='light'
                    light
                    expand='md'
                    className='nav-eco bg-white'>
                    <div
                        className={classnames({
                            container: true
                        })}>
                        <NavbarBrand to='/index' className='d-flex flex-row'>
                            <img
                                className='img-fluid brand-logo-img'
                                src='/assets/home/logo-clr.svg'
                                alt='Image'
                            />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className='ml-auto' navbar>
                                <Fragment>
                                    <NavItem>
                                        <NavLink
                                            to='/'
                                            className='nav-link'
                                            onClick={this.goToTop}>
                                            Home
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            to='#about-us'
                                            className='nav-link'
                                            onClick={() =>
                                                this.goToAnchor('about-us')
                                            }>
                                            About us
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            to='#join-us'
                                            className='nav-link'
                                            onClick={() =>
                                                this.goToAnchor('join-us')
                                            }>
                                            Join us
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink
                                            to='/login'
                                            className='nav-link'>
                                            Login
                                        </NavLink>
                                    </NavItem> */}
                                </Fragment>
                            </Nav>
                        </Collapse>
                    </div>
                </Navbar>
                <div className='bg-white page page-course'>
                    <div className='container'>
                        {/* <div className="row"> */}
                        {/* <div className="col-12 col-md-10 content scroll">
                {isPageLoading && <Loading />}
                {!isPageLoading &&
                    <CourseList
                        schedules={schedules}
                        courses={courses}
                        progress={progress}
                        refresh={this.getCourses}
                        isLoading={isLoading}
                    />
                }                              
            </div> */}
                        <section className='row bg bg--1 block-content align-items-center'>
                            <div className='block-content block-content--text col-md-5'>
                                <div className='block-intro-container'>
                                    <h2 className='block-heading'>
                                        Connecting dots by technology
                                        {/* <span className='dot' /> */}
                                    </h2>
                                    <p className='block-desc'>
                                        A premier solutions provider in the
                                        areas of providing learning management
                                        solutions for Schools and Colleges,
                                        Skill development services, Surveillance
                                        solutions, Biometric attendance, Systems
                                        hardware and Stationary suppliers.
                                    </p>
                                    <a
                                        className='btn btn-primary btn-sm block-btn'
                                        aria-current='page'
                                        href='#join-us'
                                        onClick={() =>
                                            this.goToAnchor('join-us')
                                        }>
                                        Join us
                                    </a>
                                </div>
                            </div>

                            <div className='block-content block-content--img col-md-7'>
                                <div className='block-intro-container'>
                                    <img
                                        className='img-fluid block-img'
                                        src='/assets/home/banner1.svg'
                                        alt='Bannar Image'
                                    />
                                </div>
                            </div>
                        </section>
                        <ScrollableAnchor id={'about-us'}>
                            <section className='row bg bg--2 block-content align-items-center'>
                                <div className='block-content block-content--img col-md-6'>
                                    <div className='block-intro-container'>
                                        <img
                                            className='img-fluid block-img'
                                            src='/assets/home/banner2.svg'
                                            alt='Bannar Image'
                                        />
                                    </div>
                                </div>
                                <div className='block-content block-content--text col-md-6'>
                                    <div className='block-intro-container'>
                                        <h6 className='block-title'>
                                            Let us tell you
                                        </h6>
                                        <h2 className='block-heading'>
                                            Who we are
                                            {/* <span className='dot' /> */}
                                        </h2>
                                        <p className='block-desc'>
                                            learners, thinkers, speakers,
                                            bikers, engineers, authors,
                                            photographers, readers, volunteers
                                            and hard workers. With such a
                                            diverse group of people, from so
                                            many different places, we bring a
                                            unique perspective to everything we
                                            do.
                                        </p>
                                    </div>
                                </div>
                            </section>
                        </ScrollableAnchor>
                    </div>
                    <div className='container-fluid bg bg--3'>
                        <span className='bg bg--4' />
                        <ScrollableAnchor id={'join-us'}>
                            <section className='row block-content align-items-start'>
                                <div className='block-content block-content--text col-md-5 offset-md-3'>
                                    <div className='block-intro-container'>
                                        <h2 className='block-sub-heading'>
                                            Interested in joining us?
                                        </h2>
                                        <div className='block-sub-panel block-desc'>
                                            <div className='col-sm-9 pl-0'>
                                                <p>
                                                    At DBEV, we provide a
                                                    vibrant, open-minded and
                                                    friendly work environment
                                                    that encourages innovation
                                                    and creativity. We are
                                                    looking for individuals who
                                                    value creativity, diversity,
                                                    inclusion and collaboration.
                                                </p>
                                                <p>
                                                    Please send your updated
                                                    resume to &nbsp;
                                                    <a href='mailto:hr@dbev.in'>
                                                        hr@dbev.in
                                                    </a>
                                                </p>
                                            </div>
                                            {/* <a
                                            className='col-sm-3 btn btn-primary btn-sm block-btn block-btn--filled'
                                            aria-current='page'
                                            href=''>
                                            Join us
                                        </a> */}
                                        </div>
                                    </div>
                                </div>

                                <div className='block-content block-content--img col-md-4'>
                                    <div className='block-intro-container'>
                                        <img
                                            className='img-fluid block-img'
                                            src='/assets/home/banner3.svg'
                                            alt='Dream Big - Join us'
                                        />
                                    </div>
                                </div>
                            </section>
                        </ScrollableAnchor>
                    </div>
                    <Footer />
                </div>
            </Fragment>
        );
    }
}
