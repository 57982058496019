import classnames from 'classnames';
import * as qs from 'query-string';
import React, { Component, Fragment } from 'react';
import { Redirect, Route } from 'react-router-dom';
import auth from 'services/authService';
import _ from 'lodash';
import { getLetters } from 'services/static';
import { getUserTypeLabels } from 'components/libs';
import moment from 'moment';

export const ClientName = ({ name, icon, colorCode }) => {
    colorCode = colorCode ? colorCode : '#41c0ed';
    return (
        <div className='clientname'>
            <div
                className={`placeholder`}
                data-letters={getLetters(name)}
                style={{ '--tooltip-color': colorCode }}>
                <div
                    className='icon'
                    style={{
                        backgroundImage: `url(${icon})`
                    }}
                />
            </div>
            <div className='title'>{name}</div>
        </div>
    );
};

export const UserName = ({ name, icon, colorCode, utype }) => {
    return (
        <div className='username'>
            <div className={`profilePic`} data-letters={getLetters(name)}>
                <div
                    className='avatar'
                    style={{
                        backgroundImage: `url(${icon})`
                    }}
                />
            </div>
            <span>
                <div className='title'>{name}</div>
                {utype && (
                    <div className='badge badge-light'>
                        {getUserTypeLabels(utype)}
                    </div>
                )}
            </span>
        </div>
    );
};

export const EmailLinksAsCSV = ({ emailIds }) => {
    var ids = [];
    if (!_.isArray(emailIds)) ids = emailIds.split(',');
    else ids = emailIds;
    return (
        <span>
            {ids.map((email, idx) => {
                return (
                    <span key={idx}>
                        <a href={`mailto:${_.trim(email)}`}>{_.trim(email)}</a>
                        {idx != ids.length - 1 ? ', ' : ''}
                    </span>
                );
            })}
        </span>
    );
};

export const ContactAsCSV = ({ contactNos }) => {
    var nos = [];
    if (!_.isArray(contactNos)) nos = contactNos.split(',');
    else nos = contactNos;
    return (
        <span>
            {nos.map((no, idx) => {
                return (
                    <span key={idx}>
                        <a href={`tel:${no}`}>{no}</a>
                        {idx != nos.length - 1 ? ', ' : ''}
                    </span>
                );
            })}
        </span>
    );
};

export const Description = ({ title, children, empty }) => {
    return (
        <div className='description d-flex align-items-center'>
            <span className='desc-item-label'>{title}</span>
            {children && <span className='desc-item-content'>{children}</span>}
            {!children && <span className='desc-item-empty'>{empty}</span>}
        </div>
    );
};

export const AddressView = ({
    address: { line1, line2, city, state, country, pincode, landmark }
}) => {
    return (
        <address>
            {line1 && <span>{line1},</span>}
            {line2 && <span> {line2},</span>}
            {city && (
                <span>
                    <br />
                    {city},
                </span>
            )}
            {state && (
                <span>
                    <span> {state},</span>
                </span>
            )}
            {country && <span> {country}</span>}
            {pincode && <span> - {pincode} </span>}
            {landmark && (
                <span>
                    <br />
                    <span className='text-muted'>Landmark</span>: {landmark}
                </span>
            )}
        </address>
    );
};

export const AgeNow = ({ dob }) => {
    return moment(dob, 'DD-MM-YYYY').fromNow(true);
};
// parentDetails

export const ParentDetailView = ({
    parentDetails: {
        fatherName,
        motherName,
        guardianName,
        mobileNo,
        emailId,
        education,
        occupation,
        income
    }
}) => {
    return (
        <div>
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <Description title='Father Name' empty=''>
                        {fatherName}
                    </Description>
                </div>
                <div className='col-12 col-md-6'>
                    <Description title='Mother Name' empty=''>
                        {motherName}
                    </Description>
                </div>
            </div>
            {guardianName && (
                <div className='row'>
                    <div className='col'>
                        <Description title='Guardian Name' empty=''>
                            {guardianName}
                        </Description>
                    </div>
                </div>
            )}
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <Description title='Contact No' empty=''>
                        {mobileNo}
                    </Description>
                </div>
                <div className='col-12 col-md-6'>
                    <Description title='Email Id' empty=''>
                        {emailId}
                    </Description>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <Description title='Education' empty='Education not set'>
                        {education}
                    </Description>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <Description title='Occupation' empty='Occupation not set'>
                        {occupation}
                    </Description>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <Description title='Income' empty='Income not set'>
                        {income}
                    </Description>
                </div>
            </div>
        </div>
    );
};
