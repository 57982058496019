import React, { Component, Fragment } from 'react';
import { Navbar, Nav, NavItem, Collapse } from 'reactstrap';
import { NavLink, Link } from 'react-router-dom';
import { Logo } from 'components/common';

import classnames from 'classnames';
class AdminSideMenu extends Component {
    // list = [
    //     {
    //         type: 'link',
    //         url: '/admin/dashboard',
    //         icon: <i className='fa fa-tachometer-alt' />,
    //         text: 'Dashboard'
    //     },
    //     {
    //         url: '',
    //         icon: <i className='fa fa-university' />,
    //         text: 'Institutions',
    //         buttons: [
    //             // {
    //             //     type: 'iconLink',
    //             //     url: '/admin/course/upload',
    //             //     icon: <i className='fa fa-plus' />,
    //             //     text: ''
    //             // }
    //         ],
    //         children: [
    //             {
    //                 type: 'link',
    //                 url: '/admin/client/list',
    //                 icon: <i className='fa fa-building' />,
    //                 text: 'All Institutions'
    //             },
    //             // {
    //             //     type: 'link',
    //             //     url: '/admin/client/list?ct=college',
    //             //     icon: <i className='fa fa-university' />,
    //             //     text: 'Colleges'
    //             // },
    //             // {
    //             //     type: 'link',
    //             //     url: '/admin/client/list?ct=school',
    //             //     icon: <i className='fa fa-school' />,
    //             //     text: 'Schools'
    //             // },
    //             {
    //                 type: 'link',
    //                 url: '/admin/course/upload',
    //                 icon: <i className='fa fa-plus-circle' />,
    //                 text: 'Add new organization'
    //             }
    //         ]
    //     },
    //     {
    //         url: '',
    //         icon: <i className='fa fa-cubes' />,
    //         text: 'e-Learning',
    //         buttons: [
    //             // {
    //             //     type: 'iconLink',
    //             //     url: '/admin/course/upload',
    //             //     icon: <i className='fa fa-plus' />,
    //             //     text: ''
    //             // }
    //         ],
    //         children: [
    //             {
    //                 type: 'link',
    //                 url: '/admin/course/list',
    //                 icon: <i className='fa fa-cubes' />,
    //                 text: 'Course Collection'
    //             },
    //             {
    //                 type: 'link',
    //                 url: '/admin/course/upload',
    //                 icon: <i className='fa fa-plus-square' />,
    //                 text: 'Add Course'
    //             },
    //             {
    //                 type: 'link',
    //                 url: '/admin/course/schedule',
    //                 icon: <i className='fa fa-clock' />,
    //                 text: 'Schedule Course'
    //             }
    //         ]
    //     },
    //     {
    //         url: '',
    //         icon: <i className='fa fa-clipboard' />,
    //         text: 'Exams / Assignments',
    //         buttons: [
    //             {
    //                 type: 'button',
    //                 url: '/admin/test/upload',
    //                 icon: <i className='fa fa-plus' />,
    //                 text: ''
    //             }
    //         ],
    //         children: [
    //             {
    //                 type: 'link',
    //                 url: '/admin/test/list',
    //                 icon: '',
    //                 text: 'Tests'
    //             },
    //             {
    //                 type: 'link',
    //                 url: '/admin/test/upload',
    //                 icon: '',
    //                 text: 'Upload Test'
    //             }
    //         ]
    //     },
    //     {
    //         url: '',
    //         icon: <i className='fa fa-users' />,
    //         text: "Students / Staff's",
    //         buttons: [
    //             {
    //                 type: 'button',
    //                 url: '/admin/user/upload',
    //                 icon: <i className='fa fa-plus' />,
    //                 text: ''
    //             }
    //         ],
    //         children: [
    //             {
    //                 type: 'link',
    //                 url: '/admin/user/list',
    //                 icon: <i className='fa fa-users' />,
    //                 text: 'Users'
    //             },
    //             {
    //                 type: 'link',
    //                 url: '/admin/user/upload',
    //                 icon: <i className='fa fa-user-plus' />,
    //                 text: 'Upload Students'
    //             },
    //             {
    //                 type: 'link',
    //                 url: '/admin/user/upload',
    //                 icon: <i className='fa fa-upload' />,
    //                 text: "Upload Staff's"
    //             }
    //         ]
    //     },
    //     {
    //         url: '',
    //         icon: <i className='fa fa-dashboard' />,
    //         text: 'Dashboard',
    //         buttons: [
    //             {
    //                 type: 'button',
    //                 url: '/admin/user/upload',
    //                 icon: <i className='fa fa-plus' />,
    //                 text: ''
    //             }
    //         ],

    //         children: [
    //             { type: 'heading', text: 'Heading' },
    //             {
    //                 type: 'link',
    //                 url: '/admin/user/upload',
    //                 icon: '',
    //                 text: 'Event 1'
    //             },
    //             {
    //                 type: 'checkbox',
    //                 value: '12',
    //                 text: 'Checkbox'
    //             }
    //         ]
    //     },
    //     {
    //         type: 'link',
    //         url: '/admin/user/upload/123',
    //         icon: <i className='fa fa-calendar' />,
    //         text: 'Event 1'
    //     },
    //     {
    //         url: '',
    //         icon: <i className='fa fa-times' />,
    //         text: 'Times',
    //         children: [
    //             { type: 'heading', text: 'Heading' },
    //             {
    //                 type: 'link',
    //                 url: '/admin/users',
    //                 icon: '',
    //                 text: 'Event 1'
    //             },
    //             {
    //                 type: 'checkbox',
    //                 value: '12',
    //                 text: 'Checkbox'
    //             }
    //         ]
    //     }
    // ];

    list = [
        {
            type: 'link',
            url: '/admin/dashboard',
            icon: <i className='icon icon-dashboard' />,
            text: 'Dashboard'
        },
        {
            url: '',
            icon: <i className='icon icon-university' />,
            text: 'Institutions',
            buttons: [
                // {
                //     type: 'iconLink',
                //     url: '/admin/course/upload',
                //     icon: <i className='fa fa-plus' />,
                //     text: ''
                // }
            ],
            children: [
                {
                    type: 'link',
                    url: '/admin/client/list',
                    icon: <i className='fa fa-building' />,
                    text: 'All Institutions'
                },
                // {
                //     type: 'link',
                //     url: '/admin/client/list?ct=college',
                //     icon: <i className='fa fa-university' />,
                //     text: 'Colleges'
                // },
                // {
                //     type: 'link',
                //     url: '/admin/client/list?ct=school',
                //     icon: <i className='fa fa-school' />,
                //     text: 'Schools'
                // },
                {
                    type: 'link',
                    url: '/admin/client/form/new',
                    icon: <i className='fa fa-plus-circle' />,
                    text: 'Add new organization'
                }
            ]
        },
        {
            url: '',
            icon: <i className='icon icon-elearning' />,
            text: 'e-Learning',
            buttons: [
                // {
                //     type: 'iconLink',
                //     url: '/admin/course/upload',
                //     icon: <i className='fa fa-plus' />,
                //     text: ''
                // }
            ],
            children: [
                {
                    type: 'link',
                    url: '/admin/course/list',
                    icon: <i className='fa fa-cubes' />,
                    text: 'Course Collection'
                },
                {
                    type: 'link',
                    url: '/admin/course/upload',
                    icon: <i className='fa fa-plus-square' />,
                    text: 'Add Course'
                },
                {
                    type: 'link',
                    url: '/admin/course/schedule',
                    icon: <i className='fa fa-clock' />,
                    text: 'Schedule Course'
                }
            ]
        },
        {
            url: '',
            icon: <i className='icon icon-test' />,
            text: 'Exams / Assignments',
            buttons: [
                {
                    type: 'button',
                    url: '/admin/test/upload',
                    icon: <i className='fa fa-plus' />,
                    text: ''
                }
            ],
            children: [
                {
                    type: 'link',
                    url: '/admin/test/list',
                    icon: '',
                    text: 'Tests'
                },
                {
                    type: 'link',
                    url: '/admin/test/upload',
                    icon: '',
                    text: 'Upload Test'
                }
            ]
        },
        {
            url: '',
            icon: <i className='icon icon-users' />,
            text: "Students / Staff's",
            buttons: [
                {
                    type: 'button',
                    url: '/admin/user/upload',
                    icon: <i className='fa fa-plus' />,
                    text: ''
                }
            ],
            children: [
                {
                    type: 'link',
                    url: '/admin/user/list',
                    icon: <i className='fa fa-users' />,
                    text: 'Users'
                },
                {
                    type: 'link',
                    url: '/admin/user/upload?utype=student',
                    icon: <i className='fa fa-user-plus' />,
                    text: 'Upload Students'
                },
                {
                    type: 'link',
                    url: '/admin/user/upload?utype=staff',
                    icon: <i className='fa fa-upload' />,
                    text: "Upload Staff's"
                }
            ]
        },
        // {
        //     url: '',
        //     icon: <i className='fa fa-dashboard' />,
        //     text: 'Dashboard',
        //     buttons: [
        //         {
        //             type: 'button',
        //             url: '/admin/user/upload',
        //             icon: <i className='fa fa-plus' />,
        //             text: ''
        //         }
        //     ],

        //     children: [
        //         { type: 'heading', text: 'Heading' },
        //         {
        //             type: 'link',
        //             url: '/admin/user/upload',
        //             icon: '',
        //             text: 'Event 1'
        //         },
        //         {
        //             type: 'checkbox',
        //             value: '12',
        //             text: 'Checkbox'
        //         }
        //     ]
        // },

        {
            url: '',
            icon: <i className='icon icon-classroom' />,
            text: 'My Classrooms',
            children: [
                { type: 'heading', text: '2009-13' },
                {
                    type: 'link',
                    url: '/admin/user/upload',
                    icon: <i className='fa fa-upload' />,
                    text: 'BE CSE: Class A'
                },
                {
                    type: 'link',
                    url: '/admin/user/upload',
                    icon: <i className='fa fa-upload' />,
                    text: 'B.Tech IT: Class B'
                },
                { type: 'heading', text: '2009-13' },
                {
                    type: 'link',
                    url: '/admin/user/upload',
                    icon: <i className='fa fa-upload' />,
                    text: 'ME CSE: Class A'
                }
            ]
        },
        {
            url: '/admin/calendar',
            icon: <i className='icon icon-calendar' />,
            text: 'My Calendar',
            type: 'url',
            id: 'ccheck',
            children: [
                { type: 'heading', text: 'Heading' },
                {
                    type: 'checkbox',
                    value: 'sdev',
                    visibility: 'private',
                    text: 'Sivaraj',
                    isChecked: true
                },
                {
                    type: 'checkbox',
                    value: '12',
                    text: 'BE CSE: Class A',
                    isChecked: true
                },
                {
                    type: 'checkbox',
                    value: '12',
                    text: 'BE CSE: Class A',
                    isChecked: true
                },
                {
                    type: 'checkbox',
                    value: '12',
                    text: 'B.Tech IT: Class B'
                },
                {
                    type: 'checkbox',
                    value: '12',
                    text: 'ME CSE: Class A'
                }
            ]
        },
        {
            url: '',
            icon: <i className='icon icon-fees' />,
            text: 'Fees Allocate / Collect',
            children: [
                {
                    type: 'link',
                    url: '/admin/fees',
                    icon: '',
                    text: 'Fees'
                },
                {
                    type: 'link',
                    url: '/admin/fees-allocation',
                    icon: '',
                    text: 'Fees Allocation'
                },
                {
                    type: 'link',
                    url: '/admin/fees-collection',
                    icon: '',
                    text: 'Fees Collection'
                }
            ]
        },
        {
            type: 'link',
            url: '/admin/settings',
            icon: <i className='icon icon-settings' />,
            text: 'Settings'
        }
    ];
    render() {
        const items = this.props.items ? this.props.items : this.list;

        return (
            <Navbar className='sidemenu ad-sidemenu p-0'>
                <div className='p-3'>
                    <Logo center />
                </div>
                <Nav navbar>
                    {items.map((item, i) => (
                        <Fragment key={i}>
                            {!item.children && (
                                <Item
                                    item={item}
                                    key={i}
                                    currentPath={this.props.currentPath}
                                    {...this.props}
                                />
                            )}
                            {item.children && (
                                <NavCollapse
                                    item={item}
                                    currentPath={this.props.currentPath}
                                    {...this.props}
                                />
                            )}
                        </Fragment>
                    ))}
                </Nav>
            </Navbar>
        );
    }
}

const Item = ({ item, itemId }) => (
    <Fragment>
        {item.type == 'heading' && (
            <NavItem>
                <h5>Batch 2009-11</h5>
            </NavItem>
        )}
        {item.type == 'link' && (
            <NavItem>
                <NavLink to={item.url} exact className='nav-link'>
                    {item.icon}
                    {item.icon == '' && <i className='fa fa-link' />}{' '}
                    {item.text}
                </NavLink>
            </NavItem>
        )}
        {item.type == 'iconLink' && (
            <NavLink to={item.url} exact>
                {item.icon}
                {item.icon == '' && <i className='fa fa-link' />} {item.text}
            </NavLink>
        )}
        {item.type == 'checkbox' && (
            <NavItem>
                <span className='custom-control custom-checkbox'>
                    <input
                        type='checkbox'
                        className='custom-control-input'
                        id={itemId}
                        defaultChecked={item.isChecked}
                    />
                    <label className='custom-control-label' htmlFor={itemId}>
                        {item.text}
                    </label>
                </span>
            </NavItem>
        )}
    </Fragment>
);

class NavCollapse extends Component {
    state = {
        collapse: false,
        forcedCollapse: false
    };
    constructor(props) {
        super(props);
        const {
            item: { children }
        } = this.props;

        for (var i = 0; i < children.length; i++) {
            if (
                children[i].url &&
                children[i].url != '' &&
                this.props.currentPath == children[i].url
            )
                this.state = { collapse: true };
        }
    }
    componentDidMount() {}

    toggle = (e, item) => {
        e.preventDefault();
        if (item.url && !this.state.collapse) this.props.history.push(item.url);
        if (!this.state.forcedCollapse)
            this.setState({ collapse: !this.state.collapse });
    };

    render() {
        const { item } = this.props;

        return (
            <Fragment>
                <NavItem
                    className={classnames(
                        'nav-header d-flex justify-content-between ',
                        {
                            active: this.state.collapse,
                            forcedCollapse: this.state.forcedCollapse
                        }
                    )}
                    item={item}
                    onClick={e => this.toggle(e, item)}>
                    <span className='headerTitle'>
                        {item.icon} {item.text}
                    </span>
                    <span className=''>
                        {/* <i className='fa fa-plus' /> */}
                        {item.buttons &&
                            item.buttons.map((button, i) => {
                                return <Item item={button} key={i} />;
                            })}
                    </span>
                </NavItem>
                <Collapse tag='li' isOpen={this.state.collapse}>
                    <ul>
                        {item.children.map((sub, i) => {
                            return (
                                <Item
                                    item={sub}
                                    key={i}
                                    itemId={`${item.id}-${i}`}
                                />
                            );
                        })}
                    </ul>
                </Collapse>
            </Fragment>
        );
    }
}
export default AdminSideMenu;
