import classnames from 'classnames';
import { Duration } from 'components/common';
import Slider from 'rc-slider';
import React, { Component, Fragment } from 'react';
import ReactPlayer from 'react-player';

export default class VideoContainer extends Component {
    state = {
        isLoading: true,
        url: null,
        pip: false,
        playing: true,
        controls: true,
        light: false,
        volume: 0.8,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false,
        isMouseInside: true,

        addNoteTime: 0,
        currentTopicId: null,
        currentModuleId: null,
        volumePopoverOpen: false
    }

    componentDidMount() {
        const { courseTrack: { endTime } } = this.props;

        if (endTime && endTime > 0)
            this.setState({ played: endTime })
    }
    ref = player => { this.player = player }

    playToggle = () => {
        const isPlaying = !this.state.playing;
        this.setState({ playing: isPlaying })
        this.props.setIsPlaying(isPlaying)
    }

    stop = () => {
        this.setState({ url: null, playing: false })
        this.props.setIsPlaying(false)

    }
    onPlay = () => {
        this.setState({ playing: true })
        this.props.setIsPlaying(true)

    }
    onPause = () => {
        this.setState({ playing: false })
        this.props.setIsPlaying(false)
    }
    onProgress = state => {
        if (!this.state.seeking)
            this.setState(state)
        if (state.playedSeconds)
            this.props.setPlayedSeconds(state.playedSeconds)
    }

    onMute = () => {
        this.setState({ muted: !this.state.muted })
    }

    onEnded = async () => {
        console.log('ended')
        this.props.onEnded()
    }

    onDuration = (duration) => {
        this.setState({ duration })
        this.props.setVideoDuration(duration)
    }


    mouseEnter = () => {
        this.setState({ isMouseInside: true });
    }
    mouseLeave = () => {
        this.setState({ isMouseInside: false });
    }

    addNote = () => {

    }


    getProgress = e => {
        let currentTargetRect = e.currentTarget.getBoundingClientRect();
        const event_offsetX = e.pageX - currentTargetRect.left;
        const event_offsetY = e.pageY - currentTargetRect.top;
        const progress = event_offsetX > 0 ? event_offsetX / currentTargetRect.width : 0

        return parseFloat(progress)
    }

    onSeekChange = e => {
        this.setState({ played: this.getProgress(e) })
    }

    onSeekMouseDown = e => {
        this.setState({ seeking: true })
    }

    onSeekMouseUp = e => {
        this.setState({ seeking: false })
        this.player.seekTo(this.getProgress(e))
    }


    volumeToggle = () => {
        this.setState(prevState => {
            return { volumePopoverOpen: !prevState.volumePopoverOpen }
        });
    }

    onVolumeChange = (volume) => {
        const currentVolume = parseFloat(volume / 100)
        this.setState({ volume: currentVolume })
        this.props.setVolume(currentVolume)
    }

    render() {
        const { isLoading, playing, volume, muted, played, loaded, duration } = this.state;
        const { courseTrack } = this.props


        return <Fragment>
            <div id="video" className="d-flex flex-column" onClick={this.playToggle} >
                <div className='player-wrapper'>
                    {courseTrack && <ReactPlayer
                        ref={this.ref}
                        url={courseTrack.params.url}
                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                        onContextMenu={e => e.preventDefault()}
                        className='react-player'
                        width='100%'
                        height='100%'
                        volume={volume}
                        muted={muted}
                        playing={playing}
                        played={played}
                        controls={false}
                        pip={false}
                        onEnded={this.onEnded}
                        onError={e => console.log('onError', e)}
                        onProgress={this.onProgress}
                        onDuration={this.onDuration}
                    />}
                </div>
            </div>
            <div className="d-flex flex-column video-footer w-100">
                <div></div>
                {/* , { 'fade-out': !isMouseInside && playing } */}
                <div className={classnames("control-bar")}>
                    <div className="progress1"
                        onMouseDown={(e) => this.onSeekMouseDown(e)}
                        onClick={(e) => this.onSeekChange(e)}
                        onMouseUp={(e) => this.onSeekMouseUp(e)} >
                        <div className="hidden-bar"></div>
                        <div id="progress-bar" className="ps-color-bg-gray-05">
                            {/* 
                                <div id="marker-container" className={classnames({ "markers-hidden": !isMouseInside, "markers-visible": isMouseInside })}>
                                    <div className="note-marker-container" style={{ "left": "calc(36% - 6px)" }}>
                                        <div className="note-marker-exponent-hidden">1</div>
                                        <button className="note-marker-button icon-note-marker"></button>
                                    </div>
                                </div>
                                <div id="tick-container" className="d-flex tick-container w-100">
                                    <div className="note-marker-tick-mark ps-color-bg-gray-05" style={{ 'left': `calc(36% - 0px)` }}>
                                    </div>
                                </div> 
                            */}
                            <div id="buffering-range" style={{ 'width': `${loaded * 100}%` }}></div>
                            <div className="bar" style={{ 'width': `${played * 100}%` }}>
                                <div className="handle"></div>
                            </div>
                        </div>
                    </div>

                    <div className="controls d-flex w-100">
                        <div className="col flex-wrap pl-sm pr-0 controls-left d-flex justify-content-start">
                            {/* <button id="previous-control" className="fa fa-prev icobutton hidden-xs-down" title="Previous (<)"></button> */}
                            <button id="play-control" className={classnames('icobutton fa', { 'fa-play': !playing, 'fa-pause': playing })} title="Play (Spacebar)" onClick={this.playToggle}></button>
                            {/* <button id="next-control" className="fa fa-next icobutton" title="Next (>)"></button> */}
                            {/* 
                                <button id="flashback-control" className="fa fa-rew icobutton" title="Back 10 seconds (←)"></button>
                                <button id="flashforward-control" className="fa fa-ff icobutton" title="Forward 10 seconds (→)"></button> 
                            */}
                            <div id="currenttime-control" className="ps-color-gray-02 ps-type-tiny align-self-center pl-xs time-clip" href="javascript:;"><Duration seconds={duration * played} />&nbsp;/&nbsp;
                            <span className="total-time"><Duration seconds={duration} /></span>
                            </div>
                        </div>
                        <div className="col-sm-1 col-md-2 col-lg-1 col-xl-2 hidden-sm-down controls-mid d-flex justify-content-center">
                            {/* 
                                <button className="add-note-button icobutton col-sm-1 col-md-12 col-lg-1 col-xl-12 hidden-sm-down" title="Add a note (N)" onClick={() => this.addNote()}>
                                    <span className="d-flex">
                                        <span className="fa fa-note-add mr-2"></span>
                                        <span className="add-note-button-label ps-type-tiny align-self-center">Add Note</span>
                                    </span>
                                </button> 
                            */}
                        </div>
                        <div className="col pr-sm pl-0 controls-right d-flex justify-content-end">
                            {/* <button className="icobutton fa fa-note-add hidden-xs-down hidden-md-up"></button> */}
                            <div className="d-flex button-with-floating-content menu-index">
                                <button id="volumeBtn" className={classnames("icobutton fa", { 'fa-vol-on': !muted, 'fa-vol-down': !muted && volume < .5, 'fa-vol-off': muted })} title="Volume (↑/↓)" onClick={this.onMute}></button>
                            </div>
                            {/* 
                                <div className="d-flex button-with-floating-content menu-index">
                                    <button id="fa fa-closed-captioning" className="icobutton fa fa-cc-off" title="Closed Captioning (C)"></button>
                                    <span></span>
                                </div> 
                            */}
                            {!muted && <div className="d-flex align-items-center" style={{ 'width': 100, 'position': 'relative' }}>
                                <Slider
                                    min={0}
                                    max={100}
                                    defaultValue={80}
                                    value={volume * 100}
                                    onChange={this.onVolumeChange}
                                    trackStyle={{ backgroundColor: '#f96816', height: 4, width: 100 }}
                                    handleStyle={{
                                        backgroundColor: '#f96816',
                                        borderColor: '#f96816'
                                    }}
                                    railStyle={{ borderColor: 'white', backgroundColor: 'white', height: 4, width: 100 }}
                                />
                            </div>}
                            <div className="d-flex1 button-with-floating-content menu-index d-none">
                                <div id="playback-rate" title="Playback Speed (-/+)" className="icobutton d-flex justify-content-center align-items-center playback-rate-control">
                                    <button className="d-flex playback-rate-control-button rounded playback-rate-control-text ps-color-border-gray-02 ps-type-tiny ps-color-gray-02">
                                        <span className="w-100 py-tiny">2.0x</span>
                                    </button>
                                </div>
                                <span></span>
                            </div>
                            <div className="d-flex1 button-with-floating-content menu-index d-none">
                                <button id="settings" className="icobutton fa fa-setting"></button>
                                <span></span>
                            </div>
                            <button id="fullscreen-control" className="requires-fullscreen fa fa-full-open icobutton hidden-xs-down" title="Full screen (F)"></button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    }
}

