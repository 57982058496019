// import UserList from 'components/admin/iuser/list';
import { Loading, getQueryParams } from 'components/common';
import { Header } from 'components/admin/common/header';
import React, { Component, Fragment } from 'react';
import { getAdminUsers } from 'services/userService';
import UserList from './list';
import Drawer from 'rc-drawer';
import UserPreview from 'components/admin/iuser/preview';

export default class UserViewPage extends Component {
    state = {
        isPageLoading: true,
        uid: null,
        uname: '',
        utype: 'user',
        uicon: ''
    };
    u;
    async componentDidMount() {
        const { uid } = this.props.match.params;
        const { uname, utype, uicon } = getQueryParams(this.props);

        this.setState({
            uid,
            uname,
            utype,
            uicon,
            isPageLoading: false
        });
    }
    render() {
        const { isPageLoading, isLoading, data } = this.state;
        const { session } = this.props;

        return (
            <div className='page page-users box'>
                <Header session={session} container />
                <div className='container pt-4'>
                    {isPageLoading && <Loading />}
                    {!isPageLoading && (
                        <Fragment>
                            <UserPreview
                                session={session}
                                uid={this.state.uid}
                                utype={this.state.utype}
                                uname={this.state.uname}
                                uicon={this.state.uicon}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}
