import classnames from 'classnames';
import { asField } from 'informed';
import React, { Component, Fragment } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { autoSuggest } from 'services/autoSuggest';
import Joi from 'joi-browser';

// import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';

const optionSchema = {
    label: Joi.string().empty('').optional(),
    value: Joi.any().optional()
}

const loadOptions = async (type, params) => {
    const { data: { items, metadata } } = await autoSuggest(type, params)
    return items
}

const formControl = {
    width: '100%',
    height: 'calc(2.25rem + 2px)',
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#ffffff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
}
// padding: 0, paddingLeft: '4px', borderColor: '#dcdcdc', margin: 0, minHeight: '30px', fontSize: '10pt'
const colourStyles = {
    container: styles => ({ ...styles, padding: 0 }),
    dropdownIndicator: styles => ({ ...styles, padding: '0 5px', margin: 0 }),
    indicatorSeparator: styles => ({ ...styles, padding: 0, margin: 0, backgroundColor: 'transparent' }),
    control: styles => ({ ...styles, ...formControl }),
    input: styles => ({ ...styles, padding: 0, margin: 0 }),
    placeholder: styles => ({ ...styles, padding: 0, margin: 0 }),
    singleValue: (styles, { data }) => ({ ...styles, padding: 0, margin: 0 }),
    valueContainer: styles => ({ ...styles, padding: 0, margin: 0 }),

};

const AsyncAutoSuggest = asField(({ fieldState, fieldApi, ...props }) => {
    const { value } = fieldState;
    const { setValue, setTouched } = fieldApi;
    const { field, onChange, onBlur, initialValue, forwardedRef, ...rest } = props;
    // console.log(value)
    return <AsyncSelect
        {...rest}
        id={field}
        ref={forwardedRef}
        styles={colourStyles}
        theme={(theme) => ({
            ...theme,
            borderRadius: 0
        })}
        value={value}
        className={classnames("async-select", { 'is-invalid': fieldState.error })}
        cacheOptions
        defaultOptions
        loadOptions={(e) => loadOptions(props.suggestType, { searchTerm: e, ...props.params })}
        onChange={e => {
            setValue(e);

            if (onChange) {
                onChange(e);
            }
        }}
        onBlur={e => {
            setTouched();
            if (onBlur) {
                onBlur(e);
            }
        }}
    />
});

export {
    AsyncAutoSuggest,
    optionSchema
}