import { asField } from 'informed';
import React, { Component, Fragment } from 'react';

import { AsyncAutoSuggest } from './auto-suggest';
import { FormControlDateRangePicker, FormControlDatePicker } from './datepicker';
import {
    FormControlCustomCheckbox,
    FormControlCustomRadioGroup,
    FormControlCustomSelect,
    FormControlInput,
    FormControlTextarea,
} from './form-control';
import classnames from 'classnames';


const displayLabel = ({ id, label, isRequired, ...props }) => label && <label htmlFor={id} className={classnames({ required: isRequired })} {...props}>{label}</label>
const displayFormFieldError = ({ fieldState, ...props }) => fieldState.error && <div className="invalid-field" {...props}>{fieldState.error}</div>
const displayFormInputGroupErrors = ({ formApi, children, ...rest }) =>
    formApi && formApi.getError && (
        <ul className="invalid-field">{deepIterate(formApi, children)}</ul>
    )

const deepIterate = (formApi, children) => {
    return React.Children.map(children, element => {
        if (element.props && element.props.children)
            return deepIterate(formApi, element.props.children);
        return typeof element.type === "function" &&
            element.props && element.props.field &&
            formApi.getError(element.props.field) &&
            <li>{formApi.getError(element.props.field)}</li>
    });
}
const FormGroup = ({ id, label, isRequired, children, formApi, ...props }) => {
    return <div className="form-group">
        {displayLabel({ id, label, isRequired })}
        {children}
        {displayFormInputGroupErrors({ formApi, children })}
    </div>
};

const FormInputGroup = ({ id, label, isRequired, children, formApi, ...props }) => {
    return <div className="form-group">
        {displayLabel({ id, label, isRequired })}
        <div className="input-group">
            {children}
        </div>
        {displayFormInputGroupErrors({ formApi, children })}
    </div>
};

const FormGroupInput = asField(({ id, label, isRequired, ...props }) => {
    return <div className="form-group">
        {displayLabel({ id, label, isRequired })}
        <FormControlInput {...props} />
        {displayFormFieldError({ fieldState: props.fieldState })}
    </div>
});

const FormGroupTextarea = asField(({ id, label, isRequired, ...props }) => {
    return <div className="form-group">
        {displayLabel({ id, label, isRequired })}
        <FormControlTextarea {...props} />
        {displayFormFieldError({ fieldState: props.fieldState })}
    </div>
});

const FormGroupCustomSelect = asField(({ id, label, isRequired, ...props }) => {
    return <div className="form-group">
        {displayLabel({ id, label, isRequired })}
        <FormControlCustomSelect {...props} />
        {displayFormFieldError({ fieldState: props.fieldState })}
    </div>
});

const FormGroupCustomRadioGroup = asField(({ id, label, isRequired, ...props }) => {
    return <div className="form-group">
        {displayLabel({ id, label, isRequired })}
        <div><FormControlCustomRadioGroup {...props} /></div>
        {displayFormFieldError({ fieldState: props.fieldState })}
    </div>
});

const FormGroupCustomCheckbox = asField(({ id, label, isRequired, ...props }) => {
    return <div className="form-group">
        {displayLabel({ id, label, isRequired })}
        <FormControlCustomCheckbox {...props} />
        {displayFormFieldError({ fieldState: props.fieldState })}
    </div>
});

const FormGroupAsyncAutoSuggest = asField(({ id, label, isRequired, ...props }) => {
    return <div className="form-group">
        {displayLabel({ id, label, isRequired })}
        <AsyncAutoSuggest {...props} />
        {displayFormFieldError({ fieldState: props.fieldState })}
    </div>
});

const FormGroupDateRangePicker = asField(({ id, label, isRequired, ...props }) => {
    return <div className="form-group">
        {displayLabel({ id, label, isRequired })}
        <FormControlDateRangePicker {...props} />
        {displayFormFieldError({ fieldState: props.fieldState })}
    </div>
});

const FormGroupDatePicker = asField(({ id, label, isRequired, ...props }) => {
    return <div className="form-group">
        {displayLabel({ id, label, isRequired })}
        <FormControlDatePicker {...props} />
        {displayFormFieldError({ fieldState: props.fieldState })}
    </div>
});

export {
    FormGroup, FormInputGroup,
    FormGroupInput, FormGroupTextarea,
    FormGroupCustomSelect,
    FormGroupCustomRadioGroup, FormGroupCustomCheckbox,
    FormGroupAsyncAutoSuggest,
    FormGroupDateRangePicker, FormGroupDatePicker,

    displayLabel,
    displayFormFieldError,
    displayFormInputGroupErrors
}

