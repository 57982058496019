import classnames from 'classnames';
import * as qs from 'query-string';
import React, { Component, Fragment } from 'react';
import { Redirect, Route } from 'react-router-dom';
import auth from 'services/authService';
import _ from 'lodash';

const getQueryParams = props => {
    return qs.parse(props.location.search);
};

const Loading = ({ color }) => {
    return (
        <div className='typing-spinner'>
            <div className={`ball ${color}`} />
            <div className={`ball ${color}`} />
            <div className={`ball ${color}`} />
        </div>
    );
};

const Logo = ({ ...props }) => {
    return (
        <div
            className={classnames({
                'd-inline': !props.center,
                'd-flex justify-content-center': props.center
            })}>
            {/* src='/assets/icons/logo.svg' */}
            <img
                src='/assets/home/logo-clr.svg'
                className={`logo ${props.className}`}
            />
        </div>
    );
};

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
    const session = auth.getCurrentUser();
    const isExpired = auth.isExpired();

    // console.log(session);
    // console.log(rest.roles);

    return (
        <Route
            {...rest}
            render={props => {
                if (!session || isExpired) {
                    let search = {};
                    if (isExpired) search.isExpired = isExpired;

                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                search: qs.stringify(search),
                                state: { from: props.location }
                            }}
                        />
                    );
                }

                if (
                    rest.roles &&
                    rest.roles.length > 0 &&
                    _.intersection(session.roles, rest.roles).length <= 0
                ) {
                    let search = { unauthorized: true };

                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                search: qs.stringify(search),
                                state: { from: props.location }
                            }}
                            exact
                        />
                    );
                }

                return Component ? (
                    <Component {...props} session={session} />
                ) : (
                    render(props)
                );
            }}
        />
    );
};

const PreviewImage = ({ src, sizes, ...props }) => {
    return (
        <Fragment>
            <div className='preview-image'>
                {props.label && <label htmlFor={props.id}>{props.label}</label>}
                {sizes &&
                    sizes.map((size, i) => (
                        <div className='item' key={i}>
                            <div
                                className={`holder ${size[0]}`}
                                style={{ backgroundImage: `url(${src})` }}
                            />
                            <small>{size[1]}</small>
                        </div>
                    ))}
                {props.error ? (
                    <small className='text text-danger'>{props.error}</small>
                ) : null}
            </div>
        </Fragment>
    );
};

const Duration = ({ className, seconds }) => {
    return (
        <time dateTime={`P${Math.round(seconds)}S`} className={className}>
            {format(seconds)}
        </time>
    );
};

function format(seconds) {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = pad(date.getUTCSeconds());
    if (hh) {
        return `${hh}:${pad(mm)}:${ss}`;
    }
    return `${mm}:${ss}`;
}

function pad(string) {
    return ('0' + string).slice(-2);
}

function getCourseLink(scheduleId, tid, mid = '') {
    return `/course/${scheduleId}/player?tid=${tid}&mid=${mid}`;
}

export {
    Logo,
    Loading,
    ProtectedRoute,
    getQueryParams,
    PreviewImage,
    Duration,
    getCourseLink
};
