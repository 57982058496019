import CourseList from 'components/admin/icourse/list';
import { Loading } from 'components/common';
import { Header } from 'components/admin/common/header';
import React, { Component, Fragment } from 'react';
import { getAdminCourses } from 'services/courseService';

export default class CoursesPage extends Component {
    state = { isPageLoading: true };

    async componentDidMount() {
        const { data } = await getAdminCourses();

        this.setState({ data, isPageLoading: false });
    }
    render() {
        const { isPageLoading, isLoading, data } = this.state;
        const { session } = this.props;

        return (
            <div className='page page-courses box'>
                <Header session={session} container />
                <div className='container pt-4'>
                    {isPageLoading && <Loading />}
                    {!isPageLoading && (
                        <Fragment>
                            {/* {data && data.length <= 0 && 'No Courses found'}
                            {data && data.length > 0 && (
                                <CourseList data={data} />
                            )} */}
                            {data && <CourseList data={data} />}
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}
