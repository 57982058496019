import http from './httpService';
import { apiUrl } from '../config.json';

export async function signup(data) {
    const result = await http.post(`${apiUrl}/signup`, data);
    return result;
}

export function getAdminUsers() {
    return http.get(`${apiUrl}/admin/user/list`);
}

export function userUpload(data, type, config) {
    return http.post(`${apiUrl}/${type}/upload`, data, config);
}

export function getUserPreview(id, type, params = {}) {
    return http.get(`${apiUrl}/user/${id}/preview`, { params });
}
