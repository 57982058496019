import React, { Component } from 'react';
import FullCalendar from '@fullcalendar/react';
import { EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import rrulePlugin from '@fullcalendar/rrule';
import _ from 'lodash';
// import './main.scss';

export default class FCalendar extends Component {
    calendarComponentRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            calendarWeekends: true,
            calendarEvents: [
                // initial event data
                {
                    title: 'Event Now',
                    start: new Date(),
                    color: '#000',
                    borderColor: '#f00'
                },

                {
                    entity: 'batch',
                    type: 'timetable',
                    name: 'Physics Class',
                    start: new Date(1557243585000),
                    end: new Date(1557625185000)
                },
                {
                    entity: 'batch',
                    type: 'exam',
                    name: 'Physics Exam',
                    start: new Date(1557880785000),
                    end: new Date(1557884385000)
                },
                {
                    entity: 'batch',
                    type: 'timetable',
                    name: 'Maths Class',
                    start: new Date(1557880785000),
                    end: new Date(1557970785000)
                },
                {
                    entity: 'department',
                    type: 'timetable',
                    name: 'Biology Class',
                    start: new Date(1556694345000),
                    end: new Date(1557471945000)
                },
                {
                    entity: 'department',
                    type: 'exam',
                    name: 'Biology Exam',
                    start: new Date(1558767945000),
                    end: new Date(1559218365000)
                },
                {
                    entity: 'department',
                    type: 'timetable',
                    name: 'Chemistry Class',
                    start: new Date(1558895400000),
                    end: new Date(1558899000000)
                },
                {
                    entity: 'client',
                    type: 'news',
                    name: 'ID Card',
                    start: new Date(1558767945000),
                    end: new Date(1558899000000)
                },
                {
                    entity: 'client',
                    type: 'assignment',
                    name: 'Maths Assignment',
                    start: new Date(1557243585000),
                    end: new Date(1557970785000)
                },
                {
                    entity: 'client',
                    type: 'exam',
                    name: 'Maths Exam',
                    start: new Date(1557880785000),
                    end: new Date(1559218365000)
                },

                {
                    // standard property
                    title: 'my recurring event',

                    rrule:
                        'DTSTART:20120201T103000Z\nRRULE:FREQ=WEEKLY;INTERVAL=5;UNTIL=20190520;BYDAY=MO,FR',
                    // ...or, an object...
                    rrule: {
                        freq: 'weekly',
                        interval: 5,
                        byweekday: ['mo', 'fr'],
                        dtstart: '2019-05-01T10:30:00',
                        until: '2019-06-30'
                    },

                    // for specifying the end time of each instance
                    duration: '02:00'
                }
            ]
        };
    }

    render() {
        return (
            <div className='page demo-app'>
                <div className='demo-app-top'>
                    <button
                        className='btn btn-sm btn-primary'
                        onClick={this.toggleWeekends}>
                        Weekends
                    </button>
                    &nbsp;
                    <button
                        className='btn btn-sm btn-secondary'
                        onClick={this.gotoPast}>
                        Go to a Date ( past )
                    </button>
                    &nbsp;{' '}
                    <span className='spantag'>
                        {' '}
                        (also, click a date/time to add an event){' '}
                    </span>
                </div>
                <div
                    className='demo-app-calendar'
                    style={{ minHeight: '90vh' }}>
                    <FullCalendar
                        defaultView='dayGridMonth'
                        height='parent'
                        header={{
                            // myCustomButton
                            left: 'today prev next title',
                            center: '',
                            // center: 'title',
                            right:
                                'timeGridDay,timeGridWeek,dayGridMonth,listMonth'
                            // 'dayGridMonth,timeGridWeek,timeGridDay,listDay,listWeek,listMonth, resourceTimeGridDay,resourceTimeGridFourDay'
                        }}
                        // customButtons={{
                        //     myCustomButton: {
                        //         text: 'custom!',
                        //         click: function () {
                        //             alert('clicked the custom button!');
                        //         }
                        //     }
                        // }}
                        views={{
                            listDay: { buttonText: 'list day' },
                            listWeek: { buttonText: 'list week' },
                            listMonth: { buttonText: 'list month' },
                            resourceTimeGridFourDay: {
                                type: 'resourceTimeGrid',
                                duration: { days: 4 },
                                buttonText: '4 days'
                            }
                        }}
                        resources={[
                            { id: 'a', title: 'Room A' },
                            { id: 'b', title: 'Room B' }
                        ]}
                        buttonText={{
                            today: 'Today',
                            month: 'month',
                            week: 'week',
                            day: 'day',
                            list: 'list'
                        }}
                        // buttonIcons={{
                        //     close: 'fa fa-times',
                        //     today: 'fa fa-times',
                        //     prev: 'fa fa-chevron-left',
                        //     next: 'fa fa-chevron-right',
                        //     prevYear: 'fa fa-angle-double-left',
                        //     nextYear: 'fa fa-angle-double-right'
                        // }}
                        plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                            listPlugin,
                            bootstrapPlugin,
                            rrulePlugin
                        ]}
                        selectable={true}
                        editable={true}
                        themeSystem='bootstrap'
                        ref={this.calendarComponentRef}
                        weekends={this.state.calendarWeekends}
                        events={this.state.calendarEvents}
                        dateClick={this.handleDateClick}
                        nowIndicator={true}
                        weekNumbersWithinDays={true}
                        navLinks={true}
                        weekNumbers={false}
                        displayEventTime={true}
                        eventTimeFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            meridiem: 'short'
                        }}
                        eventLimit={true}
                        dayPopoverFormat={{
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                        }}
                        // businessHours={[
                        //     {
                        //         daysOfWeek: [1, 2, 3], // Monday, Tuesday, Wednesday
                        //         startTime: '08:00', // 8am
                        //         endTime: '18:00' // 6pm
                        //     },
                        //     {
                        //         daysOfWeek: [4, 5], // Thursday, Friday
                        //         startTime: '10:00', // 10am
                        //         endTime: '16:00' // 4pm
                        //     }
                        // ]}
                    />
                </div>
            </div>
        );
    }

    toggleWeekends = () => {
        this.setState({
            // update a property
            calendarWeekends: !this.state.calendarWeekends
        });
    };

    gotoPast = () => {
        if (this.calendarComponentRef) {
            let calendarApi = this.calendarComponentRef.current.getApi();
            calendarApi.gotoDate('2000-01-01'); // call a method on the Calendar object
        }
    };

    handleDateClick = arg => {
        this.setState({
            // add new event data
            calendarEvents: this.state.calendarEvents.concat({
                // creates a new array
                title: 'New Event',
                start: arg.date,
                allDay: arg.allDay,
                color: colorCodes()
            })
        });
    };
}

const colorCodes = () => {
    const c = {
        aliceblue: '#f0f8ff',
        antiquewhite: '#faebd7',
        aqua: '#00ffff',
        aquamarine: '#7fffd4',
        azure: '#f0ffff',
        beige: '#f5f5dc',
        bisque: '#ffe4c4',
        black: '#000000',
        blanchedalmond: '#ffebcd',
        blue: '#0000ff',
        blueviolet: '#8a2be2',
        brown: '#a52a2a',
        burlywood: '#deb887',
        cadetblue: '#5f9ea0',
        chartreuse: '#7fff00',
        chocolate: '#d2691e',
        coral: '#ff7f50',
        cornflowerblue: '#6495ed',
        cornsilk: '#fff8dc',
        crimson: '#dc143c',
        cyan: '#00ffff',
        darkblue: '#00008b',
        darkcyan: '#008b8b',
        darkgoldenrod: '#b8860b',
        darkgray: '#a9a9a9',
        darkgreen: '#006400',
        darkgrey: '#a9a9a9',
        darkkhaki: '#bdb76b',
        darkmagenta: '#8b008b',
        darkolivegreen: '#556b2f',
        darkorange: '#ff8c00',
        darkorchid: '#9932cc',
        darkred: '#8b0000',
        darksalmon: '#e9967a',
        darkseagreen: '#8fbc8f',
        darkslateblue: '#483d8b',
        darkslategray: '#2f4f4f',
        darkslategrey: '#2f4f4f',
        darkturquoise: '#00ced1',
        darkviolet: '#9400d3',
        deeppink: '#ff1493',
        deepskyblue: '#00bfff',
        dimgray: '#696969',
        dimgrey: '#696969',
        dodgerblue: '#1e90ff',
        firebrick: '#b22222',
        floralwhite: '#fffaf0',
        forestgreen: '#228b22',
        fuchsia: '#ff00ff',
        gainsboro: '#dcdcdc',
        ghostwhite: '#f8f8ff',
        gold: '#ffd700',
        goldenrod: '#daa520',
        gray: '#808080',
        green: '#008000',
        greenyellow: '#adff2f',
        grey: '#808080',
        honeydew: '#f0fff0',
        hotpink: '#ff69b4',
        indianred: '#cd5c5c',
        indigo: '#4b0082',
        ivory: '#fffff0',
        khaki: '#f0e68c',
        lavender: '#e6e6fa',
        lavenderblush: '#fff0f5',
        lawngreen: '#7cfc00',
        lemonchiffon: '#fffacd',
        lightblue: '#add8e6',
        lightcoral: '#f08080',
        lightcyan: '#e0ffff',
        lightgoldenrodyellow: '#fafad2',
        lightgray: '#d3d3d3',
        lightgreen: '#90ee90',
        lightgrey: '#d3d3d3',
        lightpink: '#ffb6c1',
        lightsalmon: '#ffa07a',
        lightseagreen: '#20b2aa',
        lightskyblue: '#87cefa',
        lightslategray: '#778899',
        lightslategrey: '#778899',
        lightsteelblue: '#b0c4de',
        lightyellow: '#ffffe0',
        lime: '#00ff00',
        limegreen: '#32cd32',
        linen: '#faf0e6',
        magenta: '#ff00ff',
        maroon: '#800000',
        mediumaquamarine: '#66cdaa',
        mediumblue: '#0000cd',
        mediumorchid: '#ba55d3',
        mediumpurple: '#9370db',
        mediumseagreen: '#3cb371',
        mediumslateblue: '#7b68ee',
        mediumspringgreen: '#00fa9a',
        mediumturquoise: '#48d1cc',
        mediumvioletred: '#c71585',
        midnightblue: '#191970',
        mintcream: '#f5fffa',
        mistyrose: '#ffe4e1',
        moccasin: '#ffe4b5',
        navajowhite: '#ffdead',
        navy: '#000080',
        oldlace: '#fdf5e6',
        olive: '#808000',
        olivedrab: '#6b8e23',
        orange: '#ffa500',
        orangered: '#ff4500',
        orchid: '#da70d6',
        palegoldenrod: '#eee8aa',
        palegreen: '#98fb98',
        paleturquoise: '#afeeee',
        palevioletred: '#db7093',
        papayawhip: '#ffefd5',
        peachpuff: '#ffdab9',
        peru: '#cd853f',
        pink: '#ffc0cb',
        plum: '#dda0dd',
        powderblue: '#b0e0e6',
        purple: '#800080',
        red: '#ff0000',
        rosybrown: '#bc8f8f',
        royalblue: '#4169e1',
        saddlebrown: '#8b4513',
        salmon: '#fa8072',
        sandybrown: '#f4a460',
        seagreen: '#2e8b57',
        seashell: '#fff5ee',
        sienna: '#a0522d',
        silver: '#c0c0c0',
        skyblue: '#87ceeb',
        slateblue: '#6a5acd',
        slategray: '#708090',
        slategrey: '#708090',
        snow: '#fffafa',
        springgreen: '#00ff7f',
        steelblue: '#4682b4',
        tan: '#d2b48c',
        teal: '#008080',
        thistle: '#d8bfd8',
        tomato: '#ff6347',
        turquoise: '#40e0d0',
        violet: '#ee82ee',
        wheat: '#f5deb3',
        white: '#ffffff',
        whitesmoke: '#f5f5f5',
        yellow: '#ffff00',
        yellowgreen: '#9acd32'
    };
    const keys = _.keys(c);
    const code = keys[Math.floor(Math.random() * keys.length)];
    return c[code] ? c[code] : c['tomato'];
};
