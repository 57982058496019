import { ProtectedRoute } from 'components/common';

import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch
} from 'react-router-dom';
import auth from 'services/authService';

import { LoginPage, RegisterPage } from 'components/core';
import Page404 from 'components/core/error';

import ProfilePage from 'components/user';
import Courses from 'components/icourse';
import CourseViewPage from 'components/icourse/view';
import CoursePlayer from 'components/icourse/player';
import Questions from 'components/icourse/questions';

import Admin from 'components/admin';
import HomePage from 'components/home';

class App extends Component {
    state = {};

    componentDidMount() {
        const user = auth.getCurrentUser();
        this.setState({ session: user });
    }

    render() {
        const { session } = this.state;

        return (
            <Router>
                <div>
                    <Switch>
                        {/* HOME PAGE */}
                        <Route
                            path='/'
                            component={HomePage}
                            session={session}
                            exact
                        />
                        {/* <Redirect from='/' to='/login' exact /> */}

                        {/* CORE PAGES */}
                        <Route
                            path='/login'
                            component={LoginPage}
                            session={session}
                        />
                        {/* <Route
                            path='/register'
                            component={RegisterPage}
                            session={session}
                        /> */}
                        {/* <Route path="/forgot-password" component={ForgotPage} /> */}

                        {/**
                         * Student Pages */}
                        {/* <ProtectedRoute path="/list" component={CourseList} /> */}
                        <ProtectedRoute
                            path='/courses'
                            component={Courses}
                            exact
                        />
                        <ProtectedRoute
                            path='/course/:scheduleId/details'
                            component={CourseViewPage}
                        />
                        <ProtectedRoute
                            path='/course/:scheduleId/player'
                            component={CoursePlayer}
                        />
                        <ProtectedRoute
                            path='/course/:scheduleId/questions'
                            component={Questions}
                        />
                        <ProtectedRoute
                            path='/profile'
                            component={ProfilePage}
                        />

                        <ProtectedRoute path='/admin' component={Admin} />
                        {/* <ProtectedRoute path="/users" exact component={Users} />
            <ProtectedRoute path="/profile/:step" exact component={UserForm} /> */}
                        <Route component={Page404} />
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default App;
