import http from './httpService';
import { apiUrl } from '../config.json';

const apiEndpoint = apiUrl + '/suggest';

function suggestUrl(type) {
    return `${apiEndpoint}/${type}`;
}

export function autoSuggest(type, params) {
    // const params = { searchTerm, pageSize, pageNum };
    return http.get(`${suggestUrl(type)}`, { params });
}

export function loadBranchList() {
    return http.get(`${apiUrl}/load/branch/list`);
}

export function loadDepartmentListByEntity(entityId) {
    return http.get(`${apiUrl}/load/department/list`, { entityId: entityId });
}
