import { FormGroupInput, SubmitButton } from 'components/common/forms';
import { getQueryParams, Loading, Logo } from 'components/common';
import { Header } from 'components/admin/common/header';
import { BoxWrapper } from 'components/core/box';
import { Form } from 'informed';
import Joi from 'joi-browser';
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { testUpload } from 'services/testService';
import Dropzone from 'react-dropzone';
import Previews from './images';

export default class TestUploadPage extends Component {
    state = { isPageLoading: false };
    render() {
        const { isPageLoading } = this.state;
        const { session } = this.props;
        const params = getQueryParams(this.props);

        return (
            <div className='page page-testUpload'>
                <Header session={session} container />
                <div className='container pt-4'>
                    {isPageLoading && <Loading />}
                    {!isPageLoading && (
                        <div className='row'>
                            <div className='col-12'>
                                {/* <TestImageUploadForm {...this.props} /> */}
                                {/* <Previews /> */}
                            </div>
                            <div className='col-sm-12 col-md-6'>
                                <TestUploadForm
                                    {...this.props}
                                    params={params}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const style = {
    borderWidth: 2,
    borderColor: 'black',
    borderStyle: 'dashed',
    borderRadius: 4,
    margin: 30,
    padding: 30,
    width: 200,
    transition: 'all 0.5s'
};

const activeStyle = {
    borderStyle: 'solid',
    borderColor: '#4FC47F'
};

const rejectStyle = {
    borderStyle: 'solid',
    borderColor: '#DD3A0A'
};

class TestImageUploadForm extends Component {
    state = {
        data: {},
        errors: {},
        testId: 'ikdu',
        images: [
            {
                _id: 'question_yptjlr',
                absoluteUrl:
                    'https://d1c64qna5hpf1i.cloudfront.net/questions/question_yptjlr_1.jpg',
                id: 'yptjlr',
                imagePath: '1.jpg',
                type: 'question'
            },
            {
                _id: 'question_yptjlr',
                absoluteUrl:
                    'https://d1c64qna5hpf1i.cloudfront.net/questions/question_yptjlr_1.jpg',
                id: 'yptjlr',
                imagePath: '2.jpg',
                type: 'question'
            },
            {
                _id: 'question_yptjlr',
                absoluteUrl:
                    'https://d1c64qna5hpf1i.cloudfront.net/questions/question_yptjlr_1.jpg',
                id: 'yptjlr',
                imagePath: '3.jpg',
                type: 'question'
            }
        ],
        filesChoosen: {},
        files: [],
        isLoading: false
    };

    // onDrop(files) {
    //     console.log('Received files: ', files);
    //     this.setState({
    //         files: files
    //     });
    // }

    onDrop = acceptedFiles => {
        // const reader = new FileReader()

        // reader.onabort = () => console.log('file reading was aborted')
        // reader.onerror = () => console.log('file reading has failed')
        // reader.onload = () => {
        //     // Do whatever you want with the file contents
        //     const binaryStr = reader.result
        //     console.log(binaryStr)
        // }
        // acceptedFiles.forEach(file => reader.readAsBinaryString(file))
        console.log('Received files: ', acceptedFiles);
        this.setState({
            files: acceptedFiles
        });

        let filesChoosen = {};
        acceptedFiles.forEach(file => {
            console.log(file.name);
            filesChoosen[file.name] = file;
        });

        this.setState(prevState => {
            return {
                filesChoosen: { ...prevState.filesChoosen, ...filesChoosen }
            };
        });
    };

    onSubmit = async () => {
        // const data = this.formApi.getState().values;

        try {
            this.setState({ isLoading: true, errors: {} });

            // if (!this.file) {
            //     const errors = { ...this.state.errors, 'message': "Please choose the file", 'class': 'danger' };
            //     this.setState({ errors });
            // }

            // const formData = new FormData();
            // formData.append('file', this.file)

            // const config = { headers: { 'content-type': 'multipart/form-data' } }
            // // const result = await testUpload(formData, config);
            // console.log(result)

            // this.setState({ isLoading: false })
        } catch (ex) {
            this.setState({ isLoading: false });
            if (ex.response && ex.response.data) {
                const errors = { ...this.state.errors, ...ex.response.data };
                this.setState({ errors });
            } else {
                const errors = {
                    ...this.state.errors,
                    message: 'Sorry some error occurred. Please try again',
                    class: 'danger'
                };
                this.setState({ errors });
            }
        }
    };

    showFiles() {
        const { files } = this.state;

        if (!files.length) {
            return null;
        }

        return (
            <div>
                <h3>Dropped files: </h3>
                <ul>
                    {files.map((file, idx) => {
                        return (
                            <li key={idx}>
                                <img
                                    src={file.preview}
                                    width={100}
                                    height={100}
                                />
                                <div>
                                    {file.name + ' : ' + file.size + ' bytes.'}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    render() {
        const { isLoading, errors, images, filesChoosen } = this.state;
        console.log(filesChoosen);
        return (
            <div className='bg-white'>
                <Dropzone
                    onDrop={this.onDrop}
                    accept='image/*'
                    style={style}
                    activeStyle={activeStyle}
                    rejectStyle={rejectStyle}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>
                                    Drag 'n' drop some files here, or click to
                                    select files
                                </p>
                            </div>
                        </section>
                    )}
                </Dropzone>
                {this.showFiles()}
                <table className='table table-bordered table-sm'>
                    <tbody>
                        {images &&
                            images.map((image, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{image['id']}</td>
                                        <td>{image['type']}</td>
                                        <td>{image['imagePath']}</td>
                                        <td>
                                            {filesChoosen &&
                                                filesChoosen[
                                                    image['imagePath']
                                                ] && (
                                                    <div>
                                                        <img
                                                            src={
                                                                filesChoosen[
                                                                    image[
                                                                        'imagePath'
                                                                    ]
                                                                ].preview
                                                            }
                                                            width={100}
                                                            height={100}
                                                        />
                                                        {
                                                            filesChoosen[
                                                                image[
                                                                    'imagePath'
                                                                ]
                                                            ].name
                                                        }
                                                        {
                                                            filesChoosen[
                                                                image[
                                                                    'imagePath'
                                                                ]
                                                            ].size
                                                        }
                                                    </div>
                                                )}
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        );
    }
}

class TestUploadForm extends Component {
    state = {
        data: {},
        errors: {},
        isLoading: false,
        ta: []
    };

    readFile = e => {
        e.stopPropagation();
        e.preventDefault();

        const files = e.target.files;
        const f = files[0];
        this.file = f;
    };

    onSubmit = async () => {
        // const data = this.formApi.getState().values;

        try {
            this.setState({ isLoading: true, errors: {} });

            if (!this.file) {
                const errors = {
                    ...this.state.errors,
                    message: 'Please choose the file',
                    class: 'danger'
                };
                this.setState({ errors });
            }

            const formData = new FormData();
            formData.append('file', this.file);

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            };
            const { data } = await testUpload(formData, config);
            // console.log(result)
            if (data && data.ta && data.ta.length > 0)
                this.setState({ isLoading: false, ta: data.ta });
            this.setState({ isLoading: false });
        } catch (ex) {
            this.setState({ isLoading: false });
            if (ex.response && ex.response.data) {
                const errors = { ...this.state.errors, ...ex.response.data };
                this.setState({ errors });
            } else {
                const errors = {
                    ...this.state.errors,
                    message: 'Sorry some error occurred. Please try again',
                    class: 'danger'
                };
                this.setState({ errors });
            }
        }
    };

    render() {
        const { isLoading, errors, ta } = this.state;
        const queryParams = getQueryParams(this.props);

        return (
            <Form
                className='bg-white p-3'
                getApi={this.setFormApi}
                onSubmit={this.onSubmit}>
                {({ formApi, formState }) => (
                    <Fragment>
                        <h1>Test Upload</h1>
                        <div className='fileBlock my-3'>
                            <input
                                id='upload'
                                ref='upload'
                                type='file'
                                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                onChange={event => {
                                    this.readFile(event);
                                }}
                                onClick={event => {
                                    // event.target.value = null
                                }}
                            />
                        </div>
                        {
                            <SubmitButton
                                extraclass=''
                                isLoading={isLoading}
                                value='Test Upload'
                            />
                        }
                        {errors && errors.message && (
                            <Alert color={errors.class} className='mt-3'>
                                {errors.message}
                            </Alert>
                        )}
                        <div>
                            {ta &&
                                ta.length > 0 &&
                                ta.map((t, idx) => {
                                    return (
                                        <NavLink
                                            key={idx}
                                            to={`/admin/test/preview/${
                                                t['_id']
                                            }`}>
                                            {t['testName']}-{t['_id']}
                                        </NavLink>
                                    );
                                })}
                        </div>
                    </Fragment>
                )}
            </Form>
        );
    }
}
