import React, { Fragment } from 'react';
import classnames from 'classnames';

export const BoxWrapper = ({ info, form }) => (
    <Fragment>
        <div className="row align-item-center justify-content-center box-wrapper">
            {info && <div className={classnames("col-sm-12 col-md-3 box-info order-1 order-md-0", { "br": !form })}>
                {info}
            </div>}
            {form && <div className={classnames("col-sm-12 col-md-6 box-form order-0 order-md-1", { "br": !info })}>
                {form}
            </div>}
        </div>
    </Fragment>
)
