import classnames from 'classnames';
import { asField, Checkbox, Option, Radio, RadioGroup, Select } from 'informed';
import React, { Component, Fragment } from 'react';

const FormControlInput = asField(({ fieldState, fieldApi, ...props }) => {
    const { value } = fieldState;

    const { setValue, setTouched } = fieldApi;
    const { field, onChange, onBlur, initialValue, forwardedRef, ...rest } = props;
    return <input
        {...rest}
        id={field}
        ref={forwardedRef}
        value={!value && value !== 0 ? '' : value}
        className={classnames("form-control", { 'is-invalid': fieldState.error })}
        onChange={e => {
            setValue(e.target.value);
            if (onChange) {
                onChange(e);
            }
        }}
        onBlur={e => {
            setTouched();
            if (onBlur) {
                onBlur(e);
            }
        }}
    />
});

const FormControlTextarea = asField(({ fieldState, fieldApi, ...props }) => {
    const { value } = fieldState;

    const { setValue, setTouched } = fieldApi;
    const { field, onChange, onBlur, initialValue, forwardedRef, ...rest } = props;
    return <textarea
        {...rest}
        id={field}
        ref={forwardedRef}
        value={!value && value !== 0 ? '' : value}
        className={classnames("form-control", { "is-invalid": fieldState.error })}
        onChange={e => {
            setValue(e.target.value);
            if (onChange) {
                onChange(e);
            }
        }}
        onBlur={e => {
            setTouched();
            if (onBlur) {
                onBlur(e);
            }
        }}
    />
});

const FormControlCustomSelect = asField(({ fieldState, fieldApi, ...props }) => {
    const { value } = fieldState;

    const { setValue, setTouched } = fieldApi;
    const { field, onChange, onBlur, initialValue, forwardedRef,
        excludeEmptyOption, emptyValue, emptyLabel, getOptionValue, getOptionLabel,
        options, ...rest } = props;
    return <Select
        {...rest}
        id={field}
        field={field}
        value={value}
        className={classnames("custom-select", { "is-invalid": fieldState.error })}
        onChange={e => {
            console.log(e)
            if (onChange) {
                onChange(e, value);
            }
        }}
        onBlur={e => {
            setTouched();
            if (onBlur) {
                onBlur(e);
            }
        }} >
        {!excludeEmptyOption &&
            <Option value={emptyValue ? emptyValue : ""} className="custom-control-input">{emptyLabel ? emptyLabel : 'Select the option'}</Option>
        }
        {options && options.map((option, i) =>
            <Option value={getOptionValue ? getOptionValue(option) : (option.value ? option.value : option)} id={`${field}-${i}`} className="custom-control-input" key={i}>{getOptionLabel ? getOptionLabel(option) : (option.label ? option.label : option)}</Option>
        )}
    </Select>
});

const FormControlCustomRadioGroup = asField(({ fieldState, fieldApi, ...props }) => {
    const { value } = fieldState;

    const { setValue, setTouched } = fieldApi;
    const { field, onChange, onBlur, initialValue, forwardedRef,
        getOptionValue, getOptionLabel, options, radioInline, ...rest } = props;
    return <RadioGroup
        {...rest}
        id={field}
        field={field}
        value={value}
        onChange={e => {
            if (onChange) {
                onChange(e, value);
            }
        }}
        onBlur={e => {
            setTouched();
            if (onBlur) {
                onBlur(e);
            }
        }}
    >
        {options && options.map((option, i) =>
            <div className={classnames('custom-control custom-radio', { 'custom-control-inline': radioInline })} key={i}>
                <Radio
                    value={getOptionValue ? getOptionValue : (option.value ? option.value : option)}
                    id={`${field}-${i}`}
                    className={classnames("custom-control-input", { "is-invalid": fieldState.error })}

                />
                <label className="custom-control-label" htmlFor={`${field}-${i}`}>{getOptionLabel ? getOptionLabel : (option.label ? option.label : option)}</label>
            </div>
        )}
    </RadioGroup>
});

const FormControlCustomCheckbox = asField(({ fieldState, fieldApi, ...props }) => {
    const { value } = fieldState;

    const { setValue, setTouched } = fieldApi;
    const { field, onChange, onBlur, initialValue, forwardedRef,
        checkboxLabel, options, getOptionValue, getOptionLabel, radioInline, id, ...rest } = props;
    return <div className="custom-control custom-checkbox">
        <Checkbox
            {...rest}
            id={id ? id : field}
            field={field}
            ref={forwardedRef}
            value={value ? value : false}
            className={classnames("custom-control-input", { "is-invalid": fieldState.error })}
            onChange={e => {
                if (onChange) {
                    onChange(e);
                }
            }}
            onBlur={e => {
                setTouched();
                if (onBlur) {
                    onBlur(e);
                }
            }}
        />
        <label className="custom-control-label" htmlFor={id ? id : field}>{checkboxLabel ? checkboxLabel : ''}</label>
    </div>
});

export {
    FormControlInput,
    FormControlTextarea,
    FormControlCustomSelect,
    FormControlCustomRadioGroup,
    FormControlCustomCheckbox
}