import classnames from 'classnames';
import { Logo } from 'components/common';
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import {
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarToggler,
    NavItem,
    UncontrolledDropdown,
    NavbarBrand
} from 'reactstrap';
import auth from 'services/authService';
import { getLetters } from 'services/static';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };

        this.toggle = this.toggle.bind(this);
        this.togglesidebar = this.togglesidebar.bind(this);
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    togglesidebar() {}

    render() {
        const { session, container } = this.props;
        return (
            <Navbar
                color='light'
                light
                expand='md'
                className='nav-eco bg-white'>
                <div
                    className={classnames({
                        container: container,
                        'container-fluid': !container
                    })}>
                    <NavbarBrand to='/index' className='d-flex flex-row'>
                        <Logo />

                        {/* <span className='d-flex flex-column logo-text ml-2'>
                            <span className='font-weight-bold'>
                                Dream big School
                            </span>
                            <span className='small'>Bangalore</span>
                        </span> */}
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className='ml-auto' navbar>
                            {!(session && session.uid) && (
                                <Fragment>
                                    {/* <NavItem>
                                        <NavLink to='/register'>
                                            Register
                                        </NavLink>
                                    </NavItem> */}
                                    <NavItem>
                                        <NavLink
                                            to='/login'
                                            className='btn btn-primary btn-sm'>
                                            Login
                                        </NavLink>
                                    </NavItem>
                                </Fragment>
                            )}

                            {/* isImage={session.pictureUrl && session.pictureUrl != '' ? true : false} */}
                            {session && session.uid && (
                                <Fragment>
                                    {/* <NavItem>
                                        <NavLink
                                            to='/courses'
                                            className='nav-link'>
                                            Courses
                                        </NavLink>
                                    </NavItem> */}

                                    <Fragment>
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav>
                                                Admin
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem>
                                                    <NavLink
                                                        to='/admin/user/list'
                                                        className='nav-link'>
                                                        Users
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink
                                                        to='/admin/test/list'
                                                        className='nav-link'>
                                                        Tests
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink
                                                        to='/admin/course/list'
                                                        className='nav-link'>
                                                        Courses
                                                    </NavLink>
                                                </DropdownItem>
                                                {/* <DropdownItem>
                                                    <NavLink
                                                        to='/course/upload'
                                                        className='nav-link'>
                                                        Course Upload
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <a
                                                        href='http://localhost:5000/data/courses'
                                                        target='_blank'
                                                        className='nav-link'>
                                                        Courses
                                                    </a>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <a
                                                        href='http://localhost:5000/course/upload'
                                                        target='_blank'
                                                        className='nav-link'>
                                                        Course Upload
                                                    </a>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <a
                                                        href='http://localhost:5000/test/upload'
                                                        target='_blank'
                                                        className='nav-link'>
                                                        Test Upload
                                                    </a>
                                                </DropdownItem> */}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </Fragment>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle
                                            nav
                                            className='username'>
                                            <div
                                                className='profilePic'
                                                data-letters={getLetters(
                                                    session.displayName
                                                )}>
                                                <div
                                                    className='avatar'
                                                    style={{
                                                        backgroundImage: `url(${
                                                            session.pictureUrl
                                                        })`
                                                    }}
                                                />
                                            </div>
                                            &nbsp; {session.displayName}
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem>Profile</DropdownItem>
                                            {/* <DropdownItem divider /> */}
                                            <NavLink to='/'>
                                                <DropdownItem
                                                    onClick={e => {
                                                        auth.logout();
                                                    }}>
                                                    Logout
                                                </DropdownItem>
                                            </NavLink>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Fragment>
                            )}
                        </Nav>
                    </Collapse>
                </div>
            </Navbar>
        );
    }
}
