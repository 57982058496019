import { Duration } from 'components/common';
import { minsToHHMM } from 'components/libs';
import React, { Component, Fragment } from 'react';
import { Button, FormGroup, Input } from 'reactstrap';
import { addNote, getNotes } from 'services/courseService';
// import { Editor, EditorState, convertFromRaw, convertToRaw, RichUtils } from 'draft-js';

import Editor from 'draft-js-plugins-editor';
import createHashtagPlugin from 'draft-js-hashtag-plugin';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import { EditorState, convertToRaw } from 'draft-js';

const hashtagPlugin = createHashtagPlugin();
const linkifyPlugin = createLinkifyPlugin();

const plugins = [
    hashtagPlugin,
    linkifyPlugin,
];


export default class NotesBlock extends Component {
    state = {
        notes: [],
        isPageLoading: true,
        isLoading: true,
        noteId: 'new',
        noteText: 'ssample'
    }

    async componentDidMount() {
        const { scheduleId } = this.props;

        const { data: notes } = await getNotes(scheduleId)

        await this.setState({ notes, isLoading: false });
    }

    updatedNotes = (note) => {
        const notes = this.state.notes;
        notes.push(note)
        this.setState({ notes })
    }


    render() {
        const { scheduleId, addNoteTime, currentTopicId } = this.props
        const { noteId, noteText, notes } = this.state;

        return <div>
            {/* <div className="d-flex py-md ps-color-border-bottom-gray-06 notes-links"><a className="download-notes d-flex flex-grow-1 align-items-center ps-color-white ps-type-xs ps-link-reset menu-text pl-md" href="/player/api/course/download?courseSlug=react-fundamentals">
                <div className="icon-download ps-icon-button notes-link-icon pr-lg"></div>
                <div><span className="cursor-pointer"><i className="fa fa-download mr-3"></i>Download</span></div>
            </a>
                <div className="ps-divide-pipe ps-color-bg-gray-05"></div>
                <a className="view-all-notes d-flex flex-grow-1 align-items-center ps-color-white ps-type-xs ps-link-reset menu-text pl-md" href="/notes" target="ps-notes">
                    <div> <span className="cursor-pointer">View all<i className="fa fa-angle-right ml-3"></i></span></div>
                </a>
            </div> */}
            <div className="over-y-scrl">

                {notes && notes.length > 0 &&
                    <div className="notes-list flex-grow-1" id="noteslist">
                        {notes.map((note, idx) => <div className="note-item standard-note-item" key={idx}>
                            <div className="px-md py-sm">
                                <div className="note-item-text ps-color-white ps-type-sm">
                                    <p>{note.text}</p>
                                </div>
                                <div className="note-item-footer d-flex align-items-center ps-color-gray-02 ps-type-xs">
                                    <div className="icon-play-time">
                                        <i className="fa fa-play-circle pr-2"></i>
                                    </div>
                                    <div className="note-item-link ps-type-ellipsis pl-tiny pr-sm flex-grow-1">
                                        {note.topicId}</div>
                                    <div className="note-item-timestamp">{minsToHHMM(note.time)}</div>
                                    <div className="note-item-edit-label d-flex ps-color-orange">
                                        <div className="note-item-delete-link d-flex align-items-center px-md">
                                            <div className="icon-x">
                                                <i className="fa fa-times px-1"></i>
                                            </div><a className="delete-note-link pl-tiny">delete</a>
                                        </div>
                                        <div className="note-item-edit-link d-flex align-items-center">
                                            <div className="icon-pen">
                                                <i className="fa fa-edit px-1"></i>
                                            </div><a className="edit-note-link pl-tiny">edit</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                }
                {notes && notes.length == 0 && <div className="my-cutsom">
                    {/* <h6 className="contents-heading">
                    <span className="cursor-pointer"><i className="fa fa-download mr-3"></i>Download</span>
                    <span className="cursor-pointer">View all<i className="fa fa-angle-right ml-3"></i></span>
                </h6> */}
                    <div className="d-flex justify-content-center align-items-center">

                        <div className="d-flex flex-column justify-content-center align-items-center text-center">
                            <div className="icon-large-note"><img src="/assets/courses/notes.svg" /></div>
                            <div className="pt-sm ps-type-md ps-type-weight-light ps-color-gray-02">Take notes while you learn. Add<span className="ps-type-weight-bold"> #tags </span>to your notes to make them easier to find.</div><a className="view-all-notes d-flex justify-content-center align-items-center ps-type-sm ps-color-white pt-md" href="/notes" target="ps-notes">
                                <div>View all notes</div>
                                <div className="icon-view-all-notes"></div>
                            </a>
                        </div>
                    </div>
                </div>
                }
            </div>
            <AddNote
                handleKeyPress={this.props.handleKeyPress}
                enterBtnClick={this.props.enterBtnClick}
                value={this.props.value}
                updateNotes={this.updatedNotes}
                updateNoteTime={this.props.updateNoteTime}

                scheduleId={scheduleId}
                addNoteTime={addNoteTime}
                currentTopicId={currentTopicId}
                noteText={noteText || ''}
                noteId={noteId || 'new'}
            />
        </div>
    }
}

class AddNote extends Component {
    state = {
        isLoading: false,
        enterToSave: false,
        scheduleId: '',
        topicId: '',
        text: '',
        time: '',
        errors: {},
        editorState: EditorState.createEmpty()
    }
    async componentDidMount() {
        const { scheduleId, currentTopicId: topicId, addNoteTime: time, noteText: text } = this.props;
        await this.setState({ scheduleId, topicId, time, text, isLoading: false });
    }

    addNote = async () => {
        const { text } = this.state
        const { scheduleId, currentTopicId: topicId, addNoteTime: time } = this.props;
        const data = { scheduleId, topicId, time, text }

        console.log(data)
        try {
            if (!text)
                return;

            await this.setState({ isLoading: true });
            const result = await addNote(data)

            if (result.data && result.data.note)
                this.props.updateNotes(result.data.note)

            await this.setState({ time: 0, text: '', isLoading: false });

        } catch (ex) {
            this.setState({ isLoading: false })
            if (ex.response && ex.response.data) {
                const errors = { ...this.state.errors, ...ex.response.data };
                this.setState({ errors });
            } else {
                const errors = { ...this.state.errors, 'message': "Sorry some error occurred. Please try again", 'class': 'danger' };
                this.setState({ errors });
            }
        }
    }

    onChange = (editorState) => {
        this.setState({ editorState })
        // console.log(editorState)
        // console.log(convertToRaw(editorState.getCurrentContent()))
    }

    handleChange = (event) => {
        const value = event.target.value

        this.setState(prevState => {
            if (prevState.text == "") {
                this.props.updateNoteTime()
            }

            return { text: value }
        })
    }

    render() {
        const { enterToSave, text } = this.state;

        const { scheduleId, currentTopicId: topicId, addNoteTime: time } = this.props;

        return <div className="px-3 footer-fixed">
            <div className="w-100 textareaNotes note-entry-box pb-3">
                <div className="editor-and-timestamp ps-type-sm ps-color-bg-white ps-color-gray-04 rounded p-2">
                    <Editor
                        editorState={this.state.editorState}
                        onChange={this.onChange} className="ps-color-gray-04"
                        plugins={plugins} />

                    {text && <Duration seconds={time} className="note-timestamp ps-type-xs ps-color-bg-white ps-color-gray-02 pl-sm rounded" />}
                </div>
            </div>

            {!enterToSave && <Button className="d-block w-100 mb-2" color="primary" size="sm" onClick={(e) => this.addNote()}>Save Note</Button>}

            {/* <button className="save-note-button ps-button-primary-md w-100 my-sm">Save note</button> */}
            <div className="note-entry-footer ps-color-gray-02 ps-type-xs d-flex justify-content-end align-items-center" >
                <div className="press-enter-to-save">Press<span className="ps-color-border-gray-02 rounded px-tiny mx-tiny">enter to save</span></div>

                <div className="enter-to-save-toggle pl-sm">
                    <Input className="tgl tgl-light" id="cb1" type="checkbox" value={enterToSave} onClick={(e) => this.setState(prev => !prev.enterToSave)} />
                    <label className="tgl-btn mb-0" htmlFor="cb1"></label>
                </div>
            </div>
        </div>
    }
}
