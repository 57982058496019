import {
    FormGroupInput,
    FormGroupAsyncAutoSuggest,
    optionSchema
} from 'components/common/forms';
import { Scope } from 'informed';
import Joi from 'joi-browser';
import React, { Component, Fragment } from 'react';
import { Col, Row } from 'reactstrap';

// prettier-ignore
export const addressSchema = {
    // no: Joi.string().required('').optional(),
    // street: Joi.string().required('').optional(),
    attn: Joi.string().empty('').optional(),
    address1: Joi.string().required().label("Address Line 1"),
    address2: Joi.string().empty('').optional(),
    // city: Joi.object(this.optionSchema).label("City"),
    // state: Joi.object(optionSchema).required().label("State"),
    // country: Joi.object(this.optionSchema).label("Country"),
    city: Joi.string().required().label("City"),
    state: Joi.string().required().label("State"),
    country: Joi.string().required().label("Country").default("India"),
    pincode: Joi.string().required().label("Pin code"),
    email: Joi.string().empty().optional().label("Email ID"),
    contactNo: Joi.string().empty().optional().label("Contact Number"),
    fax: Joi.string().empty('').optional().label("Contact Number"),
    landmark: Joi.string().empty('').optional(),

}

export class AddressComponent extends Component {
    render() {
        const { scope, validateProperty } = this.props;
        return (
            <Fragment>
                <Scope scope={scope}>
                    {/* <Row>
                    <Col sm={12} md={3}>
                        <FormGroupInput isRequired label="No" field="no" validateOnBlur validate={e => validateProperty(`${scope}.no`, e)} />
                    </Col>
                    <Col sm={12} md={9}>
                        <FormGroupInput isRequired label="Street" field="street" validateOnBlur validate={e => validateProperty(`${scope}.street`, e)} />
                    </Col>
                </Row> */}
                    <Row>
                        <Col sm={12} md={4}>
                            <FormGroupInput
                                label='Attn'
                                field='attn'
                                validateOnBlur
                                validate={e =>
                                    this.props.validateProperty(
                                        `${scope}.attn`,
                                        e
                                    )
                                }
                            />
                        </Col>
                        <Col sm={12} md={8}>
                            <FormGroupInput
                                isRequired
                                label='Address Line 1*'
                                field='line1'
                                validateOnBlur
                                validate={e =>
                                    this.props.validateProperty(
                                        `${scope}.address1`,
                                        e
                                    )
                                }
                            />
                        </Col>
                    </Row>

                    <FormGroupInput
                        label='Address Line 2'
                        field='line2'
                        validateOnBlur
                        validate={e =>
                            this.props.validateProperty(`${scope}.address2`, e)
                        }
                    />
                    <Row>
                        <Col sm={12} md={4}>
                            {/* <FormGroupAsyncAutoSuggest isRequired label="City" field="city" suggestType="city" validateOnBlur validate={e => validateProperty(`${scope}.city`, e)} /> */}
                            <FormGroupInput
                                isRequired
                                label='City'
                                field='city'
                                validateOnBlur
                                validate={e =>
                                    this.props.validateProperty(
                                        `${scope}.city`,
                                        e
                                    )
                                }
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            <FormGroupAsyncAutoSuggest
                                isRequired
                                label='State'
                                field='state'
                                suggestType='state'
                                getOptionValue={option => option['label']}
                                validateOnBlur
                                validate={e =>
                                    validateProperty(`${scope}.state`, e)
                                }
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            {/* <FormGroupAsyncAutoSuggest isRequired label="City" field="city" suggestType="city" validateOnBlur validate={e => validateProperty(`${scope}.city`, e)} /> */}
                            <FormGroupInput
                                isRequired
                                label='Country'
                                field='country'
                                validateOnBlur
                                validate={e =>
                                    this.props.validateProperty(
                                        `${scope}.country`,
                                        e
                                    )
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                            <FormGroupInput
                                label='Landmark'
                                field='landmark'
                                validateOnBlur
                                validate={e =>
                                    validateProperty(`${scope}.landmark`, e)
                                }
                            />
                        </Col>
                        <Col sm={12} md={6}>
                            <FormGroupInput
                                isRequired
                                label='Pin Code'
                                field='pincode'
                                validateOnBlur
                                validate={e =>
                                    validateProperty(`${scope}.pincode`, e)
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} md={6}>
                            <FormGroupInput
                                label='Contact number'
                                field='contactNo'
                                validateOnBlur
                                validate={e =>
                                    validateProperty(`${scope}.contactNo`, e)
                                }
                            />
                        </Col>
                        <Col sm={12} md={6}>
                            <FormGroupInput
                                label='Fax'
                                field='fax'
                                validateOnBlur
                                validate={e =>
                                    validateProperty(`${scope}.fax`, e)
                                }
                            />
                        </Col>
                    </Row>
                </Scope>
            </Fragment>
        );
    }
}
