import http from './httpService';
import { apiUrl } from '../config.json';

const apiEndpoint = `${apiUrl}/course`;

export function getCourses() {
    return http.get(`${apiEndpoint}/list`);
}

export function getCourse(id, params) {
    return http.get(`${apiEndpoint}/${id}`, { params });
}

export function getAdminCourses() {
    return http.get(`${apiUrl}/admin/course/list`);
}

export function changeCourseTopic(id, data) {
    return http.post(`${apiEndpoint}/${id}`, data);
}

export function courseUpload(data, config) {
    return http.post(`${apiEndpoint}/upload`, data, config);
}

/**
 * COURSE NOTES
 */
export function getNotes(scheduleId) {
    return http.get(`${apiUrl}/notes/${scheduleId}`);
}

export function addNote(data, noteId = 'new') {
    return http.post(`${apiUrl}/note/edit/${noteId}`, data);
}
