import moment from 'moment';

export default class Static {
    /** Honorifics */
    static userTitles(type = 'common') {
        switch (type) {
            default:
                return ['Dr', 'Miss', 'Mr', 'Mrs', 'Prof', 'Rev'];
        }
    }

    static gender() {
        return ['Male', 'Female', 'Third Gender'];
    }

    static getShortMonthNames() {
        const names = moment.monthsShort();
        let months = [];
        for (var i = 0; i < names.length; i++) {
            const month = { value: i + 1, label: names[i] };
            months.push(month);
        }
        return months;
    }

    static formatBytes(bytes, decimals) {
        if (bytes == 0) return '0 Bytes';
        var k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    }
}

export const getLetters = (str, nos = 2) => {
    if (!str) return '';
    const matches = str.match(/\b(\w)/g);
    const acronym = matches.slice(0, nos).join('');
    return acronym;
};
