import { FormGroupInput, SubmitButton } from 'components/common/forms';
import { getQueryParams, Loading, Logo } from 'components/common';
import { Header } from 'components/admin/common/header';
import { BoxWrapper } from 'components/core/box';
import { Form } from 'informed';
import Joi from 'joi-browser';
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { courseUpload } from 'services/courseService';

export default class CourseUploadPage extends Component {
    state = { isPageLoading: false };
    render() {
        const { isPageLoading } = this.state;
        const { session } = this.props;
        const params = getQueryParams(this.props);

        return (
            <div className='page page-courseUpload box'>
                <Header session={session} container />
                <div className='container pt-4'>
                    {isPageLoading && <Loading />}
                    {!isPageLoading && (
                        <div className='row'>
                            <div className='col-sm-12 col-md-6'>
                                <CourseUploadForm
                                    {...this.props}
                                    params={params}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

class CourseUploadForm extends Component {
    state = {
        data: {},
        errors: {},
        isLoading: false,
        success: {}
    };

    readFile = e => {
        e.stopPropagation();
        e.preventDefault();

        const files = e.target.files;
        const f = files[0];
        this.file = f;
    };

    onSubmit = async () => {
        // const data = this.formApi.getState().values;

        try {
            this.setState({ isLoading: true, errors: {}, success: {} });

            if (!this.file) {
                const errors = {
                    ...this.state.errors,
                    message: 'Please choose the file',
                    class: 'danger'
                };
                this.setState({ errors });
            }

            const formData = new FormData();
            formData.append('file', this.file);

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            };
            const { data } = await courseUpload(formData, config);
            console.log(data);

            if (data && data.status === 0) {
                const { message, errors } = data;
                this.setState({
                    isLoading: false,
                    errors: {
                        message: message,
                        list: errors,
                        class: 'danger'
                    }
                });
            } else if (data && data.status === 1) {
                const { message, sid } = data;

                this.setState({
                    isLoading: false,
                    success: {
                        sid: sid,
                        message: message,
                        class: 'success'
                    }
                });
            }
        } catch (ex) {
            this.setState({ isLoading: false });
            if (ex.response && ex.response.data) {
                const errors = { ...this.state.errors, ...ex.response.data };
                this.setState({ errors });
            } else {
                const errors = {
                    ...this.state.errors,
                    message: 'Sorry some error occurred. Please try again',
                    class: 'danger'
                };
                this.setState({ errors });
            }
        }
    };

    render() {
        const { isLoading, errors, success } = this.state;
        const queryParams = getQueryParams(this.props);

        return (
            <Form
                className='bg-white p-3'
                getApi={this.setFormApi}
                onSubmit={this.onSubmit}>
                {({ formApi, formState }) => (
                    <Fragment>
                        <h1>Course Upload</h1>
                        <div className='fileBlock my-3'>
                            <input
                                id='upload'
                                ref='upload'
                                type='file'
                                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                onChange={event => {
                                    this.readFile(event);
                                }}
                                onClick={event => {
                                    // event.target.value = null
                                }}
                            />
                        </div>
                        {
                            <SubmitButton
                                extraclass=''
                                isLoading={isLoading}
                                value='Course Upload'
                            />
                        }
                        {errors && errors.message && (
                            <Alert color={errors.class} className='mt-3'>
                                {errors.message}
                                {errors.list && errors.list.length > 0 && (
                                    <ul>
                                        {errors.list.map((e, idx) => {
                                            return <li key={idx}>{e}</li>;
                                        })}
                                    </ul>
                                )}
                            </Alert>
                        )}

                        {success && success.message && (
                            <Alert color={success.class} className='mt-3'>
                                {success.message}
                                {success.sid && (
                                    <NavLink
                                        to={`/course/${success.sid}/details`}
                                        className='badge badge-success'>
                                        Click here to view the course
                                    </NavLink>
                                )}
                            </Alert>
                        )}
                    </Fragment>
                )}
            </Form>
        );
    }
}
