import http from './httpService';
import { apiUrl } from '../config.json';

const apiEndpoint = `${apiUrl}/client`;

export function getAdminClients() {
    return http.get(`${apiEndpoint}/list`);
}

export function getClientPreview(id, type, params = {}) {
    return http.get(`${apiUrl}/group/${type}/${id}/preview`, { params });
}

export function changeClientTopic(id, data) {
    return http.post(`${apiEndpoint}/${id}`, data);
}

export function clientUpload(data, config) {
    return http.post(`${apiEndpoint}/upload`, data, config);
}

export function saveGroup(type, id, data, config = {}) {
    return http.post(`${apiUrl}/group/edit/${id}`, { ...data, type }, config);
}
