import { Loading, getCourseLink, Duration } from 'components/common';
import { Header } from 'components/core';
import { minsToHHMM } from 'components/libs';
import React, { Component } from 'react';
import { Col, Collapse, Container, ListGroupItem, Row } from 'reactstrap';
import { getCourse } from 'services/courseService';
import ShowMore from 'react-show-more';
import ReactPlayer from 'react-player';
import { NavLink } from 'react-router-dom';

//import { Loading } from 'components/common';
export default class CourseViewPage extends Component {
    state = {
        id: '',
        course: {},
        isPageLoading: true,
        isLoading: false
    };

    constructor(props) {
        super(props);
        console.log('CourseViewPage');
    }
    async componentDidMount() {
        console.log(this.props);
        this.getData(this.props);
    }

    async componentDidUpdate(prevProps, prevState) {
        const { scheduleId: prevId } = prevProps.match.params;
        const { scheduleId: newId } = this.props.match.params;
        console.log(`${prevId} ${newId}`);
        if (prevId !== newId) {
            await this.getData(this.props);
        }
    }

    getData = async props => {
        const { scheduleId } = props.match.params;
        const { data: course } = await getCourse(scheduleId);
        await this.setState({ course, scheduleId, isPageLoading: false });
    };

    render() {
        const { isPageLoading, course, scheduleId } = this.state;
        const { session } = this.props;

        return (
            <div className='page page-course-preview'>
                <Header session={session} container />
                <div className='container'>
                    {isPageLoading && <Loading />}
                    {!isPageLoading && (
                        <CoursePreview data={course} scheduleId={scheduleId} />
                    )}
                </div>
            </div>
        );
    }
}

export class CoursePreview extends Component {
    state = {
        playing: true,
        muted: true
    };

    onPlay = () => {
        this.setState(prevState => {
            const playing = prevState.playing && !prevState.muted;
            // console.log(playing)
            // console.log(muted)
            const muted = prevState.muted ? false : false;

            return { playing: playing, muted: muted };
        });
    };

    doNothing = () => {};

    render() {
        const { data: course, scheduleId } = this.props;
        const { playing, muted } = this.state;
        const { progress, courseTrack } = course;

        return (
            <div>
                {' '}
                {course && course._id && (
                    <Container className='containerr'>
                        <div className='font-sm small text-primary mb-3'>
                            {course.category && course.category.label && (
                                <span>{course.category.label}</span>
                            )}
                            {course.level && (
                                <span>
                                    <span className='mx-1'>.</span>
                                    <span>{course.level}</span>
                                </span>
                            )}
                        </div>
                        <Row>
                            <Col xs='12' md='6'>
                                {/* <video className="w-100" controls>
                            <source src="" type="" />
                        </video> */}
                                {/* */}
                                <div
                                    className='player-wrapper'
                                    onClick={e => this.onPlay()}>
                                    <ReactPlayer
                                        url={course.promo.url}
                                        config={{
                                            file: {
                                                attributes: {
                                                    controlsList: 'nodownload'
                                                }
                                            }
                                        }}
                                        onContextMenu={e => e.preventDefault()}
                                        className='react-player'
                                        width='100%'
                                        height='100%'
                                        volume={0.8}
                                        muted={muted}
                                        playing={playing}
                                        onPlay={this.doNothing()}
                                        controls={true}
                                        pip={true}
                                        // light={true}
                                    />
                                </div>
                            </Col>
                            <Col xs='12' md='5'>
                                <NavLink
                                    to={getCourseLink(
                                        scheduleId,
                                        courseTrack['currentTopicId'],
                                        courseTrack['currentModuleId']
                                    )}
                                    className='btn btn-primary btn-lg'>
                                    Continue Course
                                </NavLink>
                                <h1>{course.name}</h1>

                                {course.desc && (
                                    <div className='desc'>
                                        <ShowMore
                                            lines={5}
                                            more='Read more'
                                            less='Less'
                                            anchorClass='readMore'>
                                            {course.desc}
                                        </ShowMore>
                                    </div>
                                )}

                                <div className='features'>
                                    <span className='text-muted small'>
                                        <i className='fa fa-clock-o mr-1' />
                                        {minsToHHMM(course.duration)}
                                    </span>
                                </div>
                            </Col>
                        </Row>

                        {course.objectives && (
                            <section>
                                <h2>Course Objectives</h2>
                                <div className='desc'>{course.objectives}</div>
                            </section>
                        )}

                        {course.prerequisites && (
                            <section>
                                <h2>Prerequisites</h2>
                                <div className='desc'>
                                    {course.prerequisites}
                                </div>
                            </section>
                        )}

                        <section>
                            <div className='my-3'>
                                <strong>Table of Contents</strong>
                            </div>
                            {course.modules &&
                                course.modules.map((module, idx) => {
                                    return (
                                        <CourseModule
                                            data={module}
                                            scheduleId={scheduleId}
                                            progress={progress}
                                            key={idx}
                                        />
                                    );
                                })}
                        </section>
                    </Container>
                )}{' '}
            </div>
        );
    }
}

export class CourseModule extends Component {
    state = { collapse: true };

    toggle = () => {
        this.setState(prevState => ({ collapse: !prevState.collapse }));
    };

    render() {
        const { data: module, progress, scheduleId } = this.props;
        return (
            <div>
                <ListGroupItem className='mt-1' onClick={this.toggle}>
                    <strong>{module.name}</strong>
                    <span className='pull-right d-inline'>
                        {/* <i className="fa fa-check text-success mr-3" ></i> */}
                        {module.duration && (
                            <span className='mr-3 small'>
                                {minsToHHMM(module.duration)}
                            </span>
                        )}
                        <i
                            className={
                                'cursor-pointer px-2 fa ' +
                                (this.props.isOpen
                                    ? 'fa-angle-down'
                                    : 'fa-angle-right')
                            }
                        />
                    </span>
                </ListGroupItem>
                <Collapse isOpen={this.state.collapse}>
                    {module.topics &&
                        module.topics.map((topic, idx) => {
                            return (
                                <CourseTopic
                                    data={topic}
                                    module={module}
                                    scheduleId={scheduleId}
                                    progress={progress}
                                    key={idx}
                                />
                            );
                        })}
                </Collapse>
            </div>
        );
    }
}

export class CourseTopic extends Component {
    render() {
        const { data: topic, module, scheduleId, progress } = this.props;

        return (
            <ListGroupItem className='my-0 text-muted py-2 pl-5 border-bottom-0'>
                <NavLink
                    to={getCourseLink(
                        scheduleId,
                        topic['topicId'],
                        module['moduleId']
                    )}>
                    <i
                        className={`topic-icon type-${topic.contentType} mr-3`}
                    />
                    <span>{topic.name}</span>
                    <span className='pull-right d-inline'>
                        {progress &&
                            progress[topic['topicId']] &&
                            progress[topic['topicId']]['isCompleted'] && (
                                <i className='fa fa-check text-success mr-3' />
                            )}

                        {topic['contentType'] == 'video' && topic.duration && (
                            <span className='mr-3 small pt-duration'>
                                {minsToHHMM(topic.duration)}
                            </span>
                        )}
                    </span>
                </NavLink>
            </ListGroupItem>
        );
    }
}
