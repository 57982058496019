import http from './httpService';
import { apiUrl } from '../config.json';

const apiEndpoint = `${apiUrl}/test`;

export function getAdminTests() {
    return http.get(`${apiEndpoint}/list`);
}

export function getTestPreview(id, params) {
    return http.get(`${apiEndpoint}/${id}/preview`, { params });
}

export function changeTestTopic(id, data) {
    return http.post(`${apiEndpoint}/${id}`, data);
}

export function testUpload(data, config) {
    return http.post(`${apiEndpoint}/upload`, data, config);
}
