import React, { Component, Container, Fragment } from 'react';
import { Header, Footer } from 'components/core';

const Page404 = ({ location, session }) => (
    <div className='page page-404'>
        <Header session={session} />
        <div style={{ minHeight: '75vh' }} className='p-5'>
            <h2>
                No match found for <code>{location.pathname}</code>
            </h2>
        </div>
        <Footer />
    </div>
);

export default Page404;
