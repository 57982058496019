import FCalendar from 'components/admin/calendar';
import CourseListPage from 'components/admin/icourse';
import CourseUploadPage from 'components/admin/icourse/upload';
import TestListPage from 'components/admin/itest';
import TestPreviewPage from 'components/admin/itest/preview';
import TestUploadPage from 'components/admin/itest/upload';
import { ProtectedRoute } from 'components/common';
import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import UserListPage from 'components/admin/iuser';
import UserUploadPage from 'components/admin/iuser/upload';
import AdminSideMenu from 'components/admin/sidemenu';
import SplitPane from 'react-split-pane';
import Page404 from 'components/core/error';

import ClientListPage from 'components/admin/iclient';
import ClientListPageForm from 'components/admin/iclient/form';
import ClientUploadPage from 'components/admin/iclient/upload';
import PerfectScrollbar from 'react-perfect-scrollbar';

import AdminDashboard from 'components/admin/dashboard';
import UserViewPage from 'components/admin/iuser/view';

class Admin extends Component {
    state = { currentPath: '' };

    componentDidMount() {
        const { pathname } = this.props.location;

        const currentPath = pathname;
        this.setState({ currentPath });
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ currentPath: this.props.location.pathname });
        }
    }

    render() {
        const { session } = this.state;
        const { match } = this.props;

        return (
            <Fragment>
                <SplitPane
                    split='vertical'
                    minSize={270}
                    maxSize={300}
                    className='bg-white'
                    // defaultSize={parseInt(localStorage.getItem('splitPos'), 10)}
                    // onChange={size => localStorage.setItem('splitPos', size)}
                >
                    <PerfectScrollbar className='bg-white border-right'>
                        <AdminSideMenu
                            currentPath={this.state.currentPath}
                            {...this.props}
                        />
                    </PerfectScrollbar>
                    <PerfectScrollbar style={{ overflow: 'scroll' }}>
                        <Switch>
                            <ProtectedRoute
                                path={`${match.url}/dashboard`}
                                component={AdminDashboard}
                                userTypes={['superAdmin', 'admin']}
                                modules={['clientList']}
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/client/list`}
                                component={ClientListPage}
                                userTypes={['superAdmin', 'admin']}
                                modules={['clientList']}
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/client/form/:id`}
                                component={ClientListPageForm}
                                userTypes={['superAdmin', 'admin']}
                                modules={['clientList']}
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/client/upload`}
                                component={ClientUploadPage}
                                userTypes={['superAdmin', 'admin']}
                                modules={['clientUpload']}
                                exact
                            />

                            <ProtectedRoute
                                path={`${match.url}/test/list`}
                                component={TestListPage}
                                userTypes={['superAdmin', 'admin']}
                                modules={['testList']}
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/test/upload`}
                                component={TestUploadPage}
                                userTypes={['superAdmin', 'admin']}
                                modules={['testUpload']}
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/test/preview/:testId`}
                                component={TestPreviewPage}
                                exact
                            />

                            <ProtectedRoute
                                path={`${match.url}/course/list`}
                                component={CourseListPage}
                                userTypes={['superAdmin', 'admin']}
                                modules={['courseList']}
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/course/upload`}
                                component={CourseUploadPage}
                                userTypes={['superAdmin', 'admin']}
                                modules={['courseUpload']}
                                exact
                            />

                            <ProtectedRoute
                                path={`${match.url}/user/list`}
                                component={UserListPage}
                                userTypes={['superAdmin', 'admin']}
                                modules={['userList']}
                                exact
                            />

                            <ProtectedRoute
                                path={`${match.url}/user/:uid/details`}
                                component={UserViewPage}
                                userTypes={['superAdmin', 'admin']}
                                modules={['userList']}
                                exact
                            />

                            <ProtectedRoute
                                path={`${match.url}/user/upload`}
                                component={UserUploadPage}
                                userTypes={['superAdmin', 'admin']}
                                modules={['userUpload']}
                                exact
                            />

                            <ProtectedRoute
                                path={`${match.url}/calendar`}
                                component={FCalendar}
                                userTypes={['superAdmin', 'admin']}
                                modules={['Calendar']}
                                exact
                            />
                            <Route
                                component={() => (
                                    <Page404
                                        session={session}
                                        {...this.props}
                                    />
                                )}
                            />
                        </Switch>
                    </PerfectScrollbar>
                </SplitPane>
            </Fragment>
        );
    }
}

export default Admin;
