import { FormControlInput, FormGroupInput, FormInputGroup, SubmitButton } from 'components/common/forms';
import { getQueryParams, Loading, Logo } from 'components/common';
import { Header } from 'components/core';

import { BoxWrapper } from 'components/core/box';
import { Form } from 'informed';
import Joi from 'joi-browser';
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Alert, Col, Row } from 'reactstrap';
import { signup } from 'services/userService';
import Helmet from 'react-helmet';

export default class RegisterPage extends Component {
    state = { isPageLoading: false }
    render() {
        const { isPageLoading } = this.state;
        const { session } = this.props;
        const params = getQueryParams(this.props);

        return (
            <Fragment>
                <Helmet>
                    <title>Register</title>
                </Helmet>
                <div className="page-register box">
                    <Header session={session} container />
                    <div className="container">
                        {isPageLoading && <Loading />}
                        {!isPageLoading &&
                            <BoxWrapper
                                // info={<RegisterHelpBox {...this.props} params={params} />}
                                form={<RegisterForm {...this.props} params={params} />}
                            />
                        }
                    </div>
                </div>
            </Fragment>
        );
    }
}

class RegisterHelpBox extends Component {
    render() {
        return (
            <Fragment>
                <Logo center />
                <h4>Instructions</h4>
                <ul>
                    <li>Points</li>
                </ul>
            </Fragment>
        )
    }
}


class RegisterForm extends Component {
    state = {
        data: {
            fname: '', lname: '',
            email: '', password: '',
            mobileCountryCode: '+91', mobile: '',
            next: ''
        },
        errors: {},
        isLoading: false
    };

    schema = {
        fname: Joi.string().required().label('First Name'),
        lname: Joi.string().required().label('Last Name'),

        email: Joi.string().required().label('Email ID'),
        password: Joi.string().required().label('Password'),

        mobileCountryCode: Joi.string().required().label('Country Code'),
        mobile: Joi.string().required().label('Mobile number')
    };


    componentDidMount() {
        const sampleData = this.getSampleData();
        // const user = this.mapToView(data)
        this.formApi.setValues(sampleData);
    }

    getSampleData = () => {
        return {
            fname: 'Sivaraj', lname: 'S',
            email: 'sivaraj@sdev.in', password: '123',
            mobileCountryCode: '+91', mobile: '9566770295',
        }
    }

    // mapToView = (user) => {
    //     return {
    //         fname: user.fname,
    //         mname: user.mname || '',
    //         lname: user.lname,
    //         email: user.email,
    //         password: user.password,
    //         mobileCountryCode: user.mobileCountryCode ? user.countryCode : '+91',
    //         mobile: user.mobile && user.number ? user.number : ''
    //     }
    // }

    validateProperty = (name, value) => {
        const schema = Joi.reach(Joi.object(this.schema), name)
        const { error } = Joi.validate(value, schema);
        if (error) console.log(error)
        return error ? error.details[0].message : null;
    };

    validate = () => {
        const options = { abortEarly: false };
        const data = this.formApi.getState().values;

        const { error } = Joi.validate(data, this.schema, options);
        if (!error) return null;
        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message;
        return errors;
    };

    setFormApi = (formApi) => {
        this.formApi = formApi;
    }

    onSubmit = async () => {
        const data = this.formApi.getState().values;

        try {
            this.setState({ isLoading: true, errors: {} })
            const result = await signup(data);
            console.log(result)

            window.location = result.data.next

            this.setState({ isLoading: false })

        } catch (ex) {
            this.setState({ isLoading: false })
            if (ex.response && ex.response.data) {
                const errors = { ...this.state.errors, ...ex.response.data };
                this.setState({ errors });
            } else {
                const errors = { ...this.state.errors, 'message': "Sorry some error occurred. Please try again", 'class': 'danger' };
                this.setState({ errors });
            }
        }
    }

    render() {
        const { isLoading, errors } = this.state;

        return (
            <Form className='bg-white p-4' getApi={this.setFormApi} onSubmit={this.onSubmit}>
                {({ formApi, formState }) => (
                    <Fragment>
                        <h1 className="text-gradient-eco">Register</h1>
                        <small className="text text-muted">All the fields required</small>
                        <Row>
                            <Col sm={12} md={6}>
                                <FormGroupInput
                                    field="fname" label="First Name"
                                    validateOnBlur validate={e => this.validateProperty('fname', e)}
                                />
                            </Col>
                            <Col sm={12} md={6}>
                                <FormGroupInput
                                    field="lname" label="Last Name"
                                    validateOnBlur validate={e => this.validateProperty('lname', e)}
                                />
                            </Col>
                        </Row>
                        <FormGroupInput
                            field="email" label="Email ID" type="email"
                            validateOnBlur validate={e => this.validateProperty('email', e)}
                        />
                        <FormGroupInput
                            field="password" label="Password" type="password"
                            validateOnBlur validate={e => this.validateProperty('password', e)}
                        />
                        <FormInputGroup className="input-group mb-3" label="Mobile Number" id="mobile" formApi={formApi}>
                            <div className="input-group-prepend">
                                <span className="input-group-text">{formState.values.mobileCountryCode}</span>
                            </div>
                            <FormControlInput
                                field="mobile" label="" type="number"
                                validateOnBlur validate={e => this.validateProperty('mobile', e)}
                            />
                        </FormInputGroup>

                        <div className="mt-3 text-center">
                            {<SubmitButton extraclass="w-100" isLoading={isLoading} value="Register" />}
                        </div>
                        {errors && errors.message &&
                            <Alert color={errors.class} className="mt-3">
                                {errors.message}
                            </Alert>
                        }

                        <p className="pt-3">
                            Already have an account <NavLink to={{
                                pathname: "/login",
                                search: this.props.location.search,
                                hash: this.props.location.hash
                            }}
                                className="text text-muted">Login</NavLink>
                        </p>

                    </Fragment>
                )}
            </Form>
        );
    }
}