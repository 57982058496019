import { Loading } from 'components/common';
import { minsToHHMM } from 'components/libs';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardText, CardTitle, Col, Container, Row } from 'reactstrap';


export default class CourseList extends Component {
    state = {
        data: [],
        isPageLoading: true,
        isLoading: false,
    }


    render() {
        const { schedules, courses, progress, noCoursesFoundText, isLoading } = this.props;

        return (
            <Fragment>
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <h1>Courses</h1>
                        <p className="help-text text-muted mb-2 mb-md-3">Scheduled courses for you</p>
                    </div>
                    <div>
                        <button className="btn btn-link p-0" onClick={(e) => this.props.refresh()}> <i className="fa fa-refresh px-1" aria-hidden="true"></i> Refresh </button>
                    </div>
                </div>
                {isLoading && <Loading />}
                {schedules && schedules.length <= 0 && <span>{noCoursesFoundText || 'No Courses Found'} </span>}
                {schedules && <div className="card-columns">
                    {schedules.map((schedule, idx) => {
                        // xs="12" md="6" lg="4" 
                        return <CourseCard className="mb-4 px-0 pr-md-4 pl-md-0" key={idx}
                            schedule={schedule} data={courses[schedule['courseId']]} progress={progress[schedule['_id']] || 0} idx={idx} >
                        </CourseCard>
                    })}
                </div>}
            </Fragment>
        );
    }
}



export class CourseCard extends Component {

    render() {
        const { data: course, schedule, idx, progress } = this.props;
        console.log(course)
        const colors = ["#0EB0A4", "#A364E6", "#A559D4", "#8DA4B6", "#D1A24F", "#FF769B"]
        const color = colors[idx % 6];
        // 
        // 
        return <Link to={`/course/${schedule._id}/details`} className="text-muted small a-hover">
            <Card onClick={this.viewCourse} className="course-card course-card-single card pb-0">
                <div className="course-img" style={{ backgroundImage: `url(${course.thumbnail})` }}>
                    <div className="course-img-overlay" style={{ background: `${color}b3` }}></div>
                </div>
                <div className="progress">
                    <div className="progress-bar" role="progressbar"
                        style={{ width: `${progress / course.duration * 100}%` }}
                        aria-valuenow={progress / course.duration * 100} aria-valuemin="0" aria-valuemax="100">
                    </div>
                </div>
                <CardBody className="text-center">
                    <CardText className="text-primary mb-2">
                        <span className="category">
                            <i className="fa fa-clock-o mr-1"></i>
                            <span className="mr-1">{minsToHHMM(course.duration)}</span>
                        </span>
                        <CardTitle>{course.name}</CardTitle>

                        <div className="tags">
                            {course.category && course.category.label && <span className="tag">{course.category.label}</span>}
                            {
                                course.tags && course.tags.length > 0 && course.tags.map((tag, idx) => {
                                    return <span key={idx}>
                                        <span className="tag">{tag['label']}</span>
                                    </span>
                                })
                            }
                            {
                                course.level && <span>
                                    <span className="tag">{course.level}</span>
                                </span>
                            }


                        </div>
                    </CardText>
                    {course.noTopics && course.noTopics > 0 &&
                        <span className="text-muted totalLessons">
                        <img className="mr-2" src="/assets/courses/play.png" />
                        {/* <i className="fa fa-play-circle  mr-1"></i> */}
                            <span className="mr-1">{course.noTopics} Lessons</span>
                        </span>
                    }
                    <Link to={`/course/${schedule._id}/details`} className="btn btn-outline">Preview</Link>
                </CardBody>
            </Card>
        </Link>
    }
}