// import UserList from 'components/admin/iuser/list';
import { Loading } from 'components/common';
import { Header } from 'components/admin/common/header';
import React, { Component, Fragment } from 'react';
import { getAdminUsers } from 'services/userService';
import UserList from './list';
import Drawer from 'rc-drawer';
import UserPreview from 'components/admin/iuser/preview';

export default class UsersPage extends Component {
    state = {
        isPageLoading: true,
        open: false,
        openUserForm: false,
        openChild: false,
        openChildren: false,
        uid: null,
        uname: '',
        utype: 'user',
        uicon: ''
    };
    userPreview = (uid = null, utype = 'user', uname = '', uicon = '') => {
        this.setState({
            open: !this.state.open,
            uid,
            utype,
            uname,
            uicon
        });
    };
    openUserForm = () => {
        this.setState({
            openUserForm: !this.state.openUserForm
        });
    };
    onMaskClick = () => {
        this.setState({
            open: !this.state.open
        });
    };

    onMaskClickUserForm = () => {
        this.setState({
            openUserForm: !this.state.openUserForm
        });
    };

    async componentDidMount() {
        const {
            data: { users }
        } = await getAdminUsers();

        this.setState({ data: users, isPageLoading: false });
    }
    render() {
        const { isPageLoading, isLoading, data } = this.state;
        const { session } = this.props;

        return (
            <div className='page page-users box'>
                <Header session={session} container />
                <div className='container pt-4'>
                    {isPageLoading && <Loading />}
                    {!isPageLoading && (
                        <Fragment>
                            {/* {data && data.length <= 0 && 'No Users found'}
                            {data && data.length > 0 && (
                                <UserList data={data} />
                            )} */}

                            {data && (
                                <UserList
                                    data={data}
                                    userPreview={this.userPreview}
                                />
                            )}

                            <Drawer
                                width='50vw'
                                handler={false}
                                level={['body > h1', '#__react-content']}
                                open={this.state.open}
                                onMaskClick={this.onMaskClick}
                                className='drawer1'
                                placement='right'>
                                {this.state.uid && (
                                    <UserPreview
                                        session={session}
                                        uid={this.state.uid}
                                        utype={this.state.utype}
                                        uname={this.state.uname}
                                        uicon={this.state.uicon}
                                    />
                                )}
                            </Drawer>
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}
