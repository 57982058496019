import 'styles/quiz.scss';

import { Loading } from 'components/common';
import { Header, SideNav } from 'components/core';
import React, { Component, Fragment, Container } from 'react';
import { Link } from 'react-router-dom';

export default class Quiz extends Component {
  state = {
    data: [],
    parentData: [],
    prefixUrl: "",
    isPageLoading: true,
    isLoading: false
  }

  render() {
    const { isPageLoading, isLoading, errors, data: { schedules, QuestionLists, progress }, parentData, prefixUrl, type, id } = this.state;
    const { session } = this.props;

    const isTaken = true;

    return (
      <div id="quiz" className="quiz quiz--black d-flex flex-column">
        {!isTaken && <div className="quiz-wrapper">
          <div className="itemHeaderContent">
            <div className="itemHeaderTitle">Learning check: <span className="iitemHeaderAssessmentTitle">Python: Getting Started</span></div>
            <h2 className="itemHeaderProgress">Question 4 of 10</h2>
          </div>
          <div className="progressIndicatorContainer">
            <div className="progressIndicatorBar">
              <div className="progress">
                <div className="progress-bar" role="progressbar"
                  style={{ width: `50%` }}
                  aria-valuenow={50 * 100} aria-valuemin="0" aria-valuemax="100">
                </div>
              </div>
              <div className="progressIndicatorBarCompleteness"></div>
            </div>
          </div>
          <div className="itemBodyContainer bg-white">
            <div className="textItemStemTextWrapper">
              <div className="stemTextWrapper" role="region" aria-label="question">
                To help debug your application, you can set something called a:
                </div>
              <ul className="answeredChoices choiceList" type="none" aria-label="choices" role="region">
                <li className="textChoice textChoiceKeyAnswered" role="button" tabIndex="0">
                  <div>
                    Stop point 123
                    </div>
                </li>
                <li className="textChoice textChoiceKey1" role="button" tabIndex="0">
                  <div>
                    Resume point
                    </div>
                </li>
                <li className="textChoice textChoiceButton1" role="button" tabIndex="0">
                  <div>
                    Breakpoint
                    </div>
                </li>
                <li className="textChoice textChoiceDistractorAnswered1" role="button" tabIndex="0">
                  <div>
                    Pause
                    </div>
                </li>
              </ul>
              <div className="d-flex justify-content-between">
                <span>
                  {/* <a className="">Give feedback</a> */}
                </span>
                <span>
                  <button className="btn btn-outline-primary btn-quiz-outline mr-1">Previous</button>
                  <button className="btn btn-primary btn-quiz">Next Question</button>
                </span>
              </div>
            </div>
          </div>
        </div>
        }
        {/* {isTaken && <div>
          <div className="result-block container">
            <div className="result-title">summary</div>
            <div className="row align-items-center mt-4">
              <div className="result-block-left col-sm-6">
                <div className="answers mt-3">
                  <div className="ans ans--correct"><strong>Correct</strong><strong>2</strong></div>
                  <div className="ans ans--incorrect"><strong>Incorrect</strong><strong>1</strong></div>
                </div>
              </div>
              <div className="result-block-right text-center col-sm-6">
                <img src="/assets/icons/correct-block.png" />
              </div>

              <div className="col-sm-12 order text-center mt-3 mt-md-5 px-0">
                <h5 className="totally"> Great, you got 2 out of 3 correct! </h5>

                <div className="result-block-footer p-3 mt-4">
                  <div className="result-block-footer__left">
                    <button className="btn btn-primary mr-2 btn-sm">Check Answers</button>
                    <button className="btn btn-outline-primary btn-sm">Retake Quiz</button>
                  </div>
                  <div>
                    <button className="btn btn-primary mb-2 mb-md-0 btn-sm">Next Topic</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>} */}

        {isTaken && <div className="next-topic-start">
          <div className="modal-block">
            <div className="d-flex align-items-sm-center justify-content-center">
              <div className="d-flex flex-column">
                <div className="pt-0 pb-4 pt-md-4">
                  <div>
                    <p className="text-center">Module completed!</p>
                    <button className="btn btn-primary" id="next-module">Continue to next module</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <div id="course-feedback-container">Have feedback about this course?
              {/* <a className="link-orange" id="survey-link" href="" target="_blank">Let us know.</a> */}
              </div>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

